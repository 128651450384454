import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Heading from "../../../../../Common/Heading";
import LoadingSpinner from "../../../../../Common/LoadingSpinner";
import { AnswerFieldStyle, RearrangeArray, styleColor, styleFontWeight, TableHeaderStyle } from "../../../../../Common/Question Generator/QuestionGeneratorFunctions";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import axios from "axios";
import url from "../../../../../Common/URL";
import { useSelector } from "react-redux";


const AssignmentGeneratorHistoryComp = () => {
    let { stud_uname, stud_ans_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [answersArray, setAnswersArray] = useState([]);

    const [state, setState] = useState({
        test_type: '', test_name: '', total_questions: 0, student_answer: []
    })
    // =========================================================================================================
    var noOfQuestions = Number(state.total_questions);
    let tableHeader = [...Array(noOfQuestions).keys()]; // to create header number for a table
    let inputFieldArray = [...Array(noOfQuestions).keys()]; // to create input fields for the table
    let actualAnswerFieldArray = [...Array(noOfQuestions).keys()]; // to create the actual answer field 


    useEffect(() => {
        LoadTestHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const LoadTestHistory = () => {

        const obj = { uname: stud_uname, id: stud_ans_id }
        axios.post(url + "view_detailed_answer", obj)
            .then((response) => {
                console.log(response)
                if (response.data.success) {
                    let a = response.data.data;
                  //  console.log(a);
                    let actual_answer = JSON.parse(a[0].actual_answer);
                    let actual_question = JSON.parse(a[0].actual_question);
                    let student_answer = JSON.parse(a[0].student_answer);
                    console.log(actual_answer, actual_question, student_answer);
                    setState({
                        test_type: a[0].test_type, test_name: a[0].test_name, total_questions: a[0].total_questions, student_answer: student_answer
                    })

                    let questionsArray = actual_question;
                    let answerArray = actual_answer;

                    var splicedArray = [];
                    while (questionsArray.length > 0) {
                        splicedArray.push(questionsArray.splice(0, 10));
                    }

                    for (let i = 0; i < splicedArray.length; i++) {
                        questionsArray.push(RearrangeArray(splicedArray[i]));
                    }

                    setQuestions(questionsArray);
                    setAnswersArray(answerArray);
                    setLoading(false);
                }
            })
            .catch((err) => console.log(err));
    }

    const checkAnswer = (x) => {
        // inputAnswers = Entered answers; 
        // answersArray = Actual Answers;
        return (state.student_answer[x] === answersArray[x]) ? true : false;
    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Tests</li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to='/generator'>Generator</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Assignment History</li>
                    </ol>
                </nav>

                {!loading && <Heading name={`${state.test_type} : ${state.test_name}`} />}
                {loading ? <LoadingSpinner /> :
                    <div className="container-fluid mb-5">

                        <div className="row my-5">
                            <div className="col-12 mx-auto ">

                                {questions.map((x, i) => {
                                    return <TableContainer key={i} component={Paper} className="mb-5">
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {tableHeader.splice(0, 10).map((x, i) => <TableCell key={i} style={TableHeaderStyle}>{x + 1}</TableCell>)}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {x.map((x, i) => {
                                                    return <TableRow key={i}>
                                                        {x.map((x, i) => <TableCell key={i} style={{ fontWeight: styleFontWeight(x), color: styleColor(x), textAlign: 'center' }}>{x}</TableCell>)}
                                                    </TableRow>
                                                })}
                                                <TableRow>
                                                    {inputFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={x} className="text-center">
                                                            <input readOnly
                                                                style={{
                                                                    width: "3em", textAlign: "center",
                                                                    border: (checkAnswer(x) ? '2px solid green' : '2px solid red')
                                                                }} value={state.student_answer[x]} />

                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                                <TableRow>
                                                    {actualAnswerFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={i} className="text-center" style={AnswerFieldStyle}>
                                                            {answersArray[x]}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                })}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
export default AssignmentGeneratorHistoryComp;