import React, { useState, useEffect } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Input, { ReadonlyInput } from "../../../Setup/Student/Component/Input"
import Heading from "../../../../Common/Heading"
import axios from "axios";
import url from "../../../../Common/URL"
import { useConfirm } from 'material-ui-confirm';
import { Button } from "@mui/material";
import { useSelector } from "react-redux"

const EditActTransComp = () => {
    const navigate = useNavigate();
    const confirm = useConfirm();
    const [loading, setLoading] = useState(false);
    const [stu, setStu] = useState([]);

    const [invItem, setInvItem] = useState([]);

    const { id } = useParams();

    const transaction_option = [
        { name: "Sell" }, { name: "Refund" },
    ];

    const [state, setState] = useState({
        stud_id: "", acc_trans_date: "", fname: "", lname: "",
        type: "", inventory_id: "", price: "",
    })


    useEffect(() => {
        loadTransaction();
        loadStudents();
        loadInventoryItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Existing Locations--------------------
    const loadTransaction = async () => {
        const obj = {
            id: id,
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_single_transaction", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);   
                setState({
                    stud_id: a[0].stud_id, acc_trans_date: a[0].acc_trans_date,
                    type: a[0].type, inventory_id: a[0].inventory_id, fname: a[0].fname,
                    lname: a[0].lname, item_name: a[0].item_name, price: a[0].price
                })
            })
            .catch((err) => console.log(err));
    }
    // -----------This function will Fetch all the Existing Students--------------------
    const loadStudents = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_students", obj)
            .then((response) => {
                let a = response.data.data;
                // console.log(a);
                setStu(a);
            })
            .catch((err) => console.log(err));
    }
    // -----------This function will Fetch all the Existing Inventory Items--------------------

    const loadInventoryItems = () => {
        const obj = { uname: sessionStorage.getItem("username") };

        axios.post(url + "fetch_inventorys", obj)
            .then((response) => {
                // console.log(response)
                if (response.data.success) {
                    let a = response.data.data;
                    // console.log(a);
                    a !== null ? setInvItem(a.filter(x => x.status === "Active")) : setInvItem([]);
                } else {
                    setInvItem([]);
                }
            }).catch((err) => console.log(err));
    }

    //---------------One onChange function is used for setting the state of various types of input fields ---------------     

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }
    // ------------------------Deleting Transaction table's data-------------------------------------

    const onDelete = (e) => {
        e.preventDefault();
        confirm({ title: "Delete Confirmation", description: 'This will delete the student permanently.' })
            .then(() => {
                const delObj = {
                    id: id,
                    uname: state.uname,
                    tokenId: 123,
                };
                axios.post(url + "delete_acc_trans", delObj)
                    .then((res) => {
                        // console.log(res);
                        toast.info("Transaction Deleted")
                        navigate("/act-trans")
                    })
                    .catch((err) => console.log(err));
            })
    }

    //-------------------------- onSubmit function for Submitting the  Forms---------------------------

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        // ------------Storing form data in "obj" object fetched from various input fields-------------
        const obj = {
            uname: sessionStorage.getItem("username"), stud_id: state.stud_id,
            acc_trans_date: state.acc_trans_date, price: state.price,
            type: state.type, inventory_id: "", id: id, item_name: state.item_name,
            updated_by: sessionStorage.getItem("username")
        }
        // ------------Sending form data stored in "obj" object to the add_student file uusing AXIOS-------------

        axios.post(url + "edit_acc_tran", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    toast.success("Accounts transaction updated");

                } else {
                    alert("Unable to update accounts transaction")
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            })

    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'} >
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Accounts</li>
                        <li className="breadcrumb-item "><Link to="/act-trans">Accounts Transactions</Link></li>
                        <li className="breadcrumb-item active">Update Transaction</li>
                    </ol>
                </nav>
                <Heading name="Edit Transaction" />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
                <form onSubmit={onSubmit} id="myform">
                    <div className="container-fluid mt-3">
                        <div className="row my-4">
                            <div className="col-12 d-flex justify-content-end mx-auto">
                                <Button variant="contained" onClick={onDelete} >Delete</Button>
                                <Button variant="contained" disabled={loading} type="submit" className="ms-3">Save</Button>
                            </div>
                        </div>

                        <div className="row">

                            {/*-----------------------Column-1 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto mx-md-0">

                                <ReadonlyInput value={`${state.fname} ${state.lname}`}
                                    field="Student" type="text"  />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Select Item</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="item_name" value={`${state.item_name}^${state.price}`}
                                            onChange={(e) => {
                                                let data = e.target.value;
                                                setState({ ...state, price: data.split("^")[1], item_name: data.split("^")[0] })
                                            }}
                                            className="form-select">
                                            <option value="" >Choose</option>
                                            {invItem.map((data, i) => (<option key={i} value={`${data.product_name}^${data.price}`}>{data.product_name}</option>))}
                                        </select>
                                    </div>
                                </div>


                                <ReadonlyInput value={state.price} field="Price"
                                    type="text"  />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Transaction Type</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="type" value={state.type} onChange={onChange} className="form-select">
                                            <option value="" >Choose</option>
                                            {transaction_option.map((x, i) => <option key={i} value={x.name}>{x.name}</option>)}
                                        </select>
                                    </div>
                                </div>

                                <Input name="acc_trans_date" onChange={onChange} field="Transaction Date" type="date" className="form-control" value={state.acc_trans_date} />

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default EditActTransComp