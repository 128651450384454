import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import Heading from "../../../../Common/Heading";
import url from "../../../../Common/URL";
import Input from "../../Student/Component/Input";

import { useSelector } from "react-redux";

const AddTeacherComp = () => {
  let navigate = useNavigate();

  // This are used to display Values in Select options
  const [loc, setLoc] = useState([]);
  const [batch, setBatch] = useState([]);
  const [lev, setLev] = useState([]);

  // const [usr , setUsr] =useState([]);

  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
    // ------------normal input fields--------------
    fname: "", mname: "", lname: "", city: "", zip: "",
    mobile_no: "", home_no: "", email: "", parent_fname: "", parent_lname: "", parent_mobile_no: "",
    parent_email: "", parent_mobile_no_2: "", parent_email_2: "", join_date: "",
    leave_date: "", registration_number: "", approved_date: "", kvk_number: "",
    contract_period: "",  contract_renewal_date: "",  probation_period: "",
    // ------------Textarea input fields--------------
    addressline1: "",
    addressline2: "",

    // ------------Checkbox input fields--------------
    active: true,
    photo_consent: "",
    disclaimer_signed: "",

    // ------------Select inputs fields--------------
    state: "",
    country: "",
    location_name: [],
    batch_name: [],
    level_name: [],
    user_name: "",
    picture: "",
  });

  useEffect(() => {
    loadLocation();
    loadBatch();
    loadLevel();
    // loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -----------This function will Fetch all the Existing Locations--------------------
  const loadLocation = () => {
    const obj = {
      uname: sessionStorage.getItem("username"),
    };

    axios
      .post(url + "fetch_locations", obj)
      .then((response) => {
        let a = response.data.data;
        //   console.log(a);
        setLoc(a);
      })
      .catch((err) => console.log(err));
  };

  // -----------This function will Fetch all the Existing Batch--------------------
  const loadBatch = () => {
    const obj = {
      uname: sessionStorage.getItem("username"),
    };

    axios
      .post(url + "fetch_batches", obj)
      .then((response) => {
        let a = response.data.data;
        //   console.log(a
        setBatch(a);
      })
      .catch((err) => console.log(err));
  };

  // -----------This function will Fetch all the Existing Levels--------------------
  const loadLevel = () => {
    const obj = {
      uname: sessionStorage.getItem("username"),
    };

    axios
      .post(url + "fetch_levels", obj)
      .then((response) => {
        let a = response.data.data;
        //   console.log(a);
        setLev(a);
      })
      .catch((err) => console.log(err));
  };

  // -------------This function will Filter Batches according to the location Selected in Select input field ---------------------
  const BatchFilter = () => {
    let Location_arr = state.location_name.map((x) => x.value);

    let Filtered_batches = batch.filter((a) => Location_arr.indexOf(a.location_name) >= 0);

    return Filtered_batches.map((x) => ({ label: x.batch_name, value: x.batch_name }));
  };

  //---------------One onChange function is used for setting the state of various types of input fields ---------------

  const onChange = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.type === "checkbox" ? input.checked : input.value;
    setState((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  // ------------------- -------------------functions for Submitting Country and state- ------------------- -------------------
  const selectCountry = (val) => {
    setState((preValue) => {
      return {
        ...preValue,
        country: val,
      };
    });
  };
  const selectRegion = (val) => {
    setState((preValue) => {
      return {
        ...preValue,
        state: val,
      };
    });
  };
  // ------------------- -----------------handleInputChange function for Submitting the profile-pic------------------- -------------------
  // const handleInputChange = (e) =>{
  //     setState({ picture: e.target.files[0] })

  // }
  //-------------------------- onSubmit function for Submitting the  Forms---------------------------

  const onSubmit = (e) => {
    e.preventDefault();

    if (state.location_name.length === 0) {
      toast.error("Please fill the Location field.");
    } else if (state.batch_name.length === 0) {
      toast.error("Please fill the Batch field.");
    } else if (state.level_name.length === 0) {
      toast.error("Please fill the Level field.");
    } else if (state.country.length === 0) {
      toast.error("Please fill the Country field.");
    } else {
      /*---------------Using form data to send profile pic-------------------------- */

      let profile_pic = new FormData();
      profile_pic.append("profile_pic", state.picture);

      /*---------------Fetching Date in YEAR-MONTH-DATE  format-------------------------- */
      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1; //months from 1-12
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();

      var newdate = year + "-" + month + "-" + day;

      // ------------Storing form data in "obj" object fetched from various input fields-------------
      const obj = {
        fname: state.fname,
        mname: state.mname,
        lname: state.lname,
        uname: sessionStorage.getItem("username"),
        created_by: sessionStorage.getItem("username"),
        city: state.city,
        zip: state.zip,
        mobile_no: state.mobile_no,
        home_no: state.home_no,
        email: state.email,
        parent_fname: state.parent_fname,
        parent_lname: state.parent_lname,
        parent_mobile_no: state.parent_mobile_no,
        parent_email: state.parent_email,
        parent_mobile_no_2: state.parent_mobile_no_2,
        parent_email_2: state.parent_email_2,
        created_at: newdate,
        approved_date: state.approved_date,
        registration_number: state.registration_number,
        addressline1: state.addressline1,
        addressline2: state.addressline2,
        kvk_number: state.kvk_number,
        contract_period: state.contract_period,
        contract_renewal_date: state.contract_renewal_date,
        probation_period: state.probation_period,
        join_date: state.join_date,
        leave_date: state.leave_date,

        active: state.active,
        photo_consent: state.photo_consent,
        disclaimer_signed: state.disclaimer_signed,
        user_name: state.user_name,
        state: state.state,
        country: state.country,

        // location_name:state.location_name.map(({value})=>value),
        // batch_name:state.batch_name.map(({value})=>value),
        // level_name:state.level_name.map(({value})=>value),
      };

      const formData = new FormData();
      Object.keys(obj).forEach((key) => formData.append(key, obj[key]));

      state.location_name.length === 0
        ? formData.append("location_name", "")
        : state.location_name.forEach((x) => formData.append("location_name[]", x.value));
      state.batch_name.length === 0
        ? formData.append("batch_name", "")
        : state.batch_name.forEach((x) => formData.append("batch_name[]", x.value));
      state.level_name.length === 0
        ? formData.append("level_name", "")
        : state.level_name.forEach((x) => formData.append("level_name[]", x.value));

      // ------------Sending form data stored in "obj" object to the add_student file uusing AXIOS-------------
      // console.log(...formData)

      axios
        .post(url + "add_teacher", formData)
        .then((res) => {
          console.log(res)
          if (res.data.success) {
            if (res.data.mail_sent === 1) {
              toast.success("New teacher added");
            } else toast.warning("New teacher added, but mail was not sent due to an error");
            navigate("/teacher");
          } else {
            alert("Teacher not created, pLease try again");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        {/*----------------------- Bread crumb ------------------------- */}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item">
              <Link to="/bulletin">Home</Link>
            </li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item">
              <Link to="/teacher">Teachers List</Link>
            </li>
            <li className="breadcrumb-item active">Add Teacher</li>
          </ol>
        </nav>
        <Heading name="Add Teacher" />
        {/* ------------------------------------------------- Two Columns for Inputs --------------------------------------- */}
        <form onSubmit={onSubmit}>
          <div className="container-fluid mt-3">
            <div className="row my-4">
              <div className="col-12 d-flex justify-content-end mx-auto">
                <Button variant="contained" type="submit" >
                  Save
                </Button>
              </div>
            </div>

            <div className="row">
              {/*-----------------------Column-1 for Inputs------------------------- */}
              <div className="col-11 col-md-6 mx-auto">
                <h5 className="mb-4">Personal Information</h5>

                {/* <Input field="Profile Picture" type="file" className="form-control-file" onChange={handleInputChange} name="picture" /> */}

                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Teacher ID</label>
                                <div className="col-sm-7 col-md-6 ">
                                <input type="text" readonly className="form-control-plaintext" value="<Auto generated>" />
                                </div>
                            </div> */}

                <Input
                  name="fname"
                  onChange={onChange}
                  field="First Name"
                  type="text"
                  placeholder="Add text"
                  className="form-control"
                  value={state.fname}
                  required={true}
                />

                <Input
                  name="mname"
                  onChange={onChange}
                  field="Middle Name"
                  type="text"
                  placeholder="Add text"
                  className="form-control"
                  value={state.mname}
                  required={false}
                />

                <Input
                  name="lname"
                  onChange={onChange}
                  field="Last Name"
                  type="text"
                  placeholder="Add text"
                  className="form-control"
                  value={state.lname}
                  required={true}
                />

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Address Line 1</label>
                  <div className="col-sm-7 col-md-7 ">
                    <textarea
                      required
                      name="addressline1"
                      onChange={onChange}
                      value={state.addressline1}
                      className="form-control"
                      placeholder="Add text"></textarea>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label ">Address Line 2</label>
                  <div className="col-sm-7 col-md-7 ">
                    <textarea
                      name="addressline2"
                      onChange={onChange}
                      value={state.addressline2}
                      className="form-control"
                      placeholder="Add text"></textarea>
                  </div>
                </div>

                <Input
                  name="city"
                  onChange={onChange}
                  field="City"
                  type="text"
                  placeholder="Add text"
                  className="form-control"
                  value={state.city}
                  required={true}
                />

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Country</label>
                  <div className="col-sm-7 col-md-7 ">
                    {/* <select name="state" value={state.state} onChange={onChange} className="form-select">
                                    <option value="province-1">Province 1</option><option value="province-2">Province 2</option><option value="province-3">Province 3</option>
                                </select> */}

                    <CountryDropdown
                      className="form-select"
                      name="country"
                      value={state.country}
                      onChange={(val) => selectCountry(val)}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Province</label>
                  <div className="col-sm-7 col-md-7 ">
                    {/* <select name="country" value={state.country} onChange={onChange} className="form-select">
                                    <option value="1">Country 1</option><option value="country-2">Country 2</option><option value="country-3">Country 3</option>
                                </select> */}

                    <RegionDropdown
                      className="form-select"
                      name="state"
                      country={state.country}
                      value={state.state}
                      onChange={(val) => selectRegion(val)}
                    />
                  </div>
                </div>

                <Input
                  name="zip"
                  onChange={onChange}
                  field="Zip"
                  type="text"
                  placeholder="Add text"
                  className="form-control"
                  value={state.zip}
                  required={true}
                />

                <Input
                  name="mobile_no"
                  onChange={onChange}
                  field="Mobile Number"
                  type="text"
                  placeholder="Add text"
                  className="form-control"
                  value={state.mobile_no}
                  required={true}
                />

                <Input
                  name="home_no"
                  onChange={onChange}
                  field="Home Phone"
                  type="text"
                  placeholder="Add text"
                  className="form-control"
                  value={state.home_no}
                  required={false}
                />

                <Input
                  name="email"
                  onChange={onChange}
                  field="Email"
                  type="email"
                  placeholder="Add text"
                  className="form-control"
                  value={state.email}
                  required={true}
                />

                <Input
                  name="kvk_number"
                  onChange={onChange}
                  field="KVK Number"
                  type="text"
                  placeholder="Add text"
                  className="form-control"
                  value={state.kvk_number}
                  required={true}
                />

                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Contract Document</label>
                                <div className="col-sm-7 col-md-7 d-flex align-items-center">
                                   <a href="/404" download >View Contract Document &nbsp;<i className="fa fa-download"></i></a>
                                </div>
                            </div> */}
              </div>

              {/*-----------------------Column-2 for Inputs------------------------- */}
              <div className="col-11 col-md-6 mx-auto">
                <h5 className="mb-4">Batch Information</h5>

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Location Name</label>
                  <div className="col-sm-7 col-md-7 ">
                    <Select
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      isClearable={true}
                      closeMenuOnSelect={false}
                      isMulti
                      options={loc.map((x) => ({ label: x.name, value: x.name }))}
                      value={state.location_name}
                      onChange={(e) => {
                        setState((pre) => {
                          return {
                            ...pre,
                            location_name: e,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Batch Name</label>
                  <div className="col-sm-7 col-md-7 ">
                    <Select
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      isClearable={true}
                      closeMenuOnSelect={false}
                      isMulti
                      options={BatchFilter()}
                      value={state.batch_name}
                      onChange={(e) => {
                        setState((pre) => {
                          return {
                            ...pre,
                            batch_name: e,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Level Name</label>
                  <div className="col-sm-7 col-md-7 ">
                    <Select
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      isClearable={true}
                      isMulti
                      closeMenuOnSelect={false}
                      options={lev.map((x) => ({ label: x.level_name, value: x.level_name }))}
                      value={state.level_name}
                      onChange={(e) => {
                        setState((pre) => {
                          return {
                            ...pre,
                            level_name: e,
                          };
                        });
                      }}
                    />
                  </div>
                </div>

                <h5 className="mt-5 mb-4">Account Information</h5>

                <Input
                  name="join_date"
                  onChange={onChange}
                  field="Join Date"
                  type="date"
                  placeholder="Add text"
                  className="form-control"
                  value={state.join_date}
                  required={true}
                />

                <Input
                  name="probation_period"
                  onChange={onChange}
                  value={state.probation_period}
                  field="Probation Period"
                  type="number"
                  placeholder="In Months"
                  className="form-control"
                />

                <Input
                  name="contract_renewal_date"
                  onChange={onChange}
                  value={state.contract_renewal_date}
                  field="Contract Renewal Date"
                  type="date"
                  placeholder="Add text"
                  className="form-control"
                />

                <Input
                  name="contract_period"
                  onChange={onChange}
                  value={state.contract_period}
                  field="Contract Period"
                  type="number"
                  placeholder="In Months"
                  className="form-control"
                />

                <Input
                  name="leave_date"
                  onChange={onChange}
                  value={state.leave_date}
                  field="Leave Date"
                  type="date"
                  placeholder="Add text"
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default AddTeacherComp;
