import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../Common/Heading";
import url from "../../../../Common/URL";
import { ReadonlyInput } from "../../Student/Component/Input";

import Button from "@mui/material/Button";
import { useConfirm } from 'material-ui-confirm';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { QuestionTableHeaders, QuestionTableRow } from "../../../../Common/BulkUploadHelper";

import xlsxParser from 'xlsx-parse-json';
import TestUploadValidator from "../../../../Common/UploadValidators/TestUploadValidator";
import { useSelector } from 'react-redux';


const ViewTestComp = () => {
  const confirm = useConfirm();


  // ============= For Bulk Upload of Studnets in DB=======================

  const [load, setLoad] = useState(false);
  const [file_import, setFile_import] = useState(null);
  const [open, setOpen] = useState(false);
  const [fileArrayObj, setFileArrayObj] = useState([]);

  const navigate = useNavigate();
  const { id } = useParams();

  const [validated, setValidated] = useState(false);
  const [validatorDialog, setValidatorDialog] = useState(false)
  const [validatorData, setValidatorData] = useState({
    data: [], error_message: []
  })

  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
    test_id: "",
    status: "",
    generator_test: '',
    test_name: "",
    test_type: "",
    level_name: "",
    test_desc: "",
    youtube_link: "",
    page: "",
    assignment_type: "",
  });

  // Below function will fetch the select test values on which we have clicked for editing
  const LoadTest = (res) => {
    const obj = {
      uname: sessionStorage.getItem("username"),
      id: id,
    };
    axios
      .post(url + "fetch_single_test", obj)
      .then((response) => {
        let resData = response.data.data;
        console.log(resData);

        setState({
          ...state,
          test_name: resData[0].test_name,
          test_desc: resData[0].test_desc,
          test_type: resData[0].test_type,
          level_name: resData[0].level_name,
          generator_test: resData[0].generator_test,
          test_id: resData[0].test_id,
          status: resData[0].status,
          youtube_link: resData[0].youtube_link,
          page: resData[0].page,
          assignment_type: resData[0].assignment_type,
        });
      })
      .catch((err) => console.log(err));

    res !== undefined && res();
  };

  useEffect(LoadTest, []);

  // Below function will convert an 'Array of Arrays' into 'Array of objects' with Custom keys

  const ConvertArrayToObject = (ArrayOfArray) => {

    let keyNames = ["ques_num1", "ques_num2", "ques_num3", "ques_num4", "ques_num5", "ques_num6", "ques_num7", "ques_num8", "ques_num9", "ques_num10", "ques_num11", "ques_num12", "ques_num13", "ques_num14", "ques_num15", "ques_num16", "answer", "level_name", "assignment_type", "category_name", "que_type", "page"]


    let ArrayOfObj = [];

    ArrayOfArray.map(x => {
      let simpleObj = Object.assign({}, x);
      // Renaming the simpleObj object's key according to KeyNames array
      for (let i = 0; i < keyNames.length; i++) {
        simpleObj[keyNames[i]] = simpleObj[i];
        delete simpleObj[i];
      }

      simpleObj.uname = sessionStorage.getItem("username"); // Adding "uname" field in every simpleObj
      simpleObj.test_id = state.test_id;
      simpleObj.location_name = "";
      simpleObj.status = "active";

      // console.log(simpleObj);
      ArrayOfObj.push((simpleObj));
    });
    // console.log(ArrayOfObj);
    setFileArrayObj(ArrayOfObj);
  }

  // ------------------------ Deleting Role table's data -------------------------------------
  function onDelete() {
    confirm({ title: "Delete Confirmation", description: 'This will delete the test permanently.' })
      .then(() => {
        const delObj = {
          id: id,
          uname: sessionStorage.getItem("username"),
          tokenId: 123,
        };
        axios
          .post(url + "delete_test", delObj)
          .then((res) => {
            // console.log(res);
            toast.info("Test deleted ");
            navigate("/test");
          })
          .catch((err) => console.log(err));
      })
  }

  //  -------------------- Validate File ---------------------------------------------------------

  const ValidateFile = () => {
    setLoad(true);
    xlsxParser
      .onFileSelection(file_import, { showNullProperties: true, hideEmptyRows: true })
      .then(data => {
        // console.log(data.Sheet1);
        let ArrayOfArray = [];
        data.Sheet1.map(x => ArrayOfArray.push(Object.values(x)));

        ConvertArrayToObject(ArrayOfArray); // this is creating Array of objects form the excel file.  
        const errorArray = TestUploadValidator(ArrayOfArray, state.test_type);

        if (errorArray.length) {
          setValidatorData({ data: ArrayOfArray, error_message: errorArray })
          setLoad(false);
          setOpen(false);
          setFile_import(null);
          setValidatorDialog(true);
        } else {
          setLoad(false);
          setValidated(true);
        }

      }).catch(err => { console.log(err) });
  }

  // -------------------- Upload File -------------------------------------------------------------

  const UploadFile = async () => {
    setLoad(true);

    await axios
      .post(url + "php/questions/excel_upload.php", fileArrayObj)
      .then((res) => {
        // console.log(res);
        if (res.data.success) {
          let promise = new Promise((res, rej) => {
            LoadTest(res);
          })
          promise.then(() => {
            setOpen(false);
            setLoad(false);
            setValidated(false);
            setFile_import(null);
            toast.success(`Questions uploaded successfully`);
          })

        } else alert("Data not inserted, please try again.")
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
        setLoad(false);
        setValidated(false);
        setFile_import(null);
        toast.error(`Questions not uploaded, please try again later.`);
      });
  };


  // ############################# For uploading the Bulk Student upload Modal  #############################

  const handleOpen = () => {
    setOpen(true);
  };

  const onChange = (e) => {
    let file = e.target.files[0];
    setFile_import(file);
  };



  // ------------------------Below function will handel the Test Previw Part  -------------------------------------
  const ShowTestPreview = () => {

    if (state.generator_test === '1') {
      const win = window.open(`/modern-test-exam-preview/${state.test_id}/${0}/${null}`, "_blank");
      win.focus();
    }
    else if (state.test_type === "Exam") {
      const win = window.open(`/exam-preview/${state.test_name}/${state.test_id}/${0}/${null}/${null}`, "_blank");
      win.focus();
    } else if (state.test_type === "Assignment") {
      const win = window.open(`/assignment-preview/${state.test_name}/${state.test_id}/${0}/${null}/${null}`, "_blank");
      win.focus();
    } else {
      const win = window.open(`/test-preview/${state.test_name}/${state.test_id}/${0}/${null}/${null}`, "_blank");
      win.focus();
    }
  }

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Upload Question's File For The ${state.test_type}`}</DialogTitle>
        {validated ?
          <>
            <DialogContent>

              <List dense={false}>
                <ListItem >
                  <ListItemIcon ><CheckCircleOutlineIcon htmlColor="#408140" /></ListItemIcon>
                  <ListItemText>A variety of validations have been conducted on the file.</ListItemText>
                </ListItem>
                <ListItem >
                  <ListItemIcon ><CheckCircleOutlineIcon htmlColor="#408140" /></ListItemIcon>
                  <ListItemText>It appears error-free.</ListItemText>
                </ListItem>
                <ListItem >
                  <ListItemIcon ><CheckCircleOutlineIcon htmlColor="#408140" /></ListItemIcon>
                  <ListItemText>The file can now be uploaded.</ListItemText>
                </ListItem>
              </List>

            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                confirm({ title: "Close Confirmation", description: 'Are you sure, you want to close the popup?' })
                  .then(() => {
                    setFile_import(null);
                    setOpen(false);
                    setValidated(false);
                  })
              }} color="primary" >Close</Button>
              <Button onClick={UploadFile} disabled={file_import === null ? true : false} color="primary" size="small">
                {load ? <CircularProgress size={20} /> : "Upload"}
              </Button>

            </DialogActions>
          </> :
          <>
            <DialogContent>
              <div className="d-flex flex-column ">
                <div>
                  <hr className="mt-0" />
                  <div className="d-flex justify-content-between">
                    <h6>Validate File before Upload</h6>
                    {state.test_type === "Exam" && <a href={`${url}import/ExamQuestionSample.xlsx`}>Sample Exam File Format</a>}
                    {state.test_type === "Test" && <a href={`${url}import/TestQuestionSample.xlsx`}>Sample Test File Format</a>}
                    {state.test_type === "Assignment" && <a href={`${url}import/AssignmentQuestionSample.xlsx`}>Sample Assignment File Format</a>}
                  </div>
                </div>
                {/* <div className="alert alert-danger mt-3">Only <strong>"Excel"</strong> format file allowed.</div> */}

                <input type="file" accept=".xlsx" className="form-control-file mt-1 mb-2" onChange={onChange} />

              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                setOpen(false);
                setFile_import(null);
              }} color="primary" >Close</Button>
              <Button onClick={ValidateFile} disabled={file_import === null ? true : false} color="primary" size="small" >
                {load ? <CircularProgress size={20} /> : `Validate`}
              </Button>

            </DialogActions>
          </>}

      </Dialog>

      {/* ============================================================================================= */}

      {/* ================= Validator Dialog ============================ */}

      <Dialog
        open={validatorDialog}
        // fullWidth= {true}
        // maxWidth="lg"
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title ">{"Data Validation Report Summary"}</DialogTitle>
        <DialogContent >

          {/* ---------- Showing File's data in Table Format -----------------  */}
          <table className="table table-responsive table-borderless shadow-sm " style={{ height: '45%' }}>
            <thead className="shadow-sm border-bottom bg-light table-sm position-sticky" style={{ top: "0" }}>
              <tr>
                <th key={0} scope="col">No.</th>
                {QuestionTableHeaders.map((x, i) => <th key={i} scope="col">{x}</th>)}
              </tr>
            </thead>
            <tbody className="overflow-scroll">
              {validatorData.data.map((x, i) => {
                let test = state.test_type;
                // a_f_r - Assignment fields are Required if a_f_r = 1
                var a_f_r = state.test_type === "Assignment" ? 1 : 0;
                // te_f_r - Exam & Test fields are Required if te_f_r = 1
                var te_f_r = (state.test_type === "Test" || state.test_type === "Exam") ? 1 : 0;
                var que_type_error = x[20] === test ? 0 : 1;  // 0 means no error & 1 means error.
                return QuestionTableRow(x, i, a_f_r, te_f_r, que_type_error);
              })}
            </tbody>
          </table>
          {/* ---------- Showing File's Error Messages in List Format -----------------  */}

          <h6 className="d-flex align-items-center"> <ErrorOutlineIcon fontSize="large" color="action" className="ms-2 me-3" />Errors Encountered are mentioned below</h6>

          <div style={{ height: "40%", overflowY: "scroll" }}>
            {validatorData.error_message.map((x, i) => {
              return <List key={i} dense={true}>
                <ListItem>
                  <ListItemIcon><ErrorIcon color="error" /></ListItemIcon>
                  <ListItemText>{x}</ListItemText>
                </ListItem>
              </List>
            })}
          </div>

        </DialogContent>
        <DialogActions className="border-top">
          <Button onClick={() => setValidatorDialog(false)} color="primary" autoFocus>Close</Button>
        </DialogActions>
      </Dialog>


      {/* ============================================================================================= */}

      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"> <Link to="/bulletin">Home</Link> </li>
            <li className="breadcrumb-item"> Setup </li>
            <li className="breadcrumb-item">  <Link to="/test">Tests</Link></li>
            <li className="breadcrumb-item active" aria-current="page"> View Test</li>
          </ol>
        </nav>

        <Heading name={`View Test - ${state.test_name}`} />
        <div className="container-fluid">
          <div className="row mt-4 ">
            <div className="col-md-12 mx-auto ">




              <div className="row mb-4 ">
                <div className="col-12 d-flex justify-content-between align-items-end ">
                  <h5>Test Information</h5>
                  <div>
                    {(state.status !== "" && state.status === "1") && <Button variant="contained" onClick={ShowTestPreview} className="btn btn-outline-secondary "><VisibilityIcon /></Button>}
                    {(state.status !== "" && state.status === "0") && <Button variant="contained" onClick={handleOpen} className="ms-3 ">Upload Questions</Button>}
                    <Button variant="contained" onClick={onDelete} className="ms-3">Delete</Button>
                    <Button variant="contained" onClick={() => navigate("/edit-test/" + id)} className="ms-3">Edit</Button>
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-11 col-md-7 mx-auto mx-md-0">
                  {/* <div className="mb-3 row">
                      <label className="col-sm-5 col-form-label">Test ID</label>
                      <div className="col-md-6 col-sm-7">
                        <input
                          type="text"
                          readOnly
                          className="form-control bg-light border-0 fw-bold"
                          value={state.test_id}
                        />
                      </div>
                    </div> */}

                  <ReadonlyInput
                    field="Test Name"
                    type="text"
                    placeholder="--"

                    value={state.test_name}
                  />

                  <ReadonlyInput
                    field="Test Type"
                    type="text"
                    placeholder="--"

                    value={state.test_type}
                  />


                  {/* ---Select Input---- */}
                  <ReadonlyInput
                    field="Level"
                    type="text"
                    placeholder="--"
                    className="form-control bg-light border-0 "
                    value={state.level_name}
                  />

                  {state.test_type === "Assignment" && <ReadonlyInput
                    field="Assignment Type"
                    type="text"
                    placeholder="--"
                    className="form-control bg-light border-0 "
                    value={state.assignment_type === "A" ? "Class Work" : "Home Work"}
                  />}

                  {state.test_type === "Assignment" && <ReadonlyInput
                    field="Page"
                    type="text"
                    placeholder="--"
                    className="form-control bg-light border-0 "
                    value={state.page}
                  />}

                  <ReadonlyInput
                    field="Description"
                    type="text"
                    placeholder="--"

                    value={state.test_desc}
                  />

                  <ReadonlyInput
                    field="Youtube Link"
                    type="text"
                    placeholder="--"

                    value={state.youtube_link}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTestComp;
