import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Heading from "../../../../Common/Heading";

import axios from "axios";
import url from "../../../../Common/URL";
import { ReadonlyInput } from "../../../Setup/Student/Component/Input";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DeleteIcon from "@mui/icons-material/Delete";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";

import Tooltip from '@mui/material/Tooltip';
import { toast } from "react-toastify";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { DeleteOutline, Summarize, Visibility } from "@mui/icons-material";
import { useConfirm } from 'material-ui-confirm';
import { CurrentDate, ReadableDate, ReadableDateTime } from "../../../../Common/StringDateTime";

import AssessmentIcon from '@mui/icons-material/Assessment';
import CloudOffIcon from '@mui/icons-material/CloudOff';

import { CircularProgress, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Select from 'react-select';
import { QuestionTypeFullName } from "../../../Test/Mind Math/Component/LevelData";

import { Box, Stack } from "@mui/system";
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";

const StudentDetailComp = () => {
    const confirm = useConfirm();
    const navigate = useNavigate();
    const { id } = useParams();

    // ########################################################  For TAB 1    #####################################################


    const [field, setField] = useState({
        stud_id: "",
        // ------------normal input fields--------------
        fname: "", mname: "", lname: "", city: "", zip: "", mobile_no: "", home_no: "", email: "",
        parent_fname: "", parent_lname: "", parent_mobile_no: "", parent_email: "", parent_mobile_no_2: "",
        parent_email_2: "", join_date: "", leave_date: "", dob: "", gsc: "", stud_code: "", acc_name: "", acc_number: "",

        // ------------Textarea input fields--------------
        addressline1: "", addressline2: "",

        // ------------Checkbox input fields--------------
        active: false, photo_consent: false, disclaimer_signed: false, status: "",

        // ------------Select inputs fields--------------
        state: "", country: "", location_name: "", batch_name: "", level_name: "", user_name: "",
        picture: "",
    });

    useEffect(() => {
        StudentDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ------------------------------
    const StudentDetail = () => {
        const obj = {
            uname: state.uname,
            id: id
        };
        axios.post(url + "fetch_single_student", obj)
            .then((response) => {
                console.log(response);
                let resData = response.data.data;
                console.log(resData);
                setField({
                    fname: resData[0].fname, mname: resData[0].mname, lname: resData[0].lname, city: resData[0].city, zip: resData[0].zip,
                    mobile_no: resData[0].mobile_no, home_no: resData[0].home_no, email: resData[0].email,
                    parent_fname: resData[0].parent_fname, parent_lname: resData[0].parent_lname, parent_mobile_no: resData[0].parent_mobile_no,
                    parent_email: resData[0].parent_email, parent_mobile_no_2: resData[0].parent_mobile_no_2, approved_date: resData[0].approved_date,
                    parent_email_2: resData[0].parent_email_2, join_date: resData[0].join_date, leave_date: resData[0].leave_date,
                    stud_id: resData[0].stud_id,
                    // ------------Textarea input fields--------------
                    addressline1: resData[0].addressline1, addressline2: resData[0].addressline2,

                    // ------------Checkbox input fields--------------
                    active: resData[0].active, photo_consent: resData[0].photo_consent, disclaimer_signed: resData[0].disclaimer_signed,
                    // ------------Select inputs fields--------------
                    state: resData[0].state, country: resData[0].country, location_name: resData[0].location_name, batch_name: resData[0].batch_name,
                    level_name: resData[0].level_name, user_name: resData[0].user_name,

                    gsc: resData[0].gsc, stud_code: resData[0].stud_code, dob: resData[0].dob, acc_name: resData[0].acc_name, acc_number: resData[0].acc_number,
                    status: resData[0].status

                });
                loadTests(Number(resData[0].level_name.split(" ")[1]));
                loadNotes(resData[0].user_name);
                loadScores(resData[0].user_name);
                // LoadMindMath(resData[0].user_name);
            })
            .catch((err) => console.log(err));
    };

    // ------------------------------
    const loadTests = (student_level_name) => {
        const obj = { uname: state.uname };
        axios.post(url + "fetch_tests", obj)
            .then((response) => {
                // console.log(response)
                let a = response.data.data;
                var filtered_test = a.filter(x => {

                    return (Number(x.level_name.split(" ")[1]) <= student_level_name) ||
                        (x.level_name.split(" ")[1] === "5's");  // checking "5's" as we want to show it, bcoz its lower than level 1 

                });
                let assignment = [];
                let test_exam = [];
                filtered_test.forEach(x => {
                    if (x.test_type === "Assignment") assignment.push(x);
                    else test_exam.push(x);
                });
                setTests({ assignment: assignment, test_exam: test_exam });
            })
            .catch((err) => console.log(err));
    };

    // #######################################################   For Tab 2   ########################################################

    const [load, setLoad] = useState(true);
    const [editNote, setEditNote] = useState(false);

    const [state, setState] = useState({
        uname: sessionStorage.getItem("username"), notes: "", msg_id: "", editnote: "",
    });

    const [notesData, setNotesData] = useState(null);

    // ------------------------------
    const loadNotes = (uname) => {
        const obj = { uname: uname };
        axios.post(url + "select_notes", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    let a = response.data.data;
                    let rev = a !== null ? a.reverse() : a;
                    // console.log(a);
                    setLoad(false);
                    rev !== null ? setNotesData(rev) : setNotesData([]);
                } else {
                    setLoad(false);
                    setNotesData(null);
                }
            })
            .catch((err) => console.log(err));
    };

    //-------------------------------------       
    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.value;

        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            };
        });
    };

    //---------------------------------- noteSubmit function to Submit Notes ----------------------
    const noteSubmit = (e) => {
        e.preventDefault();
        const obj = { uname1: field.user_name, notes: state.notes, uname: state.uname, created_by: state.uname, created_at: CurrentDate() };
        axios.post(url + "add_notes", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    toast.success("Note added successfully");
                    loadNotes(field.user_name);
                    setState({ ...state, notes: "", msg_id: "" });

                } else {
                    setState({ ...state, notes: "", msg_id: "" });
                    toast.error("Error occurred while adding note, please try again later.");
                }
            }).catch((err) => console.log(err));
    };

    // -----------This function will Select data before Editing Notes message--------------------

    const Edit_message = (id, stud_uname) => {
        setEditNote(true);
        const obj = {
            id: id, uname: stud_uname
        };
        axios.post(url + "select_edit_notes", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    let a = response.data.data;
                    // console.log(a);
                    setState(pre => {
                        return {
                            ...pre, editnote: a.notes, msg_id: id
                        };
                    });
                }
            }).catch((err) => console.log(err));
    };

    //-------------------------- onEdit_Submit function for Submitting the Edited Note data---------------------------

    const onEdit_Submit = () => {
        const obj = {
            id: state.msg_id, uname: field.user_name, notes: state.editnote, updated_by: state.uname, updated_date: CurrentDate()
        };

        axios.post(url + "edit_notes", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    toast.success("Updated successfully!");
                    setEditNote(false);
                    loadNotes(field.user_name);
                    setState(pre => {
                        return {
                            ...pre, editnote: "", msg_id: ""
                        };
                    });
                } else {
                    setEditNote(false);
                    toast.error("Note not updated, please try again later");
                    setState(pre => {
                        return {
                            ...pre, editnote: "", msg_id: ""
                        };
                    });
                }
            }).catch((err) => console.log(err));
    };

    // -----------This function will Delete Note  message--------------------

    const Delete = (id, stu_uname) => {
        confirm({ title: "Delete Confirmation", description: 'This will delete the note permanently.' })
            .then(() => {
                const obj = { uname: stu_uname, id: id };
                axios.post(url + "delete_notes", obj)
                    .then((response) => {
                        // console.log(response)
                        if (response.data.success) {
                            toast.warning("Note deleted");
                            loadNotes(field.user_name);
                        } else {
                            toast.error("Error occurred! note not deleted, please try again");
                        }
                    })
                    .catch((err) => console.log(err));
            });

    };


    // ###########################################################   For Tab 3   ##################################################

    const [assignedTests, setAssignedTests] = useState([]);

    const AssignedTestColumns = useMemo(
        () => [
            { header: 'Test Name', accessorKey: 'assignment_name' },
            { header: 'Test Type', accessorKey: 'test_type' },
            { header: 'Created Date', accessorKey: 'created_at' },
            {
                header: 'Start Date', id: 'start_date',
                accessorFn: rowData => rowData.test_type === "Assignment" ? "" : rowData.test_date === "0000-00-00 00:00:00" ? "" : ReadableDateTime(rowData.test_date)
            },

        ],
        [],
    );


    const Columns = useMemo(
        () => [
            { header: 'Level', accessorKey: 'level_name' },
            { header: 'Test Name', id: "test_name", accessorFn: rowData => rowData.test_mode === "offline" ? (<span className="d-flex align-items-center">{rowData.test_name}&nbsp;<CloudOffIcon style={{ color: "#810000", fontSize: "95%" }} /></span>) : rowData.test_name },
            { header: 'Date', accessorKey: 'created_at', },
        ],
        [],
    );

    // assignmentType === 1 -> means Assigning of Test or Exam.
    // assignmentType === 0 -> means Assigning of Assignment.
    const [assignmentType, setAssignmentType] = useState("0");

    //  this will help in filtering test types (Assignments & test-exam)
    const [tests, setTests] = useState({
        assignment: [], test_exam: []
    });

    const [score, setScore] = useState({
        exam_test: [], assignment: []
    });

    const [testData, setTestData] = useState({
        test: "", test_date: "", test_obj: []
    });

    const [assignmentData, setAssignmentData] = useState({
        test: [], test_obj: [], test_date: ""
    });

    const [testExamData, setTestExamData] = useState({
        test: "", test_obj: "", test_date: ""
    });

    // ------------------------------
    const loadScores = (uname) => {
        LoadAssignedTests(uname);

        const obj = { uname: uname };
        axios.post(url + "select_score", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    let b = response.data.data;
                    let a = b !== null ? b.reverse() : b;
                    // console.log(a);
                    if (a !== null) {
                        var assignment_arr = [], exam_test_arr = [];
                        a.forEach(x => {
                            if (x.test_type === "Assignment") {
                                assignment_arr.push(x);
                            } else {
                                exam_test_arr.push(x);
                            }
                        });
                        // console.log(assignment_arr);
                        // console.log(exam_test_arr);

                        setScore({ ...score, assignment: assignment_arr.length === 0 ? [] : assignment_arr, exam_test: exam_test_arr.length === 0 ? [] : exam_test_arr });
                    } else {
                        setScore({ ...score, assignment: [], exam_test: [] });
                    }
                } else {
                    setScore({ ...score, assignment: [], exam_test: [] });
                }
            })
            .catch((err) => console.log(err));
    };

    //-------------------------------------
    const LoadAssignedTests = (user_name, res) => {
        const obj = { uname: user_name, role: "Student" };
        axios.post(url + "mytest", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    res !== undefined && res();
                    let a = response.data.data;
                    // console.log(a);
                    a !== null ? setAssignedTests(a.reverse()) : setAssignedTests([]);
                } else {
                    res !== undefined && res();
                    setAssignedTests([]);
                }
            })
            .catch((err) => console.log(err));
    };

    //-------------------------------------
    const handleChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.value;

        setTestData((preValue) => {
            return {
                ...preValue,
                [name]: value
            };
        });
    };

    //-------------------------------------
    const [assignTestLoading, setAssignTestLoading] = useState(false);

    const onAssignSubmit = () => {
        // This function is used to assign test/exam & assignment
        setAssignTestLoading(true);
        // assignmentType === "1" means we are assigning  Test or Exam
        if (assignmentType === "1") {
            let testArray = [];
            var x = testExamData.test.split("*");
            const obj = {
                test_date: x[0] === "Assignment" ? "--" : testData.test_date,
                uname: state.uname, stud_uname: field.user_name, assignment_name: x[1], test_type: x[0],
                level_name: x[2], assignment_type: x[3], test_id: x[5], page: x[4],
            };
            testArray.push(obj);
            console.log(obj);
            axios
                .post(url + "assignment", testArray)
                .then((res) => {
                    console.log(res);

                    if (res.data.success) {
                        LoadAssignedTests(field.user_name);
                        if (res.data.success && res.data.mail_sent === 1) {
                            toast.success("Test assigned");
                        } else if (res.data.success) {
                            toast.warning("Test/Exam assigned, but an error occurred while sending the mail.");
                        } else toast.error("Test/Exam not assigned, please try again later.");
                    } else alert("Error occurred while assigning test, please try again.");
                })
                .then(() => {
                    setAssignTestLoading(false);
                    setTestExamData({ test: "", test_date: "", test_obj: "" });
                    setTestData({ test: "", test_date: "", test_obj: "" });
                })
                .catch((err) => console.log(err));
        } else {
            // here we are assigning Assignment
            let assignmentArray = [];
            assignmentData.test.map(x => {
                var testData = x.split("*");
                let obj = {
                    test_date: "--", uname: state.uname, stud_uname: field.user_name, assignment_name: testData[1],
                    test_type: testData[0], level_name: testData[2], assignment_type: testData[3], test_id: testData[5],
                    page: testData[4],
                };
                assignmentArray.push(obj);
            });
            console.log(assignmentArray);

            axios
                .post(url + "assignment", assignmentArray)
                .then((response) => {
                    console.log(response);
                    let prom = new Promise((res, rej) => LoadAssignedTests(field.user_name, res));
                    prom.then(() => {

                        if (response.data.success && response.data.mail_sent === 1) {
                            toast.success("Assignment(s) assigned");
                        } else if (response.data.success) {
                            toast.warning("Assignment assigned, but an error occurred while sending the mail.");
                        } else toast.error("Assignment not assigned, please try again later.");
                    });
                })
                .then(() => {
                    setAssignTestLoading(false);
                    setTestData({ test: "", test_date: "", test_obj: "" });
                    setAssignmentData({ test: [], test_date: "", test_obj: [] });
                }).catch((err) => console.log(err));

        }
    };

    const [generatorScore, setGeneratorScore] = useState({
        exam_test: [], assignment: []
    });

    useEffect(() => {
        let pro1 = new Promise((resolve, reject) => {
            loadGeneratorScores(resolve);
        });
        pro1.then(() => { });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GeneratorColumns = useMemo(
        () => [
            { header: 'Level', id: 'level', accessorFn: rowData => `Level ${rowData.test_level}` },
            { header: 'Test Name', accessorKey: 'test_name', },
            { header: 'Questions', accessorKey: 'total_questions', },
            { header: 'Attempted %', id: 'attempt_per', accessorFn: rowData => `${rowData.attempted_percentage} %` },
            { header: 'Accuracy %', id: 'acc_per', accessorFn: rowData => `${rowData.total_accuracy_per} %` },
            { header: 'Date', accessorKey: 'created_at' },
        ],
        [],
    );

    const loadGeneratorScores = (res) => {
        const obj = { uname: field.user_name };
        axios.post(url + "view_test_list_stud", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    let b = response.data.data;
                    let a = b !== null ? b.reverse() : b;
                    if (a !== null) {
                        var assignment_arr = a.filter(x => x.test_type === "Assignment");
                        setGeneratorScore({ ...score, assignment: assignment_arr.length === 0 ? [] : assignment_arr });
                    } else {
                        setGeneratorScore({ ...score, assignment: [], exam_test: [] });
                    }

                } else { setGeneratorScore({ ...score, assignment: [], exam_test: [] }); }
                res();
            })
            .catch((err) => console.log(err));
    };

    // ###########################################################   For Tab 4   ##################################################

    // For Mind-Math Table

    const [mmHistoryFetchSpinner, setMmHistoryFetchSpinner] = useState(false);

    const calculateAccuracyPercentage = (x) => `${((x.total_correct / x.questions_attempted) * 100).toFixed(2)} %`;

    const [dateRange, setDateRange] = useState({
        from: "", to: ""
    });
    var MmColumns = [
        { header: 'Question Level', id: 'que_level', accessorFn: rowData => `Level ${rowData.question_level}` },
        { header: 'Type', id: 'type', accessorFn: rowData => QuestionTypeFullName[rowData.question_type] },
        { header: 'Specific', accessorKey: 'question_specific' },
        { header: 'Total Attempted', accessorKey: 'questions_attempted' },
        { header: 'Total Correct', accessorKey: 'total_correct' },
        { header: 'Accuracy Percentage', id: 'accuracy_per', accessorFn: rowData => calculateAccuracyPercentage(rowData) },
    ];
    const [mmData, setMmData] = useState([]);

    const fetchMmHistory = (e) => {
        e.preventDefault();
        if (dateRange.from > dateRange.to) {
            toast.error("This date range is incorrect, make the 'To' field larger than the 'From' field");
            return;
        }
        setMmHistoryFetchSpinner(true);
        const obj = { uname: field.user_name, from: dateRange.from, to: dateRange.to };
        console.log(obj);
        axios.post(url + "select_mm", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    let a = response.data.data;
                    //  console.log(a);
                    !a.length ? setMmData([]) : setMmData(a);
                } else {
                    setMmData([]);
                }
                setMmHistoryFetchSpinner(false);
            })
            .catch((err) => console.log(err));
    };


    return (
        <>
            {/* =============================== Dialog popup for Edit Note button =====================================*/}
            <Dialog fullWidth={true} maxWidth="md" open={editNote}>
                <DialogTitle id="max-width-dialog-title">Edit Note Message <hr className="p-0 " /></DialogTitle>
                <DialogContent>
                    <div className="mb-3 ">
                        <label style={{ fontWeight: "400" }}>Edit Note</label>
                        <textarea name="editnote" onChange={onChange} value={state.editnote}
                            className="form-control" rows="5" placeholder="Add Message here..." />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setEditNote(false);
                            setState(pre => {
                                return {
                                    ...pre, editnote: "", msg_id: ""
                                };
                            });
                        }}
                        color="primary"
                    >Close</Button>
                    <Button
                        onClick={onEdit_Submit}
                        color="primary"
                    >Save</Button>
                </DialogActions>
            </Dialog>
            {/* =================================================================================== */}

            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Students</li>
                        <li className="breadcrumb-item"><Link to="/location-student-table">Students List</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Student details</li>
                    </ol>
                </nav>

                <Heading name={`${field.fname}\u00A0\u00A0\u00A0${field.lname}`} />
                {/* -----------------------------Navigation-Tab--------------------------- */}
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-md-12 mx-auto">
                            <nav>
                                <div className="nav" id="nav-tab" role="tablist">
                                    <a className="nav_tab nav-link active" data-bs-toggle="tab" href="#nav-Personal-info" role="tab" aria-controls="nav-Personal-info" aria-selected="true">Personal Information</a>
                                    <a className="nav_tab nav-link" data-bs-toggle="tab" href="#nav-notes" role="tab" aria-controls="nav-notes" aria-selected="false">Notes</a>
                                    <a className="nav_tab nav-link" data-bs-toggle="tab" href="#nav-assign-test" role="tab" aria-controls="nav-assign-test" aria-selected="false">Assign Test</a>
                                    <a className="nav_tab nav-link" data-bs-toggle="tab" href="#nav-tasks" role="tab" aria-controls="nav-tasks" aria-selected="false">Mind-Math & Assignment History</a>
                                    <a className="nav_tab nav-link" data-bs-toggle="tab" href="#nav-acd-progress" role="tab" aria-controls="nav-acd-progress" aria-selected="false">Exam & Test History</a>
                                </div>
                            </nav>
                            <hr className="mt-0 " />
                            <div className="tab-content" >
                                {/* ----------------------Personal- Information------------------------------- */}
                                <div className="tab-pane fade show active" id="nav-Personal-info" role="tabpanel" aria-labelledby="nav-Personal-info-tab">
                                    <div className="container-fluid">

                                        <div className="row ">
                                            <div className="col-md-12 d-flex justify-content-end align-items-center mb-4">
                                                <Button variant="contained" onClick={() => navigate(`/contact_parent/${id}`)} >Contact Parents</Button>
                                            </div>
                                        </div>

                                        <div className="row mb-5">

                                            <div className="col-11 col-md-6 ">

                                                <h5 className="mb-4">Personal Information</h5>

                                                <ReadonlyInput field="First Name" className="form-control bg-light border-0 " value={field.fname} />

                                                <ReadonlyInput field="Last Name" value={field.lname} />

                                                {sessionStorage.getItem("role") === "Location Manager" && <>

                                                    <ReadonlyInput field="Date of Birth" value={field.dob} />

                                                    <div className="mb-3 row">
                                                        <label className="col-sm-5 col-form-label">Address Line 1</label>
                                                        <div className="col-sm-7 col-md-7 ">
                                                            <textarea readOnly className="form-control bg-light border-0 " placeholder="--" value={field.addressline1}></textarea>
                                                        </div>
                                                    </div>

                                                    <div className="mb-3 row">
                                                        <label className="col-sm-5 col-form-label">Address Line 2</label>
                                                        <div className="col-sm-7 col-md-7 ">
                                                            <textarea readOnly className="form-control bg-light border-0 " placeholder="--" value={field.addressline2} ></textarea>
                                                        </div>
                                                    </div>

                                                    <ReadonlyInput field="City" value={field.city} />

                                                    {/* ---Select Input---- */}
                                                    <ReadonlyInput field="Country" className="form-control bg-light border-0 " value={field.country} />

                                                    {/* ---Select Input---- */}
                                                    <ReadonlyInput field="Province" className="form-control bg-light border-0 " value={field.state} />

                                                    <ReadonlyInput field="Zip" value={field.zip} />

                                                    <ReadonlyInput field="Mobile Number" value={field.mobile_no} />

                                                    <ReadonlyInput field="Home Phone" value={field.home_no} />

                                                    <ReadonlyInput field="Email" type="email" placeholder="--" value={field.email} />


                                                    <h5 className="mt-5 mb-4">Parent Information</h5>

                                                    <ReadonlyInput field="Parent First Name" value={field.parent_fname} />

                                                    <ReadonlyInput field="Parent Last Name" value={field.parent_lname} />

                                                    <ReadonlyInput field="Parent Mobile Number" value={field.parent_mobile_no} />

                                                    <ReadonlyInput field="Parent Email" value={field.parent_email} />

                                                    <ReadonlyInput field="Parent Mobile Number 2" value={field.parent_mobile_no_2} />

                                                    <ReadonlyInput field="Parent Email 2" value={field.parent_email_2} />
                                                </>}

                                            </div>
                                            <div className="col-11 col-md-6 ">
                                                {sessionStorage.getItem("role") === "Location Manager" && <>

                                                    <h5 className="mb-4">Batch Information</h5>

                                                    {/* ---Select Input---- */}
                                                    <ReadonlyInput field="Location" className="form-control bg-light border-0 " value={field.location_name} />

                                                    {/* ---Select Input---- */}
                                                    <ReadonlyInput field="Batch" className="form-control bg-light border-0 " value={field.batch_name} />

                                                    {/* ---Select Input---- */}
                                                    <ReadonlyInput field="Level" className="form-control bg-light border-0 " value={field.level_name} />
                                                </>}

                                                <h5 className=" mb-4">Extra Information</h5>

                                                {sessionStorage.getItem("role") === "Location Manager" && <>

                                                    <ReadonlyInput field="User" className="form-control bg-light border-0 " value={field.user_name} />

                                                    <ReadonlyInput field="Global Student Code" className="form-control bg-light border-0 " value={field.gsc} />

                                                    <ReadonlyInput field="Student Code" className="form-control bg-light border-0 " value={field.stud_code} />

                                                    <ReadonlyInput field="Status" className="form-control bg-light border-0 " value={field.status} />
                                                </>}

                                                <div className="mb-3 row">
                                                    <label className="col-sm-5 col-form-label">Photography Consent Provided?</label>
                                                    <div className="col-sm-7 col-md-7 ps-4">
                                                        <div className="form-check">
                                                            <input readOnly className="form-check-input" type="checkbox" checked={(field.photo_consent === "true" || field.photo_consent === "1") ? true : false} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label className="col-sm-5 col-form-label">Disclaimer Signed?</label>
                                                    <div className="col-sm-7 col-md-7 ps-4">
                                                        <div className="form-check">
                                                            <input readOnly className="form-check-input" name="disclaimer_signed" type="checkbox" checked={(field.disclaimer_signed === "true" || field.disclaimer_signed === "1") ? true : false} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <ReadonlyInput name="join_date" field="Join Date" type="date" placeholder="--" value={field.join_date} />

                                                {sessionStorage.getItem("role") === "Location Manager" && <>


                                                    <ReadonlyInput field="Account Name" className="form-control bg-light border-0 " value={field.acc_name} />

                                                    <ReadonlyInput field="Account Number" className="form-control bg-light border-0 " value={field.acc_number} />

                                                    <ReadonlyInput name="leave_date" field="Leave Date" type="date" placeholder="--" value={field.leave_date} />
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ----------------------Personal-Information-End------------------------------- */}

                                {/* --------------------  Notes-tab-Start------------------------------- */}
                                <div className="tab-pane fade mt-5" id="nav-notes" role="tabpanel" aria-labelledby="nav-notes-tab">
                                    <div className="container-fluid">
                                        <form onSubmit={noteSubmit}>
                                            <div className="mb-3">
                                                <textarea className="border-dark form-control bg-light shadow-sm"
                                                    style={{ border: "none", borderLeft: "#174873 5px solid" }}
                                                    placeholder="Enter text here..."
                                                    name="notes"
                                                    value={state.notes}
                                                    onChange={onChange}
                                                    rows="3"
                                                    required />
                                            </div>
                                            <div className="text-end">
                                                <Button variant="contained" type="submit" >Save</Button>
                                            </div>
                                        </form>

                                        <div className="row">
                                            <div className="col-12 mx-auto d-block">
                                                <hr className="w-25 mx-auto my-5 " />
                                                {load ? (
                                                    <div className="row">
                                                        <div className="spinner-grow text-primary mx-auto" />
                                                    </div>
                                                ) : notesData ? (
                                                    notesData.slice(0).reverse().map((data) => {
                                                        return (
                                                            <Card key={data.note_id} style={{ backgroundColor: "#f5f5f5" }} className="mb-4 pt-1 px-3 pb-1 position-relative">
                                                                <div className="d-flex align-items-center ">
                                                                    <p className="m-0"> <DoubleArrowIcon fontSize="small" /> {data.created_by}</p>
                                                                    {(sessionStorage.getItem("role") === "Superadmin" || sessionStorage.getItem("role") === "Systemadmin" || sessionStorage.getItem("role") === "Teacher") && (
                                                                        <div className="d-flex align-items-center ms-auto">
                                                                            <IconButton onClick={() => Delete(data.note_id, data.user_name)}><DeleteIcon fontSize="small" /></IconButton>
                                                                            <IconButton onClick={() => Edit_message(data.note_id, data.user_name)}>
                                                                                <EditIcon fontSize="small" />
                                                                            </IconButton>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <hr className="m-0" />
                                                                <CardContent>
                                                                    <p className="ms-2 d-flex align-items-center" style={{ fontWeight: "600" }}>
                                                                        <CalendarTodayIcon fontSize="small" className="me-2" />{data.notes}
                                                                    </p>
                                                                    <p className="position-absolute text-muted" style={{ bottom: "-.5em", right: "1.7em", fontWeight: "500", fontSize: ".9em" }} >{ReadableDate(data.created_at)}</p>
                                                                </CardContent>
                                                            </Card>
                                                        );
                                                    })
                                                ) : <h6 className="d-flex align-items-center"><ErrorOutlineIcon className="me-2" />No Notes Found</h6>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                {/* ----------------------  Notes-Tab-End-------------------------- */}

                                {/* -------------------------- Assign - Test-Tab-Start--------------------------- */}
                                <div className="tab-pane fade mt-4" id="nav-assign-test" role="tabpanel" aria-labelledby="nav-assign-test-tab">
                                    <div className="container-fluid">
                                        <h5 className="mb-3">Assign Test</h5>


                                        <div className="mb-3 row">
                                            <label className="col-auto col-form-label">What do you want to assign?</label>
                                            <div className="ms-4">
                                                <RadioGroup row value={assignmentType}
                                                    onChange={(e) => {
                                                        setAssignmentType(e.target.value);
                                                        setTestData({ test: "", test_date: "", test_obj: "" });
                                                    }}
                                                >
                                                    <FormControlLabel value={"0"} control={<Radio color="primary" />} label="Assignment" />
                                                    <FormControlLabel value={"1"} control={<Radio color="primary" />} label="Test or Exam" />
                                                </RadioGroup>
                                            </div>
                                        </div>


                                        <div className="row mb-4 ">
                                            {assignmentType === "0" &&
                                                <div className="col-md-7 d-flex flex-column">
                                                    <p className="mb-2">Select Assignment</p>
                                                    <Select
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        isClearable={true}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        options={tests.assignment.map(x => ({
                                                            label: `${x.test_type} - ${x.test_name}`,
                                                            value: `${x.test_type}*${x.test_name}*${x.level_name}*${x.assignment_type}*${x.page}*${x.test_id}`
                                                        }))}
                                                        value={assignmentData.test_obj}
                                                        onChange={(e) => {
                                                            console.log(e);
                                                            let name_of_array = e.map(x => x.value);
                                                            console.log(name_of_array);
                                                            setAssignmentData({ ...assignmentData, test: name_of_array, test_obj: e });
                                                        }}
                                                    />
                                                </div>
                                            }

                                            {assignmentType === "1" && <>
                                                <div className="col-md-7 d-flex flex-column">
                                                    <p className="mb-2">Select Test/Exam</p>
                                                    <div>
                                                        <Select
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            isClearable={true}
                                                            value={testExamData.test_obj}
                                                            onChange={(e) => {
                                                                // console.log(e);
                                                                // console.log(e.value)
                                                                if (e === null)
                                                                    setTestExamData({ ...testExamData, test: "", test_obj: "" });
                                                                else
                                                                    setTestExamData({ ...testExamData, test: e.value, test_obj: e });
                                                            }}
                                                            options={tests.test_exam.map(x => ({
                                                                label: `${x.test_type} - ${x.test_name}`,
                                                                value: `${x.test_type}*${x.test_name}*${x.level_name}*${x.assignment_type}*${x.page}*${x.test_id}`
                                                            }))}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 d-flex flex-column">
                                                    <p className="mb-2">Start Date</p>
                                                    <input type="datetime-local" name="test_date" className="form-control" value={testData.test_date} onChange={handleChange} />
                                                </div>
                                            </>
                                            }

                                            <div className="col-md-2 d-flex justify-content-center align-items-end">
                                                {assignTestLoading ? <CircularProgress size={25} className='mb-2' /> :
                                                    <Button variant="contained" onClick={onAssignSubmit}
                                                        disabled={assignmentType === "0" ? (assignmentData.test_obj.length === 0 ? true : false)
                                                            : (testExamData.test_obj === "" ? true : false)}
                                                        className="ms-3">Assign</Button>}
                                            </div>
                                        </div>


                                        <h5>Assigned Tests</h5>
                                        <div className="row mb-5">
                                            <div className="col-12 mx-auto">
                                                <MaterialReactTable
                                                    columns={AssignedTestColumns}
                                                    data={assignedTests}
                                                    enableRowNumbers
                                                    initialState={{ density: 'compact' }}
                                                    enableDensityToggle={false}
                                                    positionActionsColumn='last'
                                                    enableRowActions
                                                    renderRowActions={(row, index) => (
                                                        <Box>
                                                            <Tooltip title="Cancel Assigned Test" placement="right">
                                                                <IconButton onClick={() => {
                                                                    let rowData = row.row.original;
                                                                    const obj = { uname: rowData.username, id: rowData.assign_id, test_id: rowData.test_id };
                                                                    axios.post(url + "delete_assignment", obj)
                                                                        .then(res => {
                                                                            // console.log(res);
                                                                            let promise = new Promise((res, rej) => LoadAssignedTests(rowData.username, res));
                                                                            promise.then(() => toast.info("Test canceled successfully"));
                                                                        })
                                                                        .catch(res => alert("Error occurred while canceling, please try again later."));
                                                                }}>
                                                                    <DeleteOutline />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* -------------------------- Assign - Test-Tab-End --------------------------- */}

                                {/* -------------------------- Tasks-Tab-Start --------------------------- */}
                                <div className="tab-pane fade mt-4" id="nav-tasks" role="tabpanel" aria-labelledby="nav-tasks-tab">
                                    <div className="container-fluid">

                                        <h5>Mind Math History</h5>

                                        <div className="row mt-3">
                                            <div className="col-12 mb-4">
                                                <form onSubmit={fetchMmHistory} >
                                                    <h6 className="form-label">Select Date Range</h6>
                                                    <Stack direction='row' gap={2} className="align-items-center">
                                                        <div className="form-floating ">
                                                            <input className="form-control" type="date" onChange={(e) => setDateRange({ ...dateRange, from: e.target.value })} required></input>
                                                            <label htmlFor="floatingInput">From</label>
                                                        </div>

                                                        <div className="form-floating ">
                                                            <input className="form-control" type="date" onChange={(e) => setDateRange({ ...dateRange, to: e.target.value })} required></input>
                                                            <label htmlFor="floatingInput">To</label>
                                                        </div>
                                                        {mmHistoryFetchSpinner ?
                                                            <CircularProgress size={20} className='ms-4' /> :
                                                            <Button variant="contained" type="submit">Proceed</Button>
                                                        }
                                                    </Stack>
                                                </form>
                                            </div>
                                            <div className="col-12 mx-auto">

                                                <MaterialReactTable
                                                    columns={MmColumns}
                                                    data={mmData}
                                                    enableRowNumbers
                                                    initialState={{ density: 'compact' }}
                                                    enableDensityToggle={false}

                                                />

                                            </div>
                                        </div>

                                        <h5 className="mt-4 mb-3">Assignment History</h5>

                                        <div className="row mb-5">
                                            <div className="col-12 mx-auto">
                                                <MaterialReactTable
                                                    columns={Columns}
                                                    data={score.assignment}
                                                    enableRowNumbers
                                                    initialState={{ density: 'compact' }}
                                                    enableDensityToggle={false}

                                                    positionActionsColumn='last'
                                                    enableRowActions
                                                    renderRowActions={(row, index) => (
                                                        <Box>
                                                            {row.row.original.test_mode !== "offline" &&
                                                                <Tooltip title="View Answers" placement="left">
                                                                    <IconButton onClick={() => {
                                                                        let rowData = row.row.original;
                                                                        var level_name = rowData.level_name;
                                                                        var test_type = rowData.test_type;
                                                                        var test_name = rowData.test_name;
                                                                        var test_id = rowData.test_id;
                                                                        var st_uname = rowData.user_name;
                                                                        var date_time = rowData.created_at;

                                                                        if (rowData.generator_test === '1') {
                                                                            const win = window.open(`/modern-test-exam-preview/${rowData.test_id}/${1}/${rowData.user_ans_id}`, "_blank");
                                                                            win.focus();
                                                                        }
                                                                        else if (test_type === "Exam") {
                                                                            const win = window.open(`/exam-preview/${test_name}/${test_id}/${1}/${date_time}/${st_uname}`, "_blank");
                                                                            win.focus();
                                                                        } else if (test_type === "Assignment") {
                                                                            const win = window.open(`/assignment-preview/${test_name}/${test_id}/${1}/${date_time}/${st_uname}`, "_blank");
                                                                            win.focus();
                                                                        } else {
                                                                            const win = window.open(`/test-preview/${test_name}/${test_id}/${1}/${date_time}/${st_uname}`, "_blank");
                                                                            win.focus();
                                                                        }
                                                                    }}>
                                                                        <Visibility />
                                                                    </IconButton>
                                                                </Tooltip>}
                                                        </Box>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <h5 className="mt-4 mb-3">Generator Assignment History</h5>

                                        <div className="row mb-5">
                                            <div className="col-12 mx-auto">

                                                <MaterialReactTable
                                                    columns={GeneratorColumns}
                                                    data={generatorScore.assignment}
                                                    enableRowNumbers
                                                    initialState={{ density: 'compact' }}
                                                    enableDensityToggle={false}

                                                    positionActionsColumn='last'
                                                    enableRowActions
                                                    renderRowActions={(row, index) => (
                                                        <Box>
                                                            {row.row.original.test_mode !== "offline" &&
                                                                <Tooltip title="View Answers" placement="left">
                                                                    <IconButton onClick={() => {
                                                                        let rowData = row.row.original;
                                                                        var stud_uname = rowData.created_by;
                                                                        var stud_ans_id = rowData.stud_ans_id;
                                                                        const win = window.open(`/generator-assignment-history/${stud_uname}/${stud_ans_id}`, "_blank");
                                                                        win.focus();
                                                                    }}>
                                                                        <Visibility />
                                                                    </IconButton>
                                                                </Tooltip>}
                                                        </Box>
                                                    )}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* -------------------------- Tasks-Tab-End --------------------------- */}

                                {/* -------------------------- Acadmic Progress -Tab-Start --------------------------- */}
                                <div className="tab-pane fade mt-4" id="nav-acd-progress" role="tabpanel" aria-labelledby="nav-acd-progress-tab">
                                    <div className="container-fluid">
                                        <div className="row mt-4 mb-3 d-flex justify-content-end">
                                            <div className="col-12 ">
                                                <div className="d-flex flex-column ">
                                                    <p style={{ fontWeight: "500" }}><AssessmentIcon style={{ color: "#4BB543" }} />&nbsp; Report Available To Download</p>
                                                    <p style={{ fontWeight: "500" }}><AssessmentIcon />&nbsp; Report Not Available To Download</p>
                                                    <p style={{ fontWeight: "500" }}><CloudOffIcon style={{ color: "#810000" }} />&nbsp; Test given Offline</p>
                                                </div>
                                            </div>
                                        </div>

                                        <h5 className="mt-4 mb-3">Exam And Test History</h5>

                                        <div className="row mb-5">
                                            <div className="col-12 mx-auto">

                                                <MaterialReactTable
                                                    columns={Columns}
                                                    data={score.exam_test}
                                                    enableRowNumbers
                                                    initialState={{ density: 'compact' }}
                                                    enableDensityToggle={false}

                                                    positionActionsColumn='last'
                                                    enableRowActions
                                                    renderRowActions={(row, index) => (
                                                        <Stack direction='row'>
                                                            {/* // --------------------View Answers Icon------------------- */}
                                                            {row.row.original.test_mode !== "offline" &&
                                                                <Tooltip title="View Answers">
                                                                    <IconButton onClick={() => {
                                                                        let rowData = row.row.original;
                                                                        var level_name = rowData.level_name;
                                                                        var test_type = rowData.test_type;
                                                                        var test_name = rowData.test_name;
                                                                        var test_id = rowData.test_id;
                                                                        var st_uname = rowData.user_name;
                                                                        var date_time = rowData.created_at;

                                                                        if (rowData.generator_test === '1') {
                                                                            const win = window.open(`/modern-test-exam-preview/${rowData.test_id}/${1}/${rowData.user_ans_id}`, "_blank");
                                                                            win.focus();
                                                                        }
                                                                        else if (test_type === "Exam") {

                                                                            const win = window.open(`/exam-preview/${test_name}/${test_id}/${1}/${date_time}/${st_uname}`, "_blank");
                                                                            win.focus();

                                                                        } else {
                                                                            const win = window.open(`/test-preview/${test_name}/${test_id}/${1}/${date_time}/${st_uname}`, "_blank");
                                                                            win.focus();
                                                                        }
                                                                    }}>
                                                                        <Visibility />
                                                                    </IconButton>
                                                                </Tooltip>}

                                                            {/* // --------------------View Report Icon------------------- */}

                                                            <Tooltip title="View Report">
                                                                <IconButton
                                                                    style={{ color: row.row.original.approved === "1" && "#4BB543" }}
                                                                    onClick={() => {
                                                                        let rowData = row.row.original;
                                                                        navigate(`/view-test-history/${rowData.user_ans_id}`, { state: { from: "StudentDetailPage", id: id, description: "Here id is coming to move back to Student Detail Page" } });
                                                                    }}>
                                                                    <Summarize />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    )}
                                                />


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* -------------------------- Academic Progress -Tab-End --------------------------- */}

                            </div>
                        </div>
                    </div>
                </div>
                {/* ----------------------------Navigation-Tab-End------------------------------- */}
            </div>
        </>
    );
};

export default StudentDetailComp;