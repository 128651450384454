import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../../Common/Heading";

import DeleteOutline from "@mui/icons-material/DeleteOutline";

import axios from "axios";
import url from "../../../../../Common/URL";

import { useConfirm } from "material-ui-confirm";

import { IconButton } from "@mui/material";
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";

const EnquiryTableComp = () => {
  const confirm = useConfirm();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [originalData, setOriginalData] = useState([]);

  const [searchfield, setSearchfield] = useState({
    location: [],
    status: [],
    startDate: "",
    endDate: "",
  });

  const [loc_name, setLoc_name] = useState([]);

  const Columns = useMemo(
    () => [
      { header: "Name", id: 'name', accessorFn: (rowData) => `${rowData.fname} ${rowData.lname}`, size: 30 },
      { header: "Campaign", accessorKey: "campaign", size: 30 },
      {
        header: "Status", id: 'status', accessorFn: (rowData) => (rowData.status === "" ? "--" : rowData.status),
        size: 30
      },
      { header: "Category", accessorKey: "category", size: 30 },
      { header: "Location", accessorKey: "location", size: 30 },
      { header: "Preferred Date", accessorKey: "preferred_date", size: 30 },
      { header: "Preferred Time", accessorKey: "preferred_time", size: 30 },
      { header: "Created On", accessorKey: 'created_at', size: 30 },
    ],
    [],
  );
  const [data, setData] = useState([]);

  // ------------------------Fetching Icons for Table-------------------------------------

  // ------------------------ Running the Advanced search on Table-------------
  useEffect(() => {
    SearchSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchfield]);

  // ------------------------Fetching Enquiry table's data-------------
  useEffect(() => {
    loadEnquiry();
    LoadLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadEnquiry = async () => {
    const obj = { uname: sessionStorage.getItem("username") };
    await axios
      .post(url + "select_enquiry", obj)
      .then((response) => {

        if (response.data.success) {
          let a = response.data.data;
          //  console.log(a);
          if (sessionStorage.getItem("role") === "Location Manager") {
            let manager_locations = sessionStorage.getItem("location").toLowerCase().split(",");
            let filtered = a.filter(x => manager_locations.indexOf(x.location.toLowerCase()) > 0);
            setLoading(false);
            filtered !== null ? setData(filtered.reverse()) : setData([]);
            filtered !== null ? setOriginalData(filtered.reverse()) : setOriginalData([]);
          } else {
            setLoading(false);
            a !== null ? setData(a.reverse()) : setData([]);
            a !== null ? setOriginalData(a.reverse()) : setOriginalData([]);
          }
        } else {
          setLoading(false);
          setData([]);
          setOriginalData([]);
        }
      })
      .catch((err) => console.log(err));
  };

  const LoadLocation = () => {
    axios
      .post(url + "fetch_locations", { uname: sessionStorage.getItem("username") })
      .then((response) => {
        let a = response.data.data;
        let locations = a.map((a) => a.name); //This is converting an Object into an array of strings containing names respectively
        if (sessionStorage.getItem("role") === "Location Manager") {
          let manager_locations = sessionStorage.getItem("location").split(",");

          setLoc_name(manager_locations);
        } else {
          setLoc_name(locations);
        }

      })
      .catch((err) => console.log(err));
  };

  // ------------------------Deleting Batch table's data-------------------------------------

  const onDelete = async (id, resolve) => {
    const delObj = {
      id: id,
      uname: sessionStorage.getItem("username"),
      tokenId: 123,
    };

    await axios
      .post(url + "delete_enquiry", delObj)
      .then((res) => {
        // console.log(res);
        resolve();
      })
      .catch((err) => console.log(err));
  };

  // ========================================================================================

  const SearchSubmit = () => {
    var location = searchfield.location.map((a) => a.value);
    var status = searchfield.status.map((a) => a.value);
    var start_date = searchfield.startDate === "" ? searchfield.startDate : new Date(searchfield.startDate).getTime();
    var end_date = searchfield.endDate === "" ? searchfield.endDate : new Date(searchfield.endDate).getTime();

    let filter_1 = location.length === 0 ? originalData : originalData.filter((stu) => location.includes(stu.location)); //  Filtering location
    let filter_2 = status.length === 0 ? filter_1 : filter_1.filter((stu) => status.includes(stu.status)); // Filtering Status

    let filter_3 =
      start_date === ""
        ? filter_2 // Filtering dates greater than Start Date
        : filter_2.filter((stu) => {
          let stu_date = stu.created_at.split(" ")[0];
          let stu_time = new Date(stu_date).getTime();

          return stu_time >= start_date;
        });

    let filter_4 =
      end_date === ""
        ? filter_3 // Filtering Date smaller than End Date
        : filter_3.filter((stu) => {
          let stu_date = stu.created_at.split(" ")[0];
          let stu_time = new Date(stu_date).getTime();

          return stu_time <= end_date;
        });

    filter_4.length > 0 ? setData(filter_4) : setData([]);
  };

  // ========================================================================================
  const ClearFilter = () => {
    setSearchfield({
      location: [],
      status: [],
      startDate: "",
      endDate: "",
    });
    setData(originalData);
  };

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item">
              <Link to="/bulletin">Home</Link>
            </li>
            <li className="breadcrumb-item">CRM</li>
            <li className="breadcrumb-item active" aria-current="page">General Enquiries</li>
          </ol>
        </nav>

        <Heading name="General Enquiries" />
      
        {/* =========================  Enquiry List Table =============================================== */}

        <div className="container-fluid mb-5">
          <div className="row ">
            <div className="col-12 mx-auto">

              <MaterialReactTable
                state={{ isLoading: loading }}
                columns={Columns}
                data={data}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                getRowId={(originalRow) => originalRow.enq_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/view-enquiry/" + row.id),
                  sx: { cursor: 'pointer' },
                })}

                renderTopToolbarCustomActions={({ table }) => (
                  <IconButton
                    disabled={table.getSelectedRowModel().rows.length ? false : true}
                    onClick={() => {
                      confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                        .then(() => {
                          let selectedRows = table.getSelectedRowModel().rows;
                          // console.log(selectedRows);
                          const delPromise = selectedRows.map((row) => {
                            return new Promise((resolve, reject) => {
                              onDelete(row.id, resolve);
                            })
                          })
                          Promise.all(delPromise).then(() => {
                            loadEnquiry();
                            table.resetRowSelection();
                            toast.info(selectedRows.length + " Row(s) Deleted!")
                          });
                        })
                    }}
                  ><DeleteOutline />
                  </IconButton>
                )}
              />
            </div>
          </div>
        </div>

      </div>
    </>
  );
};
export default EnquiryTableComp;
