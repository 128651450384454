import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import url from "../../../Common/URL";
import { toast } from "react-toastify";
import Input from "../../Setup/Student/Component/Input";
import Heading from "../../../Common/Heading";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const AddTimeSheetComp = () => {
  let navigate = useNavigate();

  const [proj, setProj] = useState([]);
  const [task, setTask] = useState([]);

  const [state, setState] = useState({
    workdate: "",
    work_day: "",
    work_hours: "",
    user_id: "",
    project: "",
    task: "",
    status: "",
  });

  useEffect(() => {
    LoadProjects();
    loadTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ------------------------Fetching Projects ------------------------------------

  const LoadProjects = () => {
    const obj = { uname: sessionStorage.getItem("username") };
    axios
      .post(url + "fetch_projects", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
         !a.length ? setProj([]) : setProj(a);
        } else {
          setProj([]);
        }
      })
      .catch((err) => console.log(err));
  };

  // ------------------------Fetching Tasks-------------------------------------

  const loadTasks = () => {
    const obj = { uname: sessionStorage.getItem("username") };
    axios
      .post(url + "fetch_tasks", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
         !a.length ? setTask([]) : setTask(a);
        } else {
          setTask([]);
        }
      })
      .catch((err) => console.log(err));
  };

  //---------------One onChange function is used for setting the state of various types of input fields ---------------

  const onChange = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.type === "checkbox" ? input.checked : input.value;

    setState((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  //-------------------------- onSubmit function for Submitting the  Forms---------------------------

  const onSubmit = (e) => {
    e.preventDefault();

    // ------------Storing form data in "obj" object fetched from various input fields-------------
    const obj = {
      uname: sessionStorage.getItem("username"),
      user_id: sessionStorage.getItem("id"),
      workdate: state.workdate,
      work_day: state.work_day,
      work_hours: state.work_hours,
      project: state.project,
      task: state.task,
      status: state.status,
      created_by: sessionStorage.getItem("username"),
    };
    // ------------Sending form data stored in "obj" object to the add_student file uusing AXIOS-------------
    // console.log(obj);

    axios
      .post(url + "add_timesheet", obj)
      .then((res) => {
        // console.log(res)
        if ((res.data.success)) {
          toast.success("Timesheet Created successfully");
          navigate("/timesheet");
        } else {
          alert("Timesheet Not created, Please try again");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item">
              <Link to="/bulletin">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/timesheet">Timesheet</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Add Update
            </li>
          </ol>
        </nav>

        <Heading name="Add Update" />

        <div className="container-fluid">
          <form onSubmit={onSubmit} id="myform">
            <div className="row mb-4">
              <div className="col-12 d-flex justify-content-between ">
                <h5>Add Information</h5>
                <div>
                  <Button variant="contained" onClick={() => navigate('/timesheet')}>
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit" className=" mx-3 ">
                    Save
                  </Button>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-11 col-md-7 mx-auto mx-md-0">
                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Project Name</label>
                  <div className="col-md-7 col-sm-7">
                    <select
                      name="project"
                      value={state.project}
                      onChange={onChange}
                      className="form-select"
                      required>
                      <option value="">Choose</option>
                      {proj.map((x, i) => (
                        <option key={i} value={x.project_name}>
                          {x.project_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Task Name</label>
                  <div className="col-md-7 col-sm-7">
                    <select
                      name="task"
                      value={state.task}
                      onChange={onChange}
                      className="form-select"
                      required>
                      <option value="">Choose</option>
                      {task
                        .filter((x) => x.project_name === state.project)
                        .map((x, i) => (
                          <option key={i} value={x.task_name}>
                            {x.task_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label red-asterisk">Day of Week</label>
                        <div className="col-md-7 col-sm-7">
                            <select name="work_day"  value={state.work_day} onChange={onChange}  className="form-select" required>
                            <option value="">Choose</option><option value="Monday">Monday</option><option value="Tuesday">Tuesday</option><option value="Wednesday">Wednesday</option>
                                <option value="Thursday">Thursday</option><option value="Friday">Friday</option><option value="Saturday">Saturday</option>
                                <option value="Sunday">Sunday</option>
                            </select>
                        </div>
                    </div> */}

                <Input
                  name="workdate"
                  onChange={(e) => {
                    var date = e.target.value;
                    var day = new Date(date.toString()).toLocaleString("en-us", { weekday: "long" });
                    // console.log(day);
                    // console.log(date);
                    setState({ ...state, work_day: day, workdate: date });
                  }}
                  value={state.workdate}
                  field="Date"
                  type="date"
                  className="form-control"
                  required={true}
                />

                <Input
                  name="work_hours"
                  onChange={onChange}
                  placeholder="E.g. : 2"
                  value={state.work_hours}
                  field="Hours Consumed"
                  type="number"
                  className="form-control"
                  required={true}
                />

                {/*<div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Status</label>
                        <div className="col-sm-7 col-md-7 ">
                            <select name="status" 
                                value={state.status} 
                                onChange={onChange} 
                                className="form-select" >
                                <option value="">Choose</option>
                                <option></option>
                            </select>
                        </div>
                    </div> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddTimeSheetComp;
