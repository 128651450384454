import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../../../Common/Heading";
import { ReadableDateTime } from "../../../../Common/StringDateTime";
import axios from "axios";
import url from "../../../../Common/URL";
import LoadingSpinner from "../../../../Common/LoadingSpinner";

import RestorePageIcon from '@mui/icons-material/RestorePage';
import { IconButton, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import SetUserId from "../../../../Common/FetchFunctions";


import { Assignment } from "@mui/icons-material";
import { Box } from "@mui/system";
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
const AllTestTableComp = () => {
  const navigate = useNavigate();
  // ------------------------------Test table data starts------------------------
  const [state] = useState({
    uname: sessionStorage.getItem("username"),
    role: sessionStorage.getItem("role"),
    level_name: sessionStorage.getItem("level_name")
  })

  const [loading, setLoading] = useState(true);
  const Columns = useMemo(
    () => [
      { header: 'Test Name', accessorKey: 'assignment_name' },
      {
        header: 'Test Type', id: 'test_type', accessorFn: rowData => rowData.draft === "0" ? rowData.test_type :
          <span>{rowData.test_type}
            <Tooltip header="Draft Answers" placement="top" interactive>
              <RestorePageIcon className="ms-2" style={{ cursor: "pointer" }} color="primary" />
            </Tooltip>
          </span>
      },
      { header: 'Created Date', id: 'created_at', accessorFn: rowData => ReadableDateTime(rowData.created_at) },

      {
        header: 'Start Date', accessorKey: 'test_date',
        id: 'start_date', accessorFn: rowData => rowData.test_type === "Assignment" ? "" : rowData.test_date === "0000-00-00 00:00:00" ? "" : ReadableDateTime(rowData.test_date)
      },
    ],
    [],
  );
  const [table_data, setTable_data] = useState([]);

  const loadTests = () => {

    const obj = { uname: state.uname, role: state.role }
    console.log(obj);
    axios.post(url + "mytest", obj)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          let a = response.data.data;
          console.log(a)
          let reverse = a !== null ? a.reverse() : a;
          setLoading(false)
          reverse === null ? setTable_data([]) : setTable_data(reverse);
        } else {
          setLoading(false)
          setTable_data([]);
        }
      })
      .catch((err) => console.log(err));
  }
  // ------------------------Fetching Test table data-------------------------------------
  useEffect(() => {
    loadTests();
    sessionStorage.getItem("role") === "Student" && SetUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  //-------------------------Fetching Current Date in a proper format -------------------------    

  const getCurdate = () => {
    let date_ob = new Date();
    // adjust 0 before single digit date
    let date = ("0" + date_ob.getDate()).slice(-2);
    // current month
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    // current year
    let year = date_ob.getFullYear();
    // current hours
    let hours = date_ob.getHours();
    // current minutes
    let minutes = date_ob.getMinutes();
    // current seconds
    let seconds = date_ob.getSeconds();
    // prints date & time in YYYY-MM-DD HH:MM:SS format
    const curDate = year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
    return curDate;
  }

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Tests</li>
            <li className="breadcrumb-item active" aria-current="page">Tests</li>
          </ol>
        </nav>

        <Heading name="Tests" />

        {loading ? <LoadingSpinner /> :
          <div className="container-fluid mb-5">
            {/* <div className="row mb-4">
                <div className="col-12 ">
                   <Link to="/test-history"  className="btn bg-light shadow-sm border-0  me-auto">Test History</Link>

                </div>
            </div> */}

            <div className="row mb-4">
              <nav className="col-12 mx-auto ">
                <div className="nav border-bottom" id="nav-tab" role="tabist">
                  <Link className="nav_tab nav-link active" to="/all-tests" role="tab">My Tests</Link>
                  <Link className="nav_tab nav-link " to="/test-history" role="tab" >Exam & Test History</Link>
                  <Link className="nav_tab nav-link " to="/tasks-history" role="tab" >Mind-Math & Assignment History</Link>
                </div>
              </nav>
            </div>
            <div className="row">
              <div className="col-12 mx-auto">

                <MaterialReactTable
                  columns={Columns}
                  data={table_data}
                  enableRowNumbers
                  initialState={{ density: 'compact' }}
                  enableDensityToggle={false}
                  positionActionsColumn='last'
                  enableRowActions
                  renderRowActions={(row, index) => (
                    <Box>
                      <Tooltip title='Attend'>
                        <IconButton onClick={() => {
                          let rowData = row.row.original;

                          var test_id = rowData.test_id;
                          var test_type = rowData.test_type;
                          var draft_answer = rowData.draft || 0;

                          if (test_type !== "Assignment") {
                            var testDate = rowData.test_date;
                            var curDate = getCurdate();

                            var testDateUpdated = new Date(testDate.replace(/-/g, '/'));
                            var curDateUpdated = new Date(curDate.replace(/-/g, '/'));
                          }

                          if (test_type !== "Assignment" && testDateUpdated > curDateUpdated) {
                            toast.error("Can't give a test before its starting time!")
                          } else {
                            var level_name = rowData.level_name;
                            var assignment_type = rowData.assignment_type;
                            var page = rowData.page;
                            var test_name = rowData.assignment_name;
                            var generator_test = rowData.generator_test;
                            var url_x;
                            console.log(rowData);
                            // Sending Api request to get Youtube data
                            const obj = { uname: sessionStorage.getItem("username"), test_name: test_name }

                            axios.post(url + "test_video", obj)
                              .then((response) => {
                                if (response.data.success) {
                                  let a = response.data.data;
                                  url_x = a[0].youtube_link;
                                  if (url_x.includes("https://youtu.be/"))
                                    url_x = url_x.replace('https://youtu.be/', '');
                                  else url_x = "null";

                                  if (generator_test === "0") { // Old Architecture Code
                                    if (test_type === "Exam") {
                                      navigate("/exam-sheet/" + test_name + "/" + level_name + "/" + test_type + "/" + test_id + "/" + url_x)
                                    } else if (test_type === "Assignment") {
                                      navigate("/assignment-sheet/" + test_name + "/" + level_name + "/" + assignment_type + "/" + page + "/" + test_type + "/" + test_id + "/" + url_x + "/" + draft_answer)
                                    } else {
                                      navigate("/test-sheet/" + test_name + "/" + level_name + "/" + test_type + "/" + test_id + "/" + url_x)
                                    }
                                  } else { // New Architecture code
                                    if (test_type === "Exam") {
                                      navigate("/new-exam-sheet/" + test_id + "/" + url_x);
                                    } else if (test_type === "Assignment") {

                                    } else {
                                      navigate("/new-test-sheet/" + test_id + "/" + url_x);
                                    }
                                  }


                                }
                              }).catch((err) => console.log(err));
                          }

                        }}>
                          <Assignment />
                        </IconButton>
                      </Tooltip>

                    </Box>
                  )}

                />
              </div>
            </div>
            {/* ========================================================================Test -Table-- End here====================================================================================== */}
          </div>}
      </div>
    </>

  )
}

export default AllTestTableComp;