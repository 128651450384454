import React, { useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import Heading from "../../../../Common/Heading";
import TextField from "@mui/material/TextField";
import axios from "axios";
import url from "../../../../Common/URL";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";

const ActSummaryComp = () => {

  const navigate = useNavigate();
  // const [loading , setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [searchfield, setSearchfield] = useState({
    monthYear: "",
    startDate: "",
    endDate: "",
  });

  const [backdrop, setBackdrop] = useState(false);

  const Columns = useMemo(
    () => [
      { header: 'Name', id: 'name', accessorFn: rowData => `${rowData.fname} ${rowData.lname}` },
      { header: "IBAN", accessorKey: "acc_number", },
      { header: "BIC", accessorKey: "BIC", },
      { header: "Global Student Code", accessorKey: "gsc", },
      { header: "Student Code", accessorKey: "stud_code", },
      { header: "Mandaatid", accessorKey: "Mandaatid", },
      { header: "Bedraag", accessorKey: "total", },
      { header: 'Break Up', id: 'break_up', accessorFn: rowData => `${rowData.SUM_STU_FEES} + ${rowData.SUM_STU_PRICE}` },
      { header: "Account Name", accessorKey: "acc_name" },
      { header: "Beschrijving", accessorKey: "Beschrijving", },
      { header: "Endtoendid", accessorKey: "Endtoendid", },

    ],
    [],
  );

  // ------------------------Fetching Student table's data-------------------------------------
  // useEffect(() => {
  //     LoadSummary();
  //      // eslint-disable-next-line react-hooks/exhaustive-deps
  //    }, []);

  const LoadSummary = async () => {

    setBackdrop(true);
    const obj = {
      uname: sessionStorage.getItem("username"),
      start_date: searchfield.startDate,
      end_date: searchfield.endDate,
    };
    // console.log(obj);
    await axios
      .post(url + "account_summary", obj)
      .then((response) => {
        console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
          a === null ? setData([]) : setData(a);
          setBackdrop(false);
        } else {
          setData([]);
          setBackdrop(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item">
              <Link to="/bulletin">Home</Link>
            </li>
            <li className="breadcrumb-item">Accounts</li>
            <li className="breadcrumb-item active" aria-current="page">
              Accounts Fees Summary
            </li>
          </ol>
        </nav>

        <Heading name="Accounts Fees Summary" />
        {/* {loading ? <LoadingSpinner />: */}
        <div className="container-fluid mb-5">
          <h5>Search Between Interval</h5>
          <hr className="mt-0" />
          <div className="row">
            <div className="col-md-3 ">
              <TextField
                label="From"
                type="month"
                value={searchfield.monthYear}
                className="form-control mb-4"
                onChange={(e) => {
                  let date = new Date(e.target.value.toString());
                  let monthStartDay = new Date(date.getFullYear(), date.getMonth(), 1);
                  let monthEndDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                  setSearchfield((pre) => {
                    return { ...pre, startDate: monthStartDay.toLocaleDateString('en-US'), endDate: monthEndDay.toLocaleDateString('en-US'), monthYear: e.target.value };
                  });
                }}
                InputLabelProps={{ shrink: true }}
              />
            </div>

            <div className="col-md-9 d-flex justify-content-between align-items-center">
              <Button variant="contained"
                className="border-0"
                disabled={searchfield.startDate === "" ? true : false}
                onClick={() => LoadSummary()}>
                Search
              </Button>
              <Button variant="contained"
                className="btn btn-outline-dark shadow-sm  border-0"
                onClick={() => setSearchfield({ startDate: "", endDate: "", monthYear: "" })}>
                Clear Filter
              </Button>
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-12 mx-auto">

              <MaterialReactTable
                columns={Columns}
                data={data}
                enableRowNumbers
                // enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                
                getRowId={(originalRow) => originalRow.stud_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/student/view/" + row.id),
                  sx: { cursor: 'pointer' },
                })}
              />
            </div>
          </div>
        </div>
        {/* } */}

        <Backdrop sx={{ zIndex: 3001, color: "#fff" }} open={backdrop}>
          <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>
      </div>
    </>
  );
};
export default ActSummaryComp;

