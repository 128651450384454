import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Heading from "../../../../../Common/Heading";
import { toast } from "react-toastify";
import { ReadonlyInput } from "../../../../Setup/Student/Component/Input";
import axios from "axios";
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import url from "../../../../../Common/URL";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from "@mui/material";


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useConfirm } from 'material-ui-confirm';
import { useSelector } from "react-redux";



const ViewEnquiryComp = () => {
    const confirm = useConfirm();


    const navigate = useNavigate();

    const { id } = useParams();

    const [state, setState] = useState({
        fname: "", lname: "", category: "", location: "", assigned_to: "", stud_username: "",
        email: "", phone: "", created_at: "", message: "", status: "", preferred_time: "", campaign: "", preferred_date: ""
    });

    const [showLog, setShowLog] = useState(false);

    const [log, setLog] = useState({
        message: "", add_to: "internal",
        log_internal: [], log_customer: [],
    });


    useEffect(() => {
        loadEnquiry();
        FetchLog();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ----------------------- Loading Enquiry details -------------------------------------

    const loadEnquiry = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        };
        axios.post(url + "select_enq", obj)
            .then((response) => {
                let resData = response.data.data;
                console.log(resData);
                setState({
                    fname: resData.fname, lname: resData.lname, category: resData.category, status: resData.status,
                    location: resData.location, message: resData.message, assigned_to: resData.assigned_to,
                    email: resData.email, phone: resData.phone, created_at: resData.created_at,
                    stud_username: resData.stud_username === "" ? resData.stud_username : resData.stud_username.split("#")[1].split("*").join(" "),
                    preferred_time: resData.preferred_time, campaign: resData.campaign, preferred_date: resData.preferred_date
                });
            })
            .catch((err) => console.log(err));
    };
    // ----------------------- Loading Logs In {ternal and Customer} -------------------------------------

    const FetchLog = async () => {

        var internal = [], customer = [];

        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        };

        await axios.post(url + "select_log", obj)
            .then((response) => {
                // console.log(response);
                let resData = response.data.data;
                // console.log(resData);

                if (resData !== null) {
                    resData.forEach(x => {
                        x.log_use === "internal" ? internal.push(x) : customer.push(x);
                    });
                }
                setLog({
                    ...log, message: "",
                    log_internal: internal.length > 0 ? [...internal] : false,
                    log_customer: customer.length > 0 ? [...customer] : false
                });
            }).catch((err) => console.log(err));

        setShowLog(true);
    };

    // ------------------------Deleting Batch table's data-------------------------------------

    const onDelete = () => {

        confirm({ title: "Delete Confirmation", description: 'This will delete this enquiry permanently.' })
            .then(() => {
                const delObj = {
                    id: id,
                    uname: sessionStorage.getItem("username"),
                    tokenId: 123,
                };
                axios.post(url + "delete_enquiry", delObj)
                    .then((res) => {
                        // console.log(res) ;
                        navigate("/enquiry");
                        toast.info("Enquiry deleted !");
                    })
                    .catch((err) => console.log(err));
            });

    };

    // ========================== Add Log Function ==============================================

    const AddLog = (e) => {
        e.preventDefault();

        const ADDobj = {
            log_name: log.message, log_use: log.add_to, log_mail: log.add_to === "internal" ? "" : state.email,
            log_user: sessionStorage.getItem("username"), enq_id: id,
        };

        console.log(ADDobj);

        axios.post(url + "add_log", ADDobj)
            .then((res) => {
                console.log(res);
                if (res.data.success) {
                    if (log.add_to === "internal") {
                        toast.success("Log added successfully");
                    } else {
                        toast.success("Customer log added successfully");
                    }
                    FetchLog();
                }
            }).catch((err) => console.log(err));

    };
    // ------------------------Deleting Batch table's data-------------------------------------

    const LogDelete = async (id_log) => {

        const DelLogObj = {
            id: id_log,
            uname: sessionStorage.getItem("username"),
            tokenId: 123,
        };
        await axios.post(url + "delete_log", DelLogObj)
            .then((res) => {
                if (res.data.success)
                    toast.info("Log Deleted");
            })
            .catch((err) => console.log(err));
        FetchLog();


    };

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">CRM</li>
                        <li className="breadcrumb-item"><Link to="/enquiry">Enquiries</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">View Enquiry</li>
                    </ol>
                </nav>


                <Heading name="View Enquiry" />

                <div className="container-fluid">

                    <div className="row mb-4">
                        {/*-----------------Header------------------------  */}
                        <div className="col-12 d-flex justify-content-between align-items-end ">
                            <h5>Enquiry Information</h5>
                            <div>
                                <Button variant="contained" onClick={onDelete} >Delete</Button>
                                <Button variant="contained" onClick={() => navigate("/edit-enquiry/" + id)} className="ms-3">Edit</Button>
                            </div>
                        </div>
                    </div>

                    <div className="row ">
                        {/* -----------------First Column----------------- */}
                        <div className="col-11 col-md-6 mx-auto">

                            {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Batch ID</label>
                        <div className="col-sm-7 col-md-7 ">
                        <input type="text" readOnly className="form-control bg-light border-0 fw-bold" value={state.batch_id} />
                        </div>
                    </div> */}

                            <ReadonlyInput value={state.fname} field="First Name" />

                            <ReadonlyInput value={state.lname} field="Last Name" />

                            {/* ---Select Input---- */}
                            <ReadonlyInput field="Email" className="form-control bg-light border-0 " value={state.email} />

                            {/* ---Select Input---- */}
                            <ReadonlyInput field="Phone No." className="form-control bg-light border-0 " value={state.phone} />





                            <ReadonlyInput value={state.preferred_date} field="Preferred appointment date" />

                            <ReadonlyInput value={state.preferred_time} field="Preferred appointment time" />
                            {/* ---Select Input---- */}

                            <ReadonlyInput value={state.assigned_to} field="Assigned To" />

                        </div>
                        {/* ------------Second Column----------------- */}
                        <div className="col-11 col-md-6 mx-auto">

                            <ReadonlyInput value={state.campaign} field="Heard about us from" />

                            <ReadonlyInput value={state.status} field="Status" />

                            <ReadonlyInput field="Category" className="form-control bg-light border-0 " value={state.category} />

                            <ReadonlyInput value={state.location} placeholder="--" field="Location" type="text" />

                            <div className="mb-3 row">
                                <label className=" col-sm-5 col-form-label">Message</label>
                                <div className="col-sm-7 col-md-7">
                                    <textarea readOnly className="form-control bg-light border-0 " placeholder="--" value={state.message} type="text" rows="4" />
                                </div>
                            </div>

                            <ReadonlyInput value={state.stud_username} placeholder="--" field="Student" type="text" />

                        </div>

                    </div>

                    <hr className="my-4 rounded" style={{ border: 0, borderBottom: "2px solid #636e72" }} ></hr>
                    {/* ============================================= Add Log Section Starts =========================================================== */}
                    <div className="row mt-3 mb-3 d-flex flex-row">
                        <div className="col-auto">
                            <h5>Add Log</h5>
                        </div>
                    </div>

                    <RadioGroup row aria-label="add log" name="log" value={log.add_to} onChange={(e) => setLog({ ...log, add_to: e.target.value })} >
                        <FormControlLabel value="internal" control={<Radio style={{ color: "#36A7DF" }} />} label="Internal" />
                        <FormControlLabel value="customer" control={<Radio style={{ color: "#FAB033" }} />} label="Customer" />
                    </RadioGroup>
                    <form onSubmit={AddLog}>
                        <div className="row mb-5 ">
                            <div className="col-12 d-flex " >
                                <textarea onChange={(e) => setLog({ ...log, message: e.target.value })} value={log.message} type="text"
                                    className="form-control shadow-sm "
                                    placeholder="Type a message ..."
                                    style={{
                                        borderRadius: "0 0 50px 0", padding: "1em", border: 0, width: "95%",
                                        borderLeft: log.add_to === "internal" ? "3px solid #c3e5f5" : "3px solid #fee7c2",
                                        borderRight: log.add_to === "internal" ? "10px solid #c3e5f5" : "10px solid #fee7c2",
                                    }}
                                    required
                                    rows="3"
                                />
                                <div className="d-flex justify-content-center align-items-end">
                                    <IconButton aria-label="send" type="submit"
                                        style={{ color: log.add_to === "internal" ? "#36A7DF" : "#FAB033", position: "relative", top: 10 }}>
                                        <SendIcon fontSize="large" />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="row mb-5">
                        <div className="col-11 col-md-6 mx-auto">
                            <h6 className="mb-4">Internal Thread</h6>
                            {/* <hr className="mb-3 w-75 mt-0"/> */}

                            {showLog &&
                                (log.log_internal.length ?
                                    log.log_internal.map((x, i) => {
                                        return (
                                            <div className="card  shadow-sm mb-4" key={i}
                                                style={{
                                                    backgroundColor: "#c3e5f5",
                                                    borderRadius: "0 2em 2em 2em",
                                                    border: 0,
                                                    borderLeft: "2px solid #36A7DF"
                                                }} >
                                                <div className="card-body position-relative">
                                                    <IconButton
                                                        style={{ top: 8, right: 10 }}
                                                        className="position-absolute"
                                                        onClick={() => {

                                                            confirm({ title: "Delete Confirmation", description: 'This will delete log permanently.' })
                                                                .then(() => { LogDelete(x.log_id); });

                                                        }}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                    <h6 className="card-title"><HeadsetMicIcon fontSize="small" /> {x.log_user}</h6>
                                                    <h6 className="card-text" style={{ fontWeight: "400" }}>{x.log_name}</h6>
                                                </div>
                                                <small className="text-muted ms-auto me-4 mb-2">{x.commented_date}</small>
                                            </div>
                                        );
                                    })
                                    :
                                    <h6 className="d-flex align-items-center"><ErrorOutlineIcon className="me-2" /> No Internal Log Found</h6>)
                            }

                        </div>
                        <div className="col-11 col-md-6 mx-auto">
                            <h6 className="mb-4">Customer Thread</h6>
                            {/* <hr className="mb-3 w-75 mt-0"/> */}

                            {showLog &&
                                (log.log_customer.length ?
                                    log.log_customer.map((x, i) => {
                                        return (
                                            <div className="card  shadow-sm mb-4" key={i}
                                                style={{
                                                    backgroundColor: "#fee7c2",
                                                    borderRadius: "0 2em 2em 2em",
                                                    border: 0,
                                                    borderLeft: "2px solid #FAB033"
                                                }} >
                                                <div className="card-body position-relative">
                                                    <IconButton
                                                        style={{ top: 8, right: 10 }}
                                                        className="position-absolute"
                                                        onClick={() => {
                                                            confirm({ title: "Delete Confirmation", description: 'This will delete log permanently.' })
                                                                .then(() => { LogDelete(x.log_id); });
                                                        }}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                    <h6 className="card-title"><HeadsetMicIcon fontSize="small" /> {x.log_user} <ArrowRightAltIcon /> Customer</h6>
                                                    <h6 className="card-text" style={{ fontWeight: "400" }}>{x.log_name}</h6>

                                                </div>
                                                <small className="text-muted ms-auto me-4 mb-2">{x.commented_date}</small>
                                            </div>
                                        );
                                    })
                                    :
                                    <h6 className="d-flex align-items-center"><ErrorOutlineIcon className="me-2" /> No Customer Log Found</h6>)
                            }

                        </div>
                    </div>
                    {/* ============================================= Add Log Section Ends =========================================================== */}
                </div>

            </div>
        </>
    );
};

export default ViewEnquiryComp;
