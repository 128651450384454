import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Heading from "../../../../../Common/Heading"
import { toast } from "react-toastify"
import Input from "../../../Student/Component/Input"
import axios from "axios";
import url from "../../../../../Common/URL"
import { Button } from "@mui/material";
import { useSelector } from "react-redux"


const TsProjectAddComp = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [field, setField] = useState({
        uname: sessionStorage.getItem("username"),
        project_name: "", project_desc: ""
    })

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        setField((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        /*---------------Creating object to send data through Axios------------------------- */
        const obj = {
            project_name: field.project_name, project_desc: field.project_desc,
            uname: sessionStorage.getItem("username"), created_by: sessionStorage.getItem("username"),
            fname: sessionStorage.getItem("userFname"), lname: sessionStorage.getItem("userLname")
        }

        axios.post(url + "add_project", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    toast.success("Project Created successfully!")
                    navigate("/ts-project");
                } else {
                    alert("Project Creation Failed!")
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            })
    }
    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item">Timesheet</li>
                        <li className="breadcrumb-item active"><Link to="/ts-project">Projects</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Add Project</li>
                    </ol>
                </nav>

                <Heading name="Add Project" />
                <div className="container-fluid">

                    <form onSubmit={onSubmit}>

                        <div className="row mb-4">
                            <div className="col-12 d-flex justify-content-between align-items-center ">
                                <h5>Add Project</h5>
                                <div>
                                    <Button variant="contained" onClick={() => navigate("/ts-project")} >Cancel</Button>
                                    <Button variant="contained" type="submit" disabled={loading} className="ms-3">Save</Button>
                                </div>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-11 col-md-8 mx-auto mx-md-0">

                                <Input field="Project Name"
                                    name="project_name"
                                    type="text"
                                    placeholder="Add text" className="form-control"
                                    value={field.project_name} onChange={onChange}
                                    required={true}
                                />

                                <Input field="Project Description"
                                    name="project_desc"
                                    type="text"
                                    placeholder="Add text" className="form-control"
                                    value={field.project_desc} onChange={onChange}
                                    required={true}
                                />

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default TsProjectAddComp