import { Button } from "@mui/material";
import axios from "axios";
import MaterialReactTable from 'material-react-table';
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link , useNavigate} from "react-router-dom";
import Heading from "../../../../../Common/Heading";
import { ReadableDateTime } from "../../../../../Common/StringDateTime";
import url from "../../../../../Common/URL";

const AdmissionDashboardComp = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const Columns = useMemo(
        () => [
            { header: 'Student Name', id: 'stud_name', accessorFn: rowData => `${rowData.fname} ${rowData.lname}` },
            { header: 'Parent Name', id: 'parent_name', accessorFn: rowData => `${rowData.parent_fname} ${rowData.parent_lname}` },
            { header: 'Email', accessorKey: 'parent_email' },
            { header: 'Phone No.', accessorKey: 'parent_phone' },
            { header: 'Admission Status', accessorKey: 'admission_status', size: 80 },
            { header: 'Assigned To', accessorKey: 'assigned_to', size: 80 },
            { header: 'Created Date', id: 'created_at', accessorFn: rowData => ReadableDateTime(rowData.created_at) },
        ],
        [],
    );

    useEffect(() => {
        LoadAdmissionRecords();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Existing Locations--------------------

    const LoadAdmissionRecords = async (res) => {
        await axios.get(url + "list_admission_form")
            .then((response) => {
                // console.log(response);
                let a = response.data ?? [];
                console.log(a);
                a.length ? setData(a) : setData([]);
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false);
                res && res();
            })
    }


    return (
        <>



            {/* ################################################################################################################################## */}

            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">CRM</li>
                        <li className="breadcrumb-item active" aria-current="page">Admission Dashboard</li>
                    </ol>
                </nav>

                <Heading name="Admission Dashboard" />

                <div className="container-fluid mt-3">

                    <div className="row my-4">
                        <div className="col-12 mx-auto d-flex ">

                            <Button variant="contained" onClick={() => navigate('/create-admission-form')} className="ms-auto">
                                Create Admission Form
                            </Button>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-11 col-md-12 mx-auto d-flex">
                            <div className="card col-3 me-3" >
                                <div className="card-body">
                                    <h5 className="card-title">35</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">Admissions This Month</h6>
                                </div>
                            </div>

                            <div className="card col-3" >
                                <div className="card-body">
                                    <h5 className="card-title">50</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">Admissions Pending</h6>
                                </div>
                            </div>

                        </div>
                    </div>
                    <hr /> */}

                    <div className="row my-3">
                        <div className="col-12">

                            <MaterialReactTable
                                state={{ isLoading: loading }}
                                columns={Columns}
                                data={data}
                                enableRowNumbers
                                initialState={{ density: 'compact' }}
                                enableDensityToggle={false}
                                getRowId={(originalRow) => originalRow.enq_id}
                                // renderRowActions={(row, index) => (
                                //     <Tooltip title="Delete Record">
                                //         <IconButton onClick={(event) => {
                                //             event.stopPropagation();
                                //             let rowData = row.row.original;
                                //             const obj = { uname: sessionStorage.getItem("username"), id: rowData.enq_id }
                                //             console.log(obj);
                                //             axios.post(url + "delete_adm_enquiry", obj)
                                //                 .then(res => {
                                //                     console.log(res);
                                //                     let promise = new Promise((res, rej) => LoadAdmissionRecords(res));
                                //                     promise.then(() => toast.error("Record deleted"));
                                //                 })
                                //                 .catch(res => alert("Error occurred while deleting, please try again later."))
                                //         }}>
                                //             <DeleteOutline />
                                //         </IconButton>
                                //     </Tooltip>
                                // )}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: (event) => {
                                        const win = window.open("/admission-details/" + row.original.adm_id, "_blank")
                                        win.focus();
                                    },
                                    sx: { cursor: 'pointer' },
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdmissionDashboardComp;
