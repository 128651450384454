import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Heading from "../../../../Common/Heading";
import { ReadonlyInput } from "../../Student/Component/Input";
import axios from "axios";
import url from "../../../../Common/URL";
import { useConfirm } from 'material-ui-confirm';

import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const ViewLevelComp = () => {
    const confirm = useConfirm();

    const navigate = useNavigate();
    const { id } = useParams();

    const [field, setField] = useState({
        uname: sessionStorage.getItem("username"),
        level_id: "",
        level_name: "",
        level_desc: "",
        level_status: ""
    })

    const allLevels = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        }
        axios.post(url + "fetch_single_level", obj)
            .then((response) => {
                let resData = response.data.data;
                console.log(resData);
                setField({
                    level_id: resData[0].level_id,
                    level_name: resData[0].level_name,
                    level_desc: resData[0].level_desc,
                    level_status: resData[0].level_status
                })
            })
            .catch((err) => console.log(err));

    }

    useEffect(allLevels, [])

    const onDelete = () => {
        confirm({ title: "Delete Confirmation", description: 'This level will be deleted permanently.' })
            .then(() => {
                const delObj = {
                    id: id,
                    uname: sessionStorage.getItem("username"),
                    tokenId: 123,
                };
                axios.post(url + "delete_level", delObj)
                    .then((res) => {
                        //   console.log(res)
                        navigate("/level");
                        toast.info("Level deleted")
                    })
                    .catch((err) => console.log(err));
            })
    }


    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item"><Link to="/level">Levels</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">View Level</li>
                    </ol>
                </nav>

                <Heading name={`View Level - ${field.level_name}`} />

                <div className="container-fluid">

                    <div className="row mb-4">
                        <div className="col-12 d-flex justify-content-between align-items-end ">
                            <h5>Level Information</h5>
                            <div>
                                <Button variant="contained" onClick={onDelete} >Delete</Button>
                                <Button variant="contained" onClick={() => navigate("/edit-level/" + id)} className="ms-3">Edit</Button>
                            </div>
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-11 col-md-8 mx-auto mx-md-0">

                            {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Level ID</label>
                        <div className="col-md-7 col-sm-7"> */}
                            {/* {field.level_id} */}
                            {/* <input type="text" readOnly className="form-control bg-light border-0 fw-bold" value={field.level_id} />
                        </div>
                    </div> */}

                            <ReadonlyInput field="Level Name" type="text" placeholder="Add text"  value={field.level_name} />

                            <div className="mb-3 row">
                                <label className=" col-sm-5 col-form-label">Description</label>
                                <div className="col-sm-7 col-md-7">
                                    <textarea readOnly type="text" className="form-control border-0 bg-light" rows="2"
                                        placeholder="--" value={field.level_desc}
                                    />
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Status</label>
                                <div className="col-sm-7 col-md-7 align-items-center d-flex ">

                                    <div className ="form-check form-check-inline">
                                        <input className ="form-check-input" readOnly type="radio" id="inlineCheckbox1" value="Active" checked={field.level_status === 'Active'} />
                                        <label className ="form-check-label" htmlFor="inlineCheckbox1">Active</label>
                                    </div>
                                    <div className ="form-check form-check-inline">
                                        <input className ="form-check-input" readOnly type="radio" id="inlineCheckbox2" value="Inactive" checked={field.level_status === 'Inactive'} />
                                        <label className ="form-check-label" htmlFor="inlineCheckbox2">Inactive</label>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ViewLevelComp