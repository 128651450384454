import { Button } from "@mui/material";
import axios from "axios";
import MaterialReactTable from 'material-react-table';
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../Common/Heading";
import url from "../../../../Common/URL";
import Input from "../../Student/Component/Input";

import { useSelector } from "react-redux";

const EditSlotComp = () => {
    const navigate = useNavigate();

    let message = "";
    const [loc, setLoc] = useState([]);
    const [slotDetails, setSlotDetails] = useState({});
    const [slotStudents, setSlotStudents] = useState([]);
    const [teachers, setTeacher] = useState([]);

    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();


    useEffect(() => {
        loadSlotDetails();
        loadLocation();
        loadTeachers();
        fetchSlotStudents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    const Columns = useMemo(
        () => [
            { header: 'First Name', accessorKey: 'student_fname' },
            { header: 'Last Name', accessorKey: 'student_lname' },
            { header: 'Age', accessorKey: 'age' },
        ],
        [],
    );


    // -----------This function will Fetch all the Existing Teachers--------------------

    const loadTeachers = () => {
        const obj = { uname: sessionStorage.getItem("username") };
        axios.post(url + "teachers", obj)
            .then((response) => {
                // console.log(response)
                if (response.data.success) {
                    let a = response.data.data;
                    console.log(a);
                   !a.length ? setTeacher([]) : setTeacher(a);
                } else {
                    setTeacher([]);
                }
            })
            .catch((err) => console.log(err));
    };

    // -----------This function will Fetch all the Existing Locations--------------------
    const loadLocation = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        axios.post(url + "fetch_locations", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setLoc(a);
            })
            .catch((err) => console.log(err));
    };

    // ---------------------------------------------------------

    const loadSlotDetails = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            slot_id: id,
        };

        axios.post(url + "slot_details", obj)
            .then((response) => {
                let a = response.data;
                // console.log(a);
                setSlotDetails(a);
            })
            .catch((err) => console.log(err));
    };

    // ---------------------------------------------------------

    const fetchSlotStudents = async () => {
        setLoading(true);
        const obj = {
            slot_id: id
        };

        await axios.post(url + "slot_students", obj)
            .then((response) => {
                // console.log(response);
                let a = response.data;
                console.log(a);
                setSlotStudents(a);
            })
            .catch((err) => console.log(err));
        setLoading(false);
    };

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setSlotDetails((preValue) => ({ ...preValue, [name]: value }));
    };



    const onSubmit = (e) => {

        e.preventDefault();
        setDisable(true);
        // ------------Storing form data in "obj" object fetched from various input fields-------------
        const obj = { ...slotDetails, assigned_to: slotDetails.teacher_id };
        // ------------Sending form data stored in "obj" object to the add_student file uusing AXIOS-------------
        console.log(obj);

        axios.post(url + "edit_slot", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    loadSlotDetails();
                    toast.success("Updated successfully");
                } else {
                    message = res.data.message;
                    alert(message);
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item"><Link to="/slots">Slots</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Edit Slot</li>
                    </ol>
                </nav>


                <Heading name={`Edit Slot`} />

                <div className="container-fluid">

                    <form onSubmit={onSubmit}>

                        <div className="row mb-4">
                            {/*-----------------Header------------------------  */}
                            <div className="col-12 d-flex justify-content-end align-items-end ">
                                <div>
                                    <Button variant="contained" onClick={() => navigate("/slots")} >Back</Button>
                                    <Button variant="contained" type="submit" disabled={disable} className="ms-3">Save</Button>
                                </div>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-11 col-md-6 mx-auto ">
                                <h5 className="mb-3">Slot Information</h5>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Slot Location</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="location_id" value={slotDetails.location_id} onChange={onChange} className="form-select">
                                            <option value="">Choose</option>
                                            {loc.map((data, i) => <option key={i} value={data.location_id}>{data.name}</option>)}
                                        </select>
                                    </div>
                                </div>

                                <Input
                                    name="slot_date"
                                    onChange={onChange}
                                    value={slotDetails.slot_date}
                                    field="Slot Date"
                                    type="date"
                                    className="form-control"
                                    required={true}
                                />

                                <Input
                                    name="slot_time"
                                    onChange={onChange}
                                    value={slotDetails.slot_time}
                                    field="Slot Time"
                                    type="time"
                                    className="form-control"
                                    required={true}
                                />


                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Assign To</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="teacher_id" value={slotDetails.teacher_id} onChange={onChange} className="form-select">
                                            <option value="">Choose</option>
                                            {teachers.map((data, i) => (<option key={i} value={data.teacher_id}>{`${data.fname} ${data.lname}`}</option>))}
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label ">Slot Type</label>
                                    <div className="col-sm-7 col-md-7 align-items-center d-flex ">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="slot_type"
                                                id="slotType1"
                                                value='0'
                                                checked={Number(slotDetails.slot_type) === 0}
                                                onChange={onChange}
                                            />
                                            <label className="form-check-label" htmlFor="slotType1">Trial Class</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="slot_type"
                                                id="slotType2"
                                                value='1'
                                                checked={Number(slotDetails.slot_type) === 1}
                                                onChange={onChange}
                                            />
                                            <label className="form-check-label" htmlFor="slotType2">Parent Orientation</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label ">Is slot full?</label>
                                    <div className="col-sm-7 col-md-7 align-items-center d-flex ">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="slot_status"
                                                id="slotFull1"
                                                value='0'
                                                checked={Number(slotDetails.slot_status) === 0}
                                                onChange={onChange}
                                            />
                                            <label className="form-check-label" htmlFor="slotFull1">No</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="slot_status"
                                                id="slotFull2"
                                                value='1'
                                                checked={Number(slotDetails.slot_status) === 1}
                                                onChange={onChange}
                                            />
                                            <label className="form-check-label" htmlFor="slotFull2">Yes</label>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="col-12 col-md-6 mx-auto">
                                <h5 className="mb-3">{`Total Assigned Students: ${slotStudents.length} `}</h5>

                                <MaterialReactTable
                                    state={{ isLoading: loading }}
                                    columns={Columns}
                                    data={slotStudents}
                                    enableRowNumbers
                                    // enableRowSelection
                                    initialState={{ density: 'compact' }}
                                    enableDensityToggle={false}
                                    muiTableBodyCellProps={{ align: 'center' }}
                                    muiTableHeadCellProps={{ align: 'center' }}

                                // getRowId={(originalRow) => originalRow.slot_id}
                                // muiTableBodyRowProps={({ row }) => ({
                                //     onClick: (event) => navigate("/edit-slot/" + row.id),
                                //     sx: { cursor: 'pointer' },
                                // })}

                                // renderTopToolbarCustomActions={({ table }) => (
                                //     <IconButton
                                //         disabled={table.getSelectedRowModel().rows.length ? false : true}
                                //         onClick={() => {
                                //             confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                                //                 .then(() => {
                                //                     let selectedRows = table.getSelectedRowModel().rows;
                                //                     onDelete(selectedRows, table);
                                //                 })
                                //         }}
                                //     ><DeleteOutline />
                                //     </IconButton>
                                // )}
                                />

                            </div>

                        </div>
                    </form>
                </div>

            </div>
        </>
    );
};

export default EditSlotComp;