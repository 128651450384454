import React, { useState, useEffect } from "react";
// import Input from "../../Student/Component/Input";
import Heading from "../../../../Common/Heading";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ReadonlyInput } from "../../Student/Component/Input";
import { toast } from "react-toastify";
import axios from "axios";
import url from "../../../../Common/URL";

import Backdrop from '@mui/material/Backdrop';

import { useConfirm } from 'material-ui-confirm';
import { Button, CircularProgress } from "@mui/material";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from "react-redux";

const View_user_comp = () => {
  const confirm = useConfirm();

  const navigate = useNavigate();
  const { id } = useParams();
  const [backdrop, setBackdrop] = useState(false);

  const [checkPassword, setCheckPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [load, setLoad] = useState(false);
  const [changePass_Open, setChangePass_Open] = useState(false);
  const [field, setField] = useState({
    uname: "",
    email: "",
    firstname: "",
    lastname: "",
    status: "",
    role: "",
    password: "",
    confirm_password: "",
  });

  const ChangePassHandleOpen = () => {
    setChangePass_Open(true);
  };

  // ===============================================================================================
  const onPasswordChange = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.value;
    setField({ ...field, [name]: value });

    if (name === "password") {
      const reg = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
      if (value === "") {
        setCheckPassword("");
      } else {
        let result = reg.test(value);
        if (result) setCheckPassword("is-valid");
        else setCheckPassword("is-invalid");
      }
    }
  };

  // =====================================================================================================

  const ChangePassword = (e) => {
    e.preventDefault();
    setLoad(true);
    if (field.password === field.confirm_password) {

      const obj = { uname: field.uname, id: id, pass: field.password };

      axios.post(url + "edit_password", obj)
        .then((res) => {
          // console.log(res)
          if (res.data.success) {
            setChangePass_Open(false);
            toast.success("Password changed successfully");
            setLoad(false);
            setField((pre) => {
              return {
                ...pre, password: "", confirm_password: ""
              };
            });
          } else alert("An error occurred during password change, Please try again later.");
        }).catch((err) => console.log(err));
    } else {
      toast.error("Password didn't match, please enter password again");
      setLoad(false);
    }
  };
  // ====================================================================================================  

  // -----------This function will Fetch the User's Data--------------------
  const loadUser = () => {
    const obj = {
      id: id,
      uname: sessionStorage.getItem("username"),
    };

    axios.post(url + "fetch_single_user", obj)
      .then((response) => {
        let resData = response.data.data;
        console.log(resData);

        setField({
          uname: resData[0].username,
          email: resData[0].email,
          firstname: resData[0].first_name,
          lastname: resData[0].last_name,
          status: resData[0].active,
          role: resData[0].role
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(loadUser, []);
  // ---------------------------------deleting a User from the Users Table---------------------------
  function onDelete() {
    confirm({ title: "Delete Confirmation", description: 'This will delete the user permanently.' })
      .then(() => {
        const delObj = {
          id: id,
          uname: sessionStorage.getItem("username"),
          tokenId: 123,
        };
        axios.post(url + "delete_user", delObj)
          .then((res) => {
            // console.log(res)
            toast.info("User deleted ");
            navigate("/user");
          })
          .catch((err) => console.log(err));
      });

  }


  return (
    <>
      {/* ########################################### Dialog Popup for changing password ###################################### */}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={changePass_Open}
        onClose={() => {
          setChangePass_Open(false);
          setField({ ...field, password: "", confirm_password: "" });
          setCheckPassword("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change Password Dialog</DialogTitle>
        <DialogContent>
          <form onSubmit={ChangePassword} id="changePassword" >
            <div className="mb-3">
              <label>Enter Password</label>
              <input required className={`form-control ${checkPassword}`} type={showPassword ? "text" : "password"} name="password" value={field.password} onChange={onPasswordChange} />
              <div className="invalid-feedback">The password must contain 8 characters, including an uppercase (A,B,C etc.), lowercase (a,b,c etc.) & a special character(@,#,$,% etc.).</div>
            </div>
            <div className="mb-3">
              <label>Confirm Password</label>
              <input required className="form-control" type={showPassword ? "text" : "password"} name="confirm_password" value={field.confirm_password} onChange={onPasswordChange} />
            </div>
            <div className="mb-3 form-check mt-3">
              <input type="checkbox" onClick={(e) => setShowPassword(e.target.checked)} className="form-check-input" id="showPassword" />
              <label className="form-check-label" htmlFor="showPassword">Show Password</label>
            </div>
          </form>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={() => {
            setChangePass_Open(false);
            setField({ ...field, password: "", confirm_password: "" });
            setCheckPassword("");
          }}>Close</Button>
          <Button type="submit" disabled={checkPassword === "is-invalid"} form="changePassword" color="primary" > {load ? <CircularProgress size={20} /> : "Change Password"}</Button>
        </DialogActions>
      </Dialog>


      {/* ################################################################################################################################## */}


      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        {/*----------------------- Bread crumb ------------------------- */}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item"><Link to="/user">Users</Link></li>
            <li className="breadcrumb-item active">View User</li>
          </ol>
        </nav>
        <Heading name={`View User - ${field.firstname}`} />

        <div className="container-fluid mt-3">
          <div className="row mb-4 ">
            <div className="col-12 d-flex">
              <h5>View Information</h5>
              {field.role !== "" && (sessionStorage.getItem("role") === "Systemadmin" || field.role !== "Systemadmin") &&
                <div className="ms-auto">
                  <Button variant="contained" onClick={ChangePassHandleOpen} >Change Password</Button>
                  <Button variant="contained" onClick={onDelete} className="ms-3">Delete</Button>
                  <Button variant="contained" onClick={() => navigate("/edit-user/" + id)} className="mx-3">Edit</Button>
                </div>}
            </div>
          </div>

          <div className="row">
            <div className="col-11 col-md-8">
              <div>
                {/* <ReadonlyInput field="Sr No"  className="form-control border-0 fw-bold bg-light" value={id} /> */}


                <ReadonlyInput field="Username" className="form-control border-0 fw-bold bg-light " value={field.uname} />

                <ReadonlyInput field="Email" type="email" placeholder="--" className="form-control border-0 bg-light" value={field.email} />

                <ReadonlyInput field="First Name" className="form-control border-0 bg-light" value={field.firstname} />

                <ReadonlyInput field="Last Name" className="form-control border-0 bg-light" value={field.lastname} />
                {/* // <div className="mb-3 row">
                            //     <label className="col-sm-5 col-form-label">Student ID</label>
                            //     <div className="col-sm-7 col-md-6 ">
                            //     <input type="text" readonly className="form-control-plaintext" value="" />
                            //     </div>
                            // </div> */}
                {/* ---Select Input---- */}
                <ReadonlyInput field="Role" className="form-control bg-light border-0 " value={field.role} />

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label">Status</label>
                  <div className="col-sm-7 col-md-6 ">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input"
                        type="radio"
                        id="radio1"
                        value="1"
                        checked={field.status === "1"}
                        readOnly />
                      <label className="form-check-label" htmlFor="radio1">Active</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input"
                        type="radio"
                        id="radio2"
                        value="0"
                        checked={field.status === "0"}
                        readOnly />
                      <label className="form-check-label" htmlFor="radio2">Inactive</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Backdrop sx={{ zIndex: 3001, color: '#fff' }} open={backdrop} ><CircularProgress style={{ color: "#fff" }} /></Backdrop>
      </div>
    </>
  );
};

export default View_user_comp

