import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import Img from "./logo.jpg";
// import Img2 from "./small-logo.jpeg";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import IconButton from '@mui/material/IconButton';

import { useDispatch, useSelector } from "react-redux";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ForumIcon from '@mui/icons-material/Forum';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
// import PersonIcon from '@mui/icons-material/Person';
import MovieIcon from '@mui/icons-material/Movie';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import HelpIcon from '@mui/icons-material/Help';
import SettingsIcon from '@mui/icons-material/Settings';

import NewReleasesIcon from '@mui/icons-material/NewReleases';

import { Calculate, CalculateOutlined, SupportAgent } from "@mui/icons-material";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { CLOSE } from "../store/sidebarSlice";
import "./Sidebar.css";
import { Divider } from "@mui/material";

const Sidebar = () => {

    const OpenClose = useSelector(state => state.sidebar.value);
    const dispatch = useDispatch();

    const Clicked = () => {
        dispatch(CLOSE(false));
    };

    const [user] = useState({
        role: sessionStorage.getItem("role"),
        uname: sessionStorage.getItem("username"),
    });

    const close_btn = {
        color: "white",
        padding: "5px",
        outline: "none",
    };

    return (
        <>
            <div id="sidebar" className={OpenClose ? "active" : ""} >

                <div className="sidebar-header">
                    <img src={Img} alt="logo" className="img-fluid border big-logo" />
                    {/* <img src={Img2} className="img-fluid small-logo " /> */}
                </div>
                {/*----------------- React Tool-Tip Component --------------------*/}
                <ReactTooltip place="right" effect="solid" delayShow={200} />

                <div className="sidebar_content">
                    <ul className="list-unstyled nav_container">
                        <div className="close-btn text-end ">
                            <IconButton style={close_btn} className="d-md-none me-3" onClick={Clicked}><CloseIcon /></IconButton>
                        </div>

                        {/* =================== Bulletin ================== */}
                        {user.role !== "Account" &&
                            <div className="link align-items-center ">
                                <li>
                                    <NavLink className="Close_bar_icon" to="/bulletin"><i className="icon-Sidebar-active" data-type="dark" data-tip="Bulletin Board"><FormatListBulletedIcon style={{ fontSize: "1.7rem" }} /></i></NavLink>
                                    <NavLink className="main-tabs" to="/bulletin"><i className="me-2" ><FormatListBulletedIcon /></i> Bulletin Board </NavLink>
                                </li>
                            </div>}
                        {/* ================== Messages =================== */}
                        {
                            <div className="link align-items-center">
                                <li>
                                    <NavLink className="Close_bar_icon" to="/message"><i className="icon-Sidebar-active" data-type="dark" data-tip="Messages" ><ForumIcon style={{ fontSize: "1.7rem" }} /></i></NavLink>
                                    <NavLink className="main-tabs" to="/message"><i className="me-2" ><ForumIcon /></i> Messages </NavLink>
                                </li>
                            </div>
                        }

                        {/* ================= Tests ==================== */}
                        {(user.role === "Student" || user.role === "Admin" || user.role === "Superadmin" || user.role === "Systemadmin" || user.role === "Teacher") && (
                            <div className="link align-items-center">
                                <li>
                                    <Popup trigger={<i className=" icon-Sidebar-active" ><CalculateOutlined style={{ fontSize: "1.7rem" }} /></i>} position="right"
                                        on="hover" mouseLeaveDelay="200" closeOnDocumentClick offsetX={15}
                                        contentStyle={{ backgroundColor: "#29567D", color: "#000" }}
                                        arrow arrowStyle={{ color: "#29567D" }}>

                                        <div className="d-flex flex-column menu">
                                            <p className="sidebar_popup"><DataUsageIcon style={{ fontSize: ".7em" }} />&nbsp;Tests</p>
                                            <NavLink className="d-none" to="#" /> {/* This link is just used to remove the highlight from 1st link */}
                                            {user.role === "Student" ? <NavLink className="text-decoration-none menu-item" to="/all-tests"><ArrowRightIcon fontSize="small" />Tests</NavLink> :
                                                <NavLink className="text-decoration-none menu-item" to="/test-history"><ArrowRightIcon fontSize="small" />Tests</NavLink>
                                            }
                                            <NavLink className="text-decoration-none menu-item" to="/abacus"><ArrowRightIcon fontSize="small" />Virtual Abacus</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/mind-math"><ArrowRightIcon fontSize="small" />Mind Math</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/generator"><ArrowRightIcon fontSize="small" />Generator</NavLink>
                                        </div>
                                    </Popup>

                                    <a className="main-tabs " id="dropdown-tab" data-bs-toggle="collapse" href="#test"><i className="me-2" ><Calculate /></i> Tests <i className="fa fa-sort-desc float-end me-4"></i></a>
                                    <ul className="collapse list-unstyled drop_menu" id="test">
                                        {user.role === "Student" ? <li><NavLink to="/all-tests"><i className="fa fa-angle-right fa-fw " ></i> Tests</NavLink></li> :
                                            <li><NavLink to="/test-history"><i className="fa fa-angle-right fa-fw " ></i> Tests</NavLink></li>
                                        }
                                        <li><NavLink to="/abacus"><i className="fa fa-angle-right fa-fw " ></i> Virtual Abacus</NavLink></li>
                                        <li><NavLink to="/mind-math"><i className="fa fa-angle-right fa-fw " ></i> Mind Math</NavLink></li>
                                        <li><NavLink to="/generator"><i className="fa fa-angle-right fa-fw " ></i> Generator</NavLink></li>
                                    </ul>
                                </li>
                            </div>)}


                        {/* ================== HELP VIDEOS =================== */}
                        {user.role !== "Student" &&
                            <>
                                <hr className="border text-white ms-1" />
                                <div className="link align-items-center">
                                    <li>
                                        <NavLink className="Close_bar_icon" to="/help-videos"><i className="icon-Sidebar-active" data-type="dark" data-tip="Help Videos" ><MovieIcon style={{ fontSize: "1.7rem" }} /></i></NavLink>
                                        <NavLink className="main-tabs" to="/help-videos"><i className="me-2" ><MovieIcon /></i> Help Videos </NavLink>
                                    </li>
                                </div>
                            </>

                        }
                        {/* =================== Students ================== */}
                        {(user.role !== "Student" && user.role !== "Account") &&
                            <div className="link align-items-center">
                                <li>

                                    <Popup trigger={<i className="icon-Sidebar-active" ><LocalLibraryIcon style={{ fontSize: "1.7rem" }} /></i>} position="right top"
                                        on="hover" mouseLeaveDelay="200" closeOnDocumentClick offsetX={15}
                                        contentStyle={{ backgroundColor: "#29567D", color: "#000" }}
                                        arrow arrowStyle={{ color: "#29567D" }}>

                                        <div className="d-flex flex-column menu">
                                            <p className="sidebar_popup"><DataUsageIcon style={{ fontSize: ".7em" }} />&nbsp;Students</p>
                                            <NavLink className="d-none" to="#" /> {/* This link is just used to remove the highlight from 1st link */}
                                            <NavLink className=" text-decoration-none menu-item" to="/location-student-table"><ArrowRightIcon fontSize="small" />Students List</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/assign-test"><ArrowRightIcon fontSize="small" />Assign Test</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/students-test-history" ><ArrowRightIcon fontSize="small" />Test History</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/upload-report" ><ArrowRightIcon fontSize="small" />Upload Report</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/download-certificate" ><ArrowRightIcon fontSize="small" />Download Certificate</NavLink>
                                        </div>
                                    </Popup>


                                    <a className="main-tabs" id="dropdown-tab" data-bs-toggle="collapse" href="#student"><i className="me-2" ><LocalLibraryIcon /></i> Students <i className="fa fa-sort-desc float-end me-4"></i></a>
                                    <ul className="collapse list-unstyled drop_menu" id="student">
                                        <li><NavLink to="/location-student-table" ><i className="fa fa-angle-right fa-fw " ></i>Students List</NavLink></li>
                                        <li><NavLink to="/assign-test"><i className="fa fa-angle-right fa-fw " ></i>Assign Test</NavLink></li>
                                        <li><NavLink to="/students-test-history"><i className="fa fa-angle-right fa-fw " ></i>Test History</NavLink></li>
                                        <li><NavLink to="/upload-report"><i className="fa fa-angle-right fa-fw " ></i>Upload Report</NavLink></li>
                                        <li><NavLink to="/download-certificate"><i className="fa fa-angle-right fa-fw " ></i>Download Certificate</NavLink></li>
                                    </ul>
                                </li>
                            </div>
                        }

                        {/* ================= Timesheet ==================== */}
                        {(user.role !== "Student" && user.role !== "Account") && (
                            <div className="link align-items-center">
                                <li>
                                    <NavLink className="Close_bar_icon" to="/timesheet"><i className="icon-Sidebar-active" data-type="dark" data-tip="Timesheet"><AvTimerIcon style={{ fontSize: "1.7rem" }} /></i></NavLink>
                                    <NavLink className="main-tabs" to="/timesheet"><i className="me-2" ><AvTimerIcon /></i> Timesheet </NavLink>
                                </li>
                            </div>
                        )}


                        {/* ================== CRM =================== */}

                        {user.role === "Teacher" && <div className="link align-items-center">
                            <li>
                                <NavLink className="Close_bar_icon" to="/my-slots"><i className="icon-Sidebar-active" data-type="dark" data-tip="My Slots"><SupportAgent style={{ fontSize: "1.7rem" }} /></i></NavLink>
                                <NavLink className="main-tabs" to="/my-slots"><i className="me-2" ><SupportAgent /></i> My Slots</NavLink>
                            </li>
                        </div>}

                        {(user.role === "Location Manager" || user.role === "Superadmin" || user.role === "Systemadmin" || user.role === "Admin" || user.role === "Account") && <>
                            <hr className="border text-white ms-1" />

                            <div className="link align-items-center" >
                                <li>
                                    <Popup trigger={<i className=" icon-Sidebar-active" ><SupportAgent style={{ fontSize: "1.7rem" }} /></i>} position="right"
                                        on="hover" mouseLeaveDelay="200" closeOnDocumentClick offsetX={15}
                                        contentStyle={{ backgroundColor: "#29567D", color: "#000", width: "auto" }}
                                        arrow arrowStyle={{ color: "#29567D" }}>

                                        <div className="d-flex flex-column menu">
                                            <p className="sidebar_popup"><DataUsageIcon style={{ fontSize: ".7em" }} />&nbsp;CRM</p>
                                            <NavLink className="d-none" to="#" /> {/* This link is just used to remove the highlight from 1st link */}
                                            <NavLink className="text-decoration-none menu-item" to="/enquiry"><ArrowRightIcon fontSize="small" />General Enquiries</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/enq-form"><ArrowRightIcon fontSize="small" />General Enquiry Form</NavLink>

                                            <NavLink className="text-decoration-none menu-item" to="/admission-enq-form"><ArrowRightIcon fontSize="small" />Admission Enquiry Form</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/my-slots"><ArrowRightIcon fontSize="small" />My Slots</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/enq-dashboard"><ArrowRightIcon fontSize="small" />Enquiry Dashboard</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/admission-dashboard"><ArrowRightIcon fontSize="small" />Admission Dashboard</NavLink>
                                        </div>
                                    </Popup>

                                    <a className="main-tabs " id="dropdown-tab" data-bs-toggle="collapse" href="#crm"><i className="me-2" ><SupportAgent /></i> CRM <i className="fa fa-sort-desc float-end me-4"></i> </a>
                                    <ul className="collapse list-unstyled drop_menu" id="crm">
                                        <li><NavLink to="/enquiry"><i className="fa fa-angle-right fa-fw " ></i> General Enquiries</NavLink></li>
                                        <li><NavLink to="/enq-form"><i className="fa fa-angle-right fa-fw" ></i> General Enquiry Form</NavLink></li>
                                        <li><NavLink to="/admission-enq-form"><i className="fa fa-angle-right fa-fw " ></i> Admission Enquiry Form</NavLink></li>
                                        <li><NavLink to="/my-slots"><i className="fa fa-angle-right fa-fw " ></i> My Slots</NavLink></li>
                                        <li><NavLink to="/enq-dashboard"><i className="fa fa-angle-right fa-fw " ></i> Enquiry Dashboard</NavLink></li>
                                        <li><NavLink to="/admission-dashboard"><i className="fa fa-angle-right fa-fw " ></i> Admission Dashboard</NavLink></li>
                                    </ul>
                                </li>
                            </div>
                        </>}

                        {/* ================== Inventory =================== */}

                        {(user.role === "Superadmin" || user.role === "Systemadmin" || user.role === "Admin" || user.role === "Location Manager") && (
                            <div className="link align-items-center"  >
                                <li >
                                    <Popup trigger={<i className=" icon-Sidebar-active" ><AddShoppingCartIcon style={{ fontSize: "1.7rem" }} /></i>} position="right"
                                        on="hover" mouseLeaveDelay="200" closeOnDocumentClick offsetX={15}
                                        contentStyle={{ backgroundColor: "#29567D", color: "#000" }}
                                        arrow arrowStyle={{ color: "#29567D" }}>

                                        <div className="d-flex flex-column menu">
                                            <p className="sidebar_popup"><DataUsageIcon style={{ fontSize: ".7em" }} />&nbsp;Inventory</p>
                                            <NavLink className="d-none" to="#" /> {/* This link is just used to remove the highlight from 1st link */}
                                            <NavLink className="text-decoration-none menu-item" to="/inventory"><ArrowRightIcon fontSize="small" />Inventory Items</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/inv-transaction"><ArrowRightIcon fontSize="small" />Transactions</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/inv-balance"><ArrowRightIcon fontSize="small" />Balance Report</NavLink>
                                        </div>
                                    </Popup>

                                    <a className="main-tabs " id="dropdown-tab" data-bs-toggle="collapse" href="#inv"><i className="me-2" ><AddShoppingCartIcon /></i> Inventory <i className="fa fa-sort-desc float-end me-4"></i> </a>
                                    <ul className="collapse list-unstyled drop_menu" id="inv">
                                        <li><NavLink to="/inventory"><i className="fa fa-angle-right fa-fw " ></i> Inventory Items</NavLink></li>
                                        <li><NavLink to="/inv-transaction"><i className="fa fa-angle-right fa-fw " ></i> Transactions </NavLink></li>
                                        <li><NavLink to="/inv-balance"><i className="fa fa-angle-right fa-fw " ></i> Balance Report</NavLink></li>
                                    </ul>
                                </li>
                            </div>
                        )}


                        {/* ================= Accounts ==================== */}

                        {(user.role === "Superadmin" || user.role === "Systemadmin" || user.role === "Admin" || user.role === "Account") && (
                            <div className="link align-items-center"  >
                                <li >
                                    <Popup trigger={<i className=" icon-Sidebar-active" ><AccountBalanceWalletIcon style={{ fontSize: "1.7rem" }} /></i>} position="right"
                                        on="hover" mouseLeaveDelay="200" closeOnDocumentClick offsetX={15}
                                        contentStyle={{ backgroundColor: "#29567D", color: "#000", width: "auto" }}
                                        arrow arrowStyle={{ color: "#29567D" }}>

                                        <div className="d-flex flex-column menu">
                                            <p className="sidebar_popup"><DataUsageIcon style={{ fontSize: ".7em" }} />&nbsp;Accounts</p>
                                            <NavLink className="d-none" to="#" /> {/* This link is just used to remove the highlight from 1st link */}
                                            <NavLink className="text-decoration-none menu-item" to="/act-trans"><ArrowRightIcon fontSize="small" />Accounts Transactions</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/act-fee"><ArrowRightIcon fontSize="small" />Accounts Fees</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/act-summary"><ArrowRightIcon fontSize="small" />Accounts Fees Summary</NavLink>
                                        </div>
                                    </Popup>

                                    <a className="main-tabs " id="dropdown-tab" data-bs-toggle="collapse" href="#act"><i className="me-2" ><AccountBalanceWalletIcon /></i> Accounts <i className="fa fa-sort-desc float-end me-4"></i> </a>
                                    <ul className="collapse list-unstyled drop_menu" id="act">
                                        <li><NavLink to="/act-trans"><i className="fa fa-angle-right fa-fw " ></i> Accounts Transactions</NavLink></li>
                                        <li><NavLink to="/act-fee"><i className="fa fa-angle-right fa-fw " ></i> Accounts Fees</NavLink></li>
                                        <li><NavLink to="/act-summary"><i className="fa fa-angle-right fa-fw " ></i> Accounts Fees Summary</NavLink></li>
                                    </ul>
                                </li>
                            </div>
                        )}

                        {/* ================== Setup =================== */}

                        {(user.role === "Superadmin" || user.role === "Systemadmin" || user.role === "Admin") && (
                            <div className="link align-items-center"  >
                                <li >
                                    <Popup trigger={<i className=" icon-Sidebar-active" ><SettingsIcon style={{ fontSize: "1.7rem" }} /></i>} position="right center"
                                        on="hover" mouseLeaveDelay="200" closeOnDocumentClick offsetX={15}
                                        contentStyle={{ backgroundColor: "#29567D", color: "#000" }} nested
                                        arrow arrowStyle={{ color: "#29567D" }}>

                                        <div className="d-flex flex-column menu">
                                            <p className="sidebar_popup"><DataUsageIcon style={{ fontSize: ".7em" }} />&nbsp;Setup</p>
                                            <NavLink className="d-none" to="#" /> {/* This link is just used to remove the highlight from 1st link */}

                                            <NavLink className="text-decoration-none menu-item" to="/user"><ArrowRightIcon fontSize="small" />Users</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/location"><ArrowRightIcon fontSize="small" />Locations</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/batch"><ArrowRightIcon fontSize="small" />Batches</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/level"><ArrowRightIcon fontSize="small" />Levels</NavLink>

                                            <NavLink className="text-decoration-none menu-item" to="/student" ><ArrowRightIcon fontSize="small" />Students</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/teacher"><ArrowRightIcon fontSize="small" />Teachers</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/manager"><ArrowRightIcon fontSize="small" />Managers</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/question"><ArrowRightIcon fontSize="small" />Questions</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/slots"><ArrowRightIcon fontSize="small" />Slots</NavLink>

                                            {/* <NavLink className="text-decoration-none menu-item" to="/preference"><ArrowRightIcon fontSize="small"/>Preferences</NavLink> */}

                                            <NavLink className="text-decoration-none menu-item" to="/test"><ArrowRightIcon fontSize="small" />Tests</NavLink>

                                            {/* <NavLink className="text-decoration-none menu-item" to="/role"><ArrowRightIcon fontSize="small"/>Roles</NavLink> */}
                                            {/* <NavLink className="text-decoration-none menu-item" to="/landing-page"><ArrowRightIcon fontSize="small"/>Landing Page</NavLink> */}

                                            <Popup trigger={<span className="text-decoration-none menu-item" style={{ cursor: "pointer" }} ><ArrowRightIcon fontSize="small" />Timesheet</span>} position="right bottom"
                                                on="hover" mouseLeaveDelay="200" closeOnDocumentClick offsetX={15}
                                                contentStyle={{ backgroundColor: "#29567D", color: "#000" }} nested
                                                arrow arrowStyle={{ color: "#29567D" }}>

                                                <div className="d-flex flex-column menu">
                                                    <p className="sidebar_popup"><DataUsageIcon style={{ fontSize: ".7em" }} />&nbsp;Timesheet</p>
                                                    <NavLink className="d-none" to="#" /> {/* This link is just used to remove the highlight from 1st link */}
                                                    <NavLink className="text-decoration-none menu-item" to="/ts-project" ><ArrowRightIcon fontSize="small" />Projects</NavLink>
                                                    <NavLink className="text-decoration-none menu-item" to="/ts-task"><ArrowRightIcon fontSize="small" />Tasks</NavLink>
                                                </div>
                                            </Popup>

                                        </div>
                                    </Popup>

                                    <a className="main-tabs " id="dropdown-tab" data-bs-toggle="collapse" href="#setup"><i className="me-2" ><SettingsIcon /></i> Setup <i className="fa fa-sort-desc float-end me-4"></i> </a>
                                    <ul className="collapse list-unstyled drop_menu" id="setup">
                                        <li><NavLink to="/user"><i className="fa fa-angle-right fa-fw " ></i> Users</NavLink></li>
                                        <li><NavLink to="/location"><i className="fa fa-angle-right fa-fw " ></i> Locations</NavLink></li>
                                        <li><NavLink to="/batch"><i className="fa fa-angle-right fa-fw " ></i> Batches</NavLink></li>
                                        <li><NavLink to="/level"><i className="fa fa-angle-right fa-fw " ></i> Levels</NavLink></li>
                                        <li><NavLink to="/student" ><i className="fa fa-angle-right fa-fw " ></i> Students</NavLink></li>
                                        <li><NavLink to="/teacher"><i className="fa fa-angle-right fa-fw " ></i> Teachers</NavLink></li>
                                        <li><NavLink to="/manager"><i className="fa fa-angle-right fa-fw " ></i> Managers</NavLink></li>

                                        {/* <li><NavLink to="/preference"><i className="fa fa-angle-right fa-fw " ></i> Preferences</NavLink></li> */}
                                        <li><NavLink to="/question"><i className="fa fa-angle-right fa-fw " ></i> Questions</NavLink></li>
                                        <li><NavLink to="/slots"><i className="fa fa-angle-right fa-fw " ></i> Slots</NavLink></li>
                                        <li><NavLink to="/test"><i className="fa fa-angle-right fa-fw " ></i> Tests</NavLink></li>

                                        {/* <li><NavLink to="/role"><i className="fa fa-angle-right fa-fw " ></i> Roles</NavLink></li> */}
                                        {/* <li><NavLink to="/landing-page"><i className="fa fa-angle-right fa-fw " ></i> Landing Page</NavLink></li> */}
                                        <li> <a id="dropdown-tab" data-bs-toggle="collapse" href="#timesheet"><i className="fa fa-angle-right fa-fw " ></i> Timesheet <i className="fa fa-sort-desc float-end me-4"></i> </a></li>
                                        <ul className="collapse list-unstyled drop_menu" id="timesheet">
                                            <li><NavLink to="/ts-project" ><i className="fa fa-angle-right fa-fw ms-2" ></i> Projects</NavLink></li>
                                            <li><NavLink to="/ts-task"><i className="fa fa-angle-right fa-fw ms-2" ></i> Tasks</NavLink></li>
                                        </ul>
                                    </ul>
                                </li>
                            </div>
                        )}

                        <hr className="border text-white ms-1" />


                        {/* ================ Help ===================== */}
                        <div className="link align-items-center">
                            <li>
                                <NavLink className="Close_bar_icon" to="/help"><i className="icon-Sidebar-active" data-type="dark" data-tip="Help"><HelpIcon style={{ fontSize: "1.7rem" }} /></i></NavLink>
                                <NavLink className="main-tabs" to="/help"><i className="me-2" ><HelpIcon /></i> Help</NavLink>
                            </li>
                        </div>

                        {/* ================ Release Notes ===================== */}
                        <div className="link align-items-center">
                            <li>
                                <NavLink className="Close_bar_icon" to="/whatsnew"><i className="icon-Sidebar-active" data-type="dark" data-tip="Release Notes"><NewReleasesIcon style={{ fontSize: "1.7rem" }} /></i></NavLink>
                                <NavLink className="main-tabs" to="/whatsnew"><i className="me-2" ><NewReleasesIcon /></i> Release Notes</NavLink>
                            </li>
                        </div>


                        {/* ================ System Health ===================== */}
                        {(user.role === "Systemadmin") && (
                            <div className="link align-items-center"  >
                                <li >
                                    <Popup trigger={<i className=" icon-Sidebar-active" ><LocalHospitalIcon style={{ fontSize: "1.7rem" }} /></i>} position="right bottom"
                                        on="hover" mouseLeaveDelay="200" closeOnDocumentClick offsetX={15}
                                        contentStyle={{ backgroundColor: "#29567D", color: "#000", width: "auto" }}
                                        arrow arrowStyle={{ color: "#29567D" }}>

                                        <div className="d-flex flex-column menu">
                                            <p className="sidebar_popup"><DataUsageIcon style={{ fontSize: ".7em" }} />&nbsp;System Health</p>
                                            <NavLink className="d-none" to="#" /> {/* This link is just used to remove the highlight from 1st link */}
                                            <NavLink className="text-decoration-none menu-item" to="/activity-log"><ArrowRightIcon fontSize="small" />Activity Log</NavLink>
                                            <NavLink className="text-decoration-none menu-item" to="/activity-table"><ArrowRightIcon fontSize="small" />Activity Tables</NavLink>
                                            {/* <NavLink className="text-decoration-none menu-item" to="/video-player"><ArrowRightIcon fontSize="small"/>Video Player</NavLink> */}
                                        </div>
                                    </Popup>

                                    <a className="main-tabs " id="dropdown-tab" data-bs-toggle="collapse" href="#activity-log"><i className="me-2" ><LocalHospitalIcon /></i> System Health <i className="fa fa-sort-desc float-end me-4"></i> </a>
                                    <ul className="collapse list-unstyled drop_menu" id="activity-log">
                                        <li><NavLink to="/activity-log"><i className="fa fa-angle-right fa-fw " ></i> Activity Log</NavLink></li>
                                        <li><NavLink to="/activity-table"><i className="fa fa-angle-right fa-fw " ></i> Activity Tables</NavLink></li>
                                        {/* <li><NavLink to="/video-player"><i className="fa fa-angle-right fa-fw " ></i> Video Player</NavLink></li> */}
                                    </ul>
                                </li>
                            </div>
                        )}

                    </ul>
                </div>
            </div>

        </>
    );
};
export default Sidebar;
