import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import Heading from "../../../../Common/Heading";
import { CurrentDate } from "../../../../Common/StringDateTime";
import url from "../../../../Common/URL";
import Input from "../../Student/Component/Input";

import { useSelector } from "react-redux";

const EditTeacherComp = () => {
  let message = "";
  const navigate = useNavigate();

  const [loc, setLoc] = useState([]);
  const [batch, setBatch] = useState([]);
  const [lev, setLev] = useState([]);
  // const [usr , setUsr] =useState([]);

  const [disable, setDisable] = useState(true);
  const [form_data, setForm_data] = useState([]); //Used to compare if any update is done in form data.
  const [multiple_select_data, setMultiple_select_data] = useState({
    batch: [],
    level: [],
    location: [],
  }); //Used to compare if any update is done in Multi Select dropdowns data.

  const { id } = useParams();

  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
    teacher_id: "",
    // ------------normal input fields--------------
    fname: "",
    mname: "",
    lname: "",
    city: "",
    zip: "",
    mobile_no: "",
    home_no: "",
    email: "",
    parent_fname: "",
    parent_lname: "",
    parent_mobile_no: "",
    parent_email: "",
    parent_mobile_no_2: "",
    parent_email_2: "",
    join_date: "",
    leave_date: "",
    registration_number: "",
    approved_date: "",
    kvk_number: "",
    contract_period: "",
    contract_renewal_date: "",
    probation_period: "",
    // ------------Textarea input fields--------------
    addressline1: "",
    addressline2: "",

    // ------------Checkbox input fields--------------
    active: false,
    photo_consent: false,
    disclaimer_signed: false,

    // ------------Select inputs fields--------------
    state: "",
    country: "",
    location_name: [],
    batch_name: [],
    level_name: [],
    user_name: "",
    picture: null,
  });

  useEffect(() => {
    TeacherDetail();
    loadLocation();
    loadBatch();
    loadLevel();
    // loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -----------This function will Fetch all the Existing Locations--------------------
  const loadLocation = () => {
    const obj = {
      uname: sessionStorage.getItem("username"),
    };

    axios
      .post(url + "fetch_locations", obj)
      .then((response) => {
        let a = response.data.data;
        //   console.log(a);
        setLoc(a);
      })
      .catch((err) => console.log(err));
  };

  // -----------This function will Fetch all the Existing Batch--------------------
  const loadBatch = () => {
    const obj = {
      uname: sessionStorage.getItem("username"),
    };

    axios
      .post(url + "fetch_batches", obj)
      .then((response) => {
        let a = response.data.data;
        //   console.log(a);
        setBatch(a);
      })
      .catch((err) => console.log(err));
  };

  // -----------This function will Fetch all the Existing Levels--------------------
  const loadLevel = () => {
    const obj = {
      uname: sessionStorage.getItem("username"),
    };

    axios
      .post(url + "fetch_levels", obj)
      .then((response) => {
        let a = response.data.data;
        //   console.log(a);
        setLev(a);
      })
      .catch((err) => console.log(err));
  };

  // -------------This function will Filter Batches according to the location Selected in Select input field ---------------------
  const BatchFilter = () => {
    let Location_arr = state.location_name.map((x) => x.value.toLowerCase());

    let Filtered_batches = batch.filter((a) => Location_arr.indexOf(a.location_name.toLowerCase()) >= 0);

    return Filtered_batches.map((x) => ({ label: x.batch_name, value: x.batch_name }));
  };

  // --------------This will help in creating capitalize strings 
  const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1);
  };
  // ------------------------------

  const TeacherDetail = async () => {
    const obj = {
      uname: state.uname,
      id: id,
    };
    await axios
      .post(url + "select_teacher", obj)
      .then((response) => {
        let resData = response.data.data;
        // console.log(resData);

        setForm_data(resData); //THis will help in checking any update done in form, so we can enable or disable the save form button

        var active_checkbox = resData.active === "1" || resData.active === "true" ? true : false;

        setState((pre) => ({
          ...pre,
          fname: resData.fname,
          mname: resData.mname,
          lname: resData.lname,
          city: resData.city,
          zip: resData.zip,
          mobile_no: resData.mobile_no,
          home_no: resData.home_no,
          email: resData.email,
          parent_fname: resData.parent_fname,
          parent_lname: resData.parent_lname,
          parent_mobile_no: resData.parent_mobile_no,
          parent_mobile_no_2: resData.parent_mobile_no_2,
          parent_email: resData.parent_email,
          join_date: resData.join_date,
          leave_date: resData.leave_date,
          parent_email_2: resData.parent_email_2,
          registration_number: resData.registration_number,
          teacher_id: resData.teacher_id,
          approved_date: resData.approved_date,
          probation_period: resData.probation_period,
          contract_renewal_date: resData.contract_renewal_date,
          contract_period: resData.contract_period,
          kvk_number: resData.kvk_number,
          // ------------Textarea input fields--------------
          addressline1: resData.addressline1,
          addressline2: resData.addressline2,

          // ------------Checkbox input fields--------------
          active: active_checkbox,
          photo_consent: resData.photo_consent,
          disclaimer_signed: resData.disclaimer_signed,

          // ------------Select inputs fields--------------
          state: resData.state,
          country: resData.country,
          user_name: resData.user_name,
        }));
        //----------------------------------------------------------------------------------------------

        axios
          .post(url + "select_teacher_params", { uname: resData.user_name })
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              var batch_name_arr = res.data.batch_data.map((a) => a.batch_name);
              var location_name_arr = res.data.location_data.map((a) => a.location_name);
              var level_name_arr = res.data.level_data.map((a) => a.level_name);

              // console.log(batch_name_arr);console.log(location_name_arr);console.log(level_name_arr);

              setMultiple_select_data({
                batch: batch_name_arr.map((x) => ({ label: x, value: x })),
                location: location_name_arr.map((x) => ({ label: capitalizeFirstLetter(x), value: capitalizeFirstLetter(x) })),
                level: level_name_arr.map((x) => ({ label: x, value: x })),
              });

              setState((pre) => {
                return {
                  ...pre,
                  location_name: location_name_arr.map((x) => ({ label: capitalizeFirstLetter(x), value: capitalizeFirstLetter(x) })),
                  batch_name: batch_name_arr.map((x) => ({ label: x, value: x })),
                  level_name: level_name_arr.map((x) => ({ label: x, value: x })),
                };
              });
            } else {
              alert("An error occurred Location, Level & Batch can't be fetched.");
            }
          })
          .catch((res) => console.log(res));

        //----------------------------------------------------------------------------------------------
      })
      .catch((err) => console.log(err));
    setDisable(true);
  };
  //---------------One onChange function is used for setting the state of various types of input fields ------------
  const onChange = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.type === "checkbox" ? input.checked : input.value;

    if (form_data[0][name] !== value) {
      setDisable(false);
    } else {
      setDisable(true);
    }

    setState((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  // ------------------- -------------------functions for Submitting Country and state- ------------------- -------------------
  const selectCountry = (val) => {
    setState((preValue) => {
      return {
        ...preValue,
        country: val,
      };
    });
  };
  const selectRegion = (val) => {
    setState((preValue) => {
      return {
        ...preValue,
        state: val,
      };
    });
  };
  // ------------------- -----------------handleInputChange function for Submitting the profile-pic------------------
  // const handleInputChange = (e) =>{
  //     setState({ picture: e.target.files[0] })
  // }
  //-------------------------- onSubmit function for Submitting the  Forms--------------------------
  const onSubmit = (e) => {
    e.preventDefault();
    setDisable(true);

    if (state.location_name.length === 0) {
      toast.error("Please fill the Location field.");
    } else if (state.batch_name.length === 0) {
      toast.error("Please fill the Batch field.");
    } else if (state.level_name.length === 0) {
      toast.error("Please fill the Level field.");
    } else if (state.country.length === 0) {
      toast.error("Please fill the Country field.");
    } else {
      /*---------------Using form data to send profile pic-------------------------- */
      // let profile_pic = new FormData()
      // profile_pic.append('profile_pic', state.picture)

      var newdate = CurrentDate();

      /*---------------Creating object to send data through Axios------------------------- */
      const obj = {
        uname: sessionStorage.getItem("username"),
        id: id,
        updated_date: newdate,
        updated_by: sessionStorage.getItem("username"),

        fname: state.fname,
        mname: state.mname,
        lname: state.lname,
        city: state.city,
        zip: state.zip,
        mobile_no: state.mobile_no,
        home_no: state.home_no,
        email: state.email,
        parent_fname: state.parent_fname,
        parent_lname: state.parent_lname,
        parent_mobile_no: state.parent_mobile_no,
        parent_email: state.parent_email,
        contract_period: state.contract_period,
        parent_mobile_no_2: state.parent_mobile_no_2,
        parent_email_2: state.parent_email_2,
        approved_date: state.approved_date,
        leave_date: state.leave_date,
        addressline1: state.addressline1,
        addressline2: state.addressline2,
        join_date: state.join_date,
        kvk_number: state.kvk_number,
        contract_renewal_date: state.contract_renewal_date,
        probation_period: state.probation_period,

        active: state.active,
        photo_consent: state.photo_consent,
        disclaimer_signed: state.disclaimer_signed,

        state: state.state,
        country: state.country,
        user_name: state.user_name,
      };

      const formData = new FormData();
      Object.keys(obj).forEach((key) => formData.append(key, obj[key]));

      state.location_name.length === 0
        ? formData.append("location_name", "")
        : state.location_name.forEach((x) => formData.append("location_name[]", x.value));
      state.batch_name.length === 0
        ? formData.append("batch_name", "")
        : state.batch_name.forEach((x) => formData.append("batch_name[]", x.value));
      state.level_name.length === 0
        ? formData.append("level_name", "")
        : state.level_name.forEach((x) => formData.append("level_name[]", x.value));

      // console.log([...formData])

      axios
        .post(url + "edit_teacher", formData)
        .then((res) => {
          // console.log(res)
          if (res.data.success) {
            TeacherDetail();
            toast.success("Updated successfully!");
          } else {
            message = res.data.message;
            alert(message);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        {/*----------------------- Bread crumb ------------------------- */}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item">
              <Link to="/bulletin">Home</Link>
            </li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item">
              <Link to="/teacher">Teachers List</Link>
            </li>
            <li className="breadcrumb-item active">Edit Teacher</li>
          </ol>
        </nav>
        <Heading name={`Edit Teacher - ${state.fname}`} />
        {/* ------------------------------------------------- Two Columns for Inputs --------------------------------------- */}
        <form onSubmit={onSubmit}>
          <div className="container-fluid mt-3">
            <div className="row my-4">
              <div className="col-12 d-flex justify-content-end mx-auto">
                <Button variant="contained" onClick={() => navigate("/view-teacher/" + state.teacher_id)} >
                  Back
                </Button>
                <Button variant="contained" type="submit" disabled={disable} className="ms-3">
                  Save
                </Button>
              </div>
            </div>

            <div className="row">
              {/*-----------------------Column-1 for Inputs------------------------- */}
              <div className="col-11 col-md-6 mx-auto">
                <h5 className="mb-4">Personal Information</h5>

                {/* <Input field="Profile Picture" type="file" className="form-control-file" name="picture" onChange={handleInputChange} value={state.picture}/> */}

                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Teacher ID</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <input type="text" readOnly className="form-control bg-light border-0 fw-bold" value={state.teacher_id} />
                                </div>
                            </div> */}

                <Input
                  field="First Name"
                  type="text"
                  placeholder="Auto Generated"
                  className="form-control"
                  name="fname"
                  onChange={onChange}
                  value={state.fname}
                  required={true}
                />

                <Input
                  field="Middle Name"
                  type="text"
                  placeholder="Auto Generated"
                  className="form-control"
                  name="mname"
                  onChange={onChange}
                  value={state.mname}
                  required={false}
                />

                <Input
                  field="Last Name"
                  type="text"
                  placeholder="Auto Generated"
                  className="form-control"
                  name="lname"
                  onChange={onChange}
                  value={state.lname}
                  required={true}
                />

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Address Line 1</label>
                  <div className="col-sm-7 col-md-7 ">
                    <textarea
                      required
                      name="addressline1"
                      onChange={onChange}
                      className="form-control"
                      placeholder="Add text"
                      value={state.addressline1}></textarea>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label ">Address Line 2</label>
                  <div className="col-sm-7 col-md-7 ">
                    <textarea
                      name="addressline2"
                      onChange={onChange}
                      className="form-control"
                      placeholder="Add text"
                      value={state.addressline2}></textarea>
                  </div>
                </div>

                <Input
                  field="City"
                  type="text"
                  placeholder="Auto Generated"
                  className="form-control"
                  name="city"
                  onChange={onChange}
                  value={state.city}
                  required={true}
                />

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Country</label>
                  <div className="col-sm-7 col-md-7 ">
                    {/* <select name="state" value={state.state} onChange={onChange} className="form-select">
                                    <option value="province-1">Province 1</option><option value="province-2">Province 2</option><option value="province-3">Province 3</option>
                                </select> */}

                    <CountryDropdown
                      className="form-select"
                      name="country"
                      value={state.country}
                      onChange={(val) => selectCountry(val)}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label ">Province</label>
                  <div className="col-sm-7 col-md-7 ">
                    {/* <select name="country" value={state.country} onChange={onChange} className="form-select">
                                    <option value="1">Country 1</option><option value="country-2">Country 2</option><option value="country-3">Country 3</option>
                                </select> */}

                    <RegionDropdown
                      className="form-select"
                      name="state"
                      country={state.country}
                      value={state.state}
                      onChange={(val) => selectRegion(val)}
                    />
                  </div>
                </div>

                <Input
                  field="Zip"
                  type="text"
                  placeholder="Auto Generated"
                  className="form-control"
                  name="zip"
                  onChange={onChange}
                  value={state.zip}
                  required={true}
                />

                <Input
                  field="Mobile Number"
                  type="text"
                  placeholder="Auto Generated"
                  className="form-control"
                  name="mobile_no"
                  onChange={onChange}
                  value={state.mobile_no}
                  required={true}
                />

                <Input
                  field="Home Phone"
                  type="text"
                  placeholder="Auto Generated"
                  className="form-control"
                  name="home_no"
                  onChange={onChange}
                  value={state.home_no}
                  required={false}
                />

                <Input
                  field="Email"
                  type="email"
                  placeholder="Auto Generated"
                  className="form-control"
                  name="email"
                  onChange={onChange}
                  value={state.email}
                  required={true}
                />

                <Input
                  field="KVK Number"
                  type="text"
                  placeholder="Auto Generated"
                  className="form-control"
                  name="kvk_number"
                  onChange={onChange}
                  value={state.kvk_number}
                  required={true}
                />

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label">Contract Document</label>
                  <div className="col-sm-7 col-md-7 d-flex align-items-center">
                    <a href="/404" download>
                      View Contract Document &nbsp;<i className="fa fa-download"></i>
                    </a>
                  </div>
                </div>
              </div>

              {/*-----------------------Column-2 for Inputs------------------------- */}
              <div className="col-11 col-md-6 mx-auto">
                <h5 className="mb-4">Batch Information</h5>

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Location Name</label>
                  <div className="col-sm-7 col-md-7 ">
                    <Select
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      isClearable={true}
                      isMulti
                      closeMenuOnSelect={false}
                      options={loc.map((x) => ({ label: x.name, value: x.name }))}
                      value={state.location_name}
                      onChange={(e) => {
                        // console.log(multiple_select_data.location);
                        // console.log(e);
                        if (JSON.stringify(multiple_select_data.location) !== JSON.stringify(e))
                          setDisable(false);
                        else
                          setDisable(true);
                        setState({ ...state, location_name: e });
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Batch Name</label>
                  <div className="col-sm-7 col-md-7 ">
                    <Select
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      isClearable={true}
                      isMulti
                      closeMenuOnSelect={false}
                      options={BatchFilter()}
                      value={state.batch_name}
                      onChange={(e) => {
                        if (JSON.stringify(multiple_select_data.batch) !== JSON.stringify(e)) {
                          setDisable(false);
                        } else {
                          setDisable(true);
                        }
                        setState({ ...state, batch_name: e });
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Level Name</label>
                  <div className="col-sm-7 col-md-7 ">
                    <Select
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      isClearable={true}
                      isMulti
                      closeMenuOnSelect={false}
                      options={lev.map((x) => ({ label: x.level_name, value: x.level_name }))}
                      value={state.level_name}
                      onChange={(e) => {
                        if (JSON.stringify(multiple_select_data.level) !== JSON.stringify(e))
                          setDisable(false);
                        else
                          setDisable(true);

                        setState({ ...state, level_name: e });
                      }}
                    />
                  </div>
                </div>

                <h5 className="mt-5 mb-4">Account Information</h5>

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label">Active Account</label>
                  <div className="col-sm-7 col-md-7 ps-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="active"
                        checked={state.active}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Photography Consent Provided?</label>
                                <div className="col-sm-7 col-md-7 ps-4">
                                <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="photo_consent" checked={state.photo_consent} onChange={onChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Disclaimer Signed?</label>
                                <div className="col-sm-7 col-md-7 ps-4">
                                <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="disclaimer_signed" checked={state.disclaimer_signed} onChange={onChange} />
                                    </div>
                                </div>
                            </div> */}

                <Input
                  name="join_date"
                  onChange={onChange}
                  field="Join Date"
                  type="date"
                  placeholder="Add text"
                  className="form-control"
                  value={state.join_date}
                  required={true}
                />

                <Input
                  name="probation_period"
                  onChange={onChange}
                  value={state.probation_period}
                  field="Probation Period"
                  type="number"
                  placeholder="In Months"
                  className="form-control"
                />

                <Input
                  name="contract_renewal_date"
                  onChange={onChange}
                  value={state.contract_renewal_date}
                  field="Contract Renewal Date"
                  type="date"
                  placeholder="Add text"
                  className="form-control"
                />

                <Input
                  name="contract_period"
                  onChange={onChange}
                  value={state.contract_period}
                  field="Contract Period"

                  placeholder="In Months"
                  className="form-control"
                />

                <Input
                  name="leave_date"
                  onChange={onChange}
                  value={state.leave_date}
                  field="Leave Date"
                  type="date"
                  placeholder="Add text"
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default EditTeacherComp;
