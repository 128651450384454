import React, { useState, useEffect } from "react"
import Input from "../../../Student/Component/Input"
import { toast } from "react-toastify"
import { Link, useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom";
import Heading from "../../../../../Common/Heading"
import axios from "axios"
import url from "../../../../../Common/URL"

import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const TsProjectEditComp = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    let message = "";
    const { id } = useParams();

    const [field, setField] = useState({
        uname: sessionStorage.getItem("username"),

        project_desc: "", project_name: ""
    })

    const LoadProject = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        }
        axios.post(url + "fetch_single_project", obj)
            .then((response) => {
                let resData = response.data.data;
                // console.log(resData);
                setField({
                    project_name: resData[0].project_name,
                    project_desc: resData[0].project_desc,
                })
            })
            .catch((err) => console.log(err));

    }

    useEffect(() => {
        LoadProject();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setField((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        /*---------------Creating object to send data through Axios------------------------- */
        const obj = {
            uname: sessionStorage.getItem("username"), id: id, project_desc: field.project_desc,
            project_name: field.project_name, updated_by: sessionStorage.getItem("username"),
        }
        axios.post(url + "edit_project", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    LoadProject();
                    toast.success("Updated successfully!")
                } else {
                    message = res.data.message;
                    alert(message)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            })

    }
    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item">Timesheet</li>
                        <li className="breadcrumb-item active"><Link to={`/ts-project`}>Projects</Link></li>
                        <li className="breadcrumb-item active"><Link to={`/view-ts-project/${id}`}>View Project</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Edit Project</li>
                    </ol>
                </nav>

                <Heading name={`Edit Project - ${field.project_name}`} />

                <div className="container-fluid">

                    <form onSubmit={onSubmit}>

                        <div className="row mb-4">
                            <div className="col-12 d-flex justify-content-between align-items-end ">
                                <h5>Project Information</h5>
                                <div>
                                    <Button variant="contained" onClick={() => navigate("/view-ts-project/" + id)} >Back</Button>
                                    <Button variant="contained" type="submit" disabled={loading} className="ms-3">Save</Button>
                                </div>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-11 col-md-8 mx-auto mx-md-0">

                                <Input field="Project Name"
                                    name="project_name"
                                    type="text" placeholder="Add text"
                                    className="form-control"
                                    value={field.project_name}
                                    onChange={onChange}
                                    required={true}
                                />
                                <Input field="Project Description"
                                    name="project_desc"
                                    type="text" placeholder="Add text"
                                    className="form-control"
                                    value={field.project_desc}
                                    onChange={onChange}
                                    required={true}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default TsProjectEditComp