import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../Common/Heading";
import LoadingSpinner from "../../../../Common/LoadingSpinner";
import { AnswerFieldStyle, RearrangeArray, styleColor, styleFontWeight, TableHeaderStyle } from "../../../../Common/Question Generator/QuestionGeneratorFunctions";
import url from "../../../../Common/URL";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const ModernTestExamPreviewComp = () => {
    // st_ans: will give 0 or 1 value 
    // user_ans_id: which is used to fetch a test score & actual student_answers
    let { test_id, st_ans, user_ans_id } = useParams();

    const [loading, setLoading] = useState(true);

    // This data will be sent to DB 
    const [paperState, setPaperState] = useState({
        total_ab_questions: 0, total_mm_questions: 0,
        test_name: '', test_type: ''
    });


    var mmTableHeader = [...Array(paperState.total_mm_questions).keys()]; // to create header number for a Mind-Math table
    var abTableHeader = [...Array(paperState.total_ab_questions).keys()]; // to create header number for a Abacus table

    let mmInputFieldArray = [...Array(paperState.total_mm_questions).keys()]; // to create input fields for the table
    let abInputFieldArray = [...Array(paperState.total_ab_questions).keys()]; // to create input fields for the table

    var mmActualAnswerFieldArray = [...Array(paperState.total_mm_questions).keys()]; // to create the actual answer field for a Mind-Math table
    var abActualAnswerFieldArray = [...Array(paperState.total_ab_questions).keys()]; // to create the actual answer field for a Abacus table

    // This will store generated questions separately for MM & AB
    const [mmQuestions, setMmQuestions] = useState([]);
    const [abQuestions, setAbQuestions] = useState([]);

    // This is to store input field answers, which will later be used to check right and wrong answers
    const [mmInputAnswers, setMmInputAnswers] = useState([]);
    const [abInputAnswers, setAbInputAnswers] = useState([]);

    // This is to store actual answers
    const [mmAnswersArray, setMmAnswersArray] = useState([]);
    const [abAnswersArray, setAbAnswersArray] = useState([]);



    useEffect(() => {
        FetchTestDetails(); // This will fetch actual questions & answers 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const FetchTestDetails = () => {
        // New Logic
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: test_id,
        };
        axios.post(url + "fetch_single_test", obj)
            .then((response) => {
                let a = response.data.data;
                console.log(a);
                let actual_answer = a[0].actual_answer;
                let actual_question = a[0].actual_question;

                setPaperState({
                    ...paperState,
                    total_ab_questions: Number(a[0].total_ab_questions), total_mm_questions: Number(a[0].total_mm_questions),
                    actual_answer: actual_answer, actual_question: actual_question,
                    test_name: a[0].test_name, test_type: a[0].test_type
                });


                // -------- Getting Mind-Math Questions --------
                let mmQuestionsArray = actual_question.slice(0, a[0].total_mm_questions);
                let mmAnswerArray = actual_answer.slice(0, a[0].total_mm_questions);

                var mmSplicedArray = [];
                while (mmQuestionsArray.length > 0) {
                    mmSplicedArray.push(mmQuestionsArray.splice(0, 10));
                }

                for (let i = 0; i < mmSplicedArray.length; i++) {
                    mmQuestionsArray.push(RearrangeArray(mmSplicedArray[i]));
                }

                setMmQuestions(mmQuestionsArray);
                setMmAnswersArray(mmAnswerArray);


                //-------- Getting Abacus Questions --------
                let abQuestionsArray = actual_question.slice(-a[0].total_ab_questions); // -ve sign means we are taking 'N elements' from end of array
                let abAnswerArray = actual_answer.slice(-a[0].total_ab_questions); // -ve sign means we are taking 'N elements' from end of array

                var abSplicedArray = [];
                while (abQuestionsArray.length > 0) {
                    abSplicedArray.push(abQuestionsArray.splice(0, 10));
                }

                for (let i = 0; i < abSplicedArray.length; i++) {
                    abQuestionsArray.push(RearrangeArray(abSplicedArray[i]));
                }

                setAbQuestions(abQuestionsArray);
                setAbAnswersArray(abAnswerArray);

                // This will fetch student's answers for a particular stud_ans_id
                if (st_ans === "1") {
                    const obj = { uname: sessionStorage.getItem("username"), id: user_ans_id }
                    axios.post(url + "view_score", obj)
                        .then((response) => {
                            if (response.data.success) {
                                let a = response.data.data;
                                let student_answers = JSON.parse(a[0].student_answers);
                                console.log(student_answers);

                                setMmInputAnswers(student_answers.slice(0, a[0].total_mm_questions));
                                setAbInputAnswers(student_answers.slice(-a[0].total_ab_questions));
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            toast.error("Error while fetching student's answers");
                        });
                }

            }).then(() => {
                setLoading(false);
                toast.success('Preview generated successfully');
            }).catch((err) => console.log(err));

    }

    const checkAnswer = (x, type) => {
        // inputAnswers = Entered answers; 
        // answersArray = Actual Answers;
        // type = 0 means Mind-Math; type = 1 means Abacus;
        if (type === 0) {
            return (mmInputAnswers[x] === mmAnswersArray[x]) ? true : false;
        } else {
            return (abInputAnswers[x] === abAnswersArray[x]) ? true : false;
        }
    }


    return (
        <>


            {/* ========================================================= */}

            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>

                <Heading name={paperState.test_name} />
                {loading ? <LoadingSpinner /> :
                    <div className="container-fluid mb-5">

                        {st_ans === "1" && (
                            <div className="row ">
                                <div className="col-12 mx-auto ">
                                    <h6>
                                        <FiberManualRecordIcon style={{ color: "green" }} /> = Right Answer
                                    </h6>
                                    <h6>
                                        <FiberManualRecordIcon style={{ color: "red" }} /> = Wrong Answer
                                    </h6>
                                    <hr />
                                </div>
                            </div>
                        )}

                        {/* ================= Mind-Math Table ================= */}
                        <div className="row">

                            <div className="col-12 mx-auto ">
                                <h5><LabelImportantIcon /> Mind-Math</h5>
                                <hr />
                            </div>

                            <div className="col-12 mx-auto ">
                                {mmQuestions.map((x, i) => {
                                    return <TableContainer key={i} component={Paper} className="mb-5">
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {mmTableHeader.splice(0, 10).map((x, i) => <TableCell key={i} style={TableHeaderStyle}>{x + 1}</TableCell>)}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {x.map((x, i) => {
                                                    return <TableRow key={i}>
                                                        {x.map((x, i) => <TableCell key={i} style={{ fontWeight: styleFontWeight(x), color: styleColor(x), textAlign: 'center' }}>{x}</TableCell>)}
                                                    </TableRow>
                                                })}

                                                {st_ans === "1" && <TableRow>
                                                    {mmInputFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={x} className="text-center">
                                                            <input readOnly
                                                                style={{
                                                                    width: "3em", textAlign: "center",
                                                                    border: (checkAnswer(x, 0) ? '2px solid green' : '2px solid red')
                                                                }} value={mmInputAnswers[x]} />

                                                        </TableCell>
                                                    ))}
                                                </TableRow>}

                                                <TableRow>
                                                    {mmActualAnswerFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={i} className="text-center" style={AnswerFieldStyle}>
                                                            {mmAnswersArray[x]}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                })}

                            </div>
                        </div>

                        {/* ================= Abacus Table ================= */}
                        <div className="row ">
                            <div className="col-12 mx-auto ">
                                <h5><LabelImportantIcon /> Abacus</h5>
                                <hr />
                            </div>
                            <div className="col-12 mx-auto ">
                                {abQuestions.map((x, i) => {
                                    return <TableContainer key={i} component={Paper} className="mb-5">
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {abTableHeader.splice(0, 10).map((x, i) => <TableCell key={i} style={TableHeaderStyle}>{x + 1}</TableCell>)}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {x.map((x, i) => {
                                                    return <TableRow key={i}>
                                                        {x.map((x, i) => <TableCell key={i} style={{ fontWeight: styleFontWeight(x), color: styleColor(x), textAlign: 'center' }}>{x}</TableCell>)}
                                                    </TableRow>
                                                })}


                                                {st_ans === "1" && <TableRow>
                                                    {abInputFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={x} className="text-center">
                                                            <input readOnly
                                                                style={{
                                                                    width: "3em", textAlign: "center",
                                                                    border: (checkAnswer(x, 1) ? '2px solid green' : '2px solid red')
                                                                }} value={abInputAnswers[x]} />

                                                        </TableCell>
                                                    ))}
                                                </TableRow>}


                                                <TableRow>
                                                    {abActualAnswerFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={i} className="text-center" style={AnswerFieldStyle}>
                                                            {abAnswersArray[x]}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                })}

                            </div>
                        </div>

                        {/* ================================== */}

                    </div>
                }
            </div>
        </>
    )
}
export default ModernTestExamPreviewComp;