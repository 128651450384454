import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Heading from "../../../../Common/Heading";
import axios from "axios";
import url from "../../../../Common/URL";
import { toast } from "react-toastify"
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const ContactParentComp = () => {
    const { id } = useParams();

    const [state, setState] = useState({
        uname: sessionStorage.getItem("username"), mail1: "", mail2: "", message: "", fname: ""
    })

    const onChange = (e) => {
        const input = e.target;
        const value = input.value;
        const name = input.name;
        setState(pre => {
            return {
                ...pre, [name]: value
            }
        })
    }

    useEffect(() => {
        LoadParentEMail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const LoadParentEMail = () => {
        const obj = {
            uname: state.uname,
            id: id
        }
        axios.post(url + "fetch_single_student", obj)
            .then((response) => {
                let resData = response.data.data;
                // console.log(resData);
                setState({
                    fname: resData[0].fname,
                    mail1: resData[0].parent_email,
                    mail2: resData[0].parent_email_2,
                })
            })
            .catch((err) => console.log(err));
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (state.mail2 !== "" || state.mail1 !== "") {
            const obj = { uname: sessionStorage.getItem("username"), mail1: state.mail1, mail2: state.mail2, message: state.message }
            axios.post(url + "parent_mail", obj)
                .then((res) => {
                    // console.log(res);
                    if (res.data.success) {
                        toast.success("Email sent successfully")
                    } else {
                        alert("Error occurred while sending mail, please try again later.")
                    }
                })
                .catch(res => {
                    // console.log(res);
                    alert("Error occurred while sending mail, please try again later.")
                })
        } else {
            toast.error("Email can't be sent, Parent Emails are not available.")
        }
        setState((pre) => {
            return {
                ...pre, message: ""
            }
        })
    }

    return (

        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Students</li>
                        <li className="breadcrumb-item"><Link to="/location-student-table">My Students</Link></li>
                        <li className="breadcrumb-item"><Link to={`/student-detail/${id}`}>Student details</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Contact parent</li>
                    </ol>
                </nav>

                <Heading name="Contact Parent" />

                <div className="container-fluid">
                    <form onSubmit={onSubmit}>
                        <div className="mb-3 row">
                            <label htmlFor="input1" className=" col-sm-2 col-md-1 col-form-label">To</label>
                            <div className="col-sm-10 col-md-7">
                                <input type="text" readOnly className="form-control bg-white" value={state.fname === "" ? "" : `${state.fname}'s Parent`} />
                            </div>
                        </div>
                        {/* <div className="mb-3 row">
                    <label htmlFor="input2" className=" col-sm-2 col-md-1 col-form-label">Subject</label>
                    <div className="col-sm-10 col-md-7">
                    <input type="text" className="form-control" id="input2" placeholder="Add Subject" />
                    </div>
                </div> */}
                        <div className="mb-3 row">
                            <label className=" col-sm-2 col-md-1 col-form-label">Message</label>
                            <div className="col-sm-10 col-md-7">
                                <textarea type="text" name="message" className="form-control" value={state.message} onChange={onChange} rows="6" placeholder="Add your message here..." />
                            </div>
                        </div>
                        <div className="mt-5">
                            <Button variant="contained" type="submit" >Send</Button>
                        </div>


                    </form>
                </div>

            </div>
        </>
    )
}

export default ContactParentComp