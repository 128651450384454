import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import Heading from "../../../Common/Heading"
import { toast } from "react-toastify"
import axios from "axios";
import url from "../../../Common/URL";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const ComposeComp = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        uname: sessionStorage.getItem("username"),
        to_email: "", from_email: sessionStorage.getItem("email"), subject: "", message: "",
        sent_by: sessionStorage.getItem("email"), stud_id: "", from_role: "", to_role: "",
        reply_id: "", receiver_role: "", sender_name: `${sessionStorage.getItem("userFname")} ${sessionStorage.getItem("userLname")}`,
        reciever_name: ""
    })

    const [userData, setUserData] = useState([]);

    //    Below state is created to show and hide radio input for Admin, Superadmin, Systemadmin, Manager roles,
    //    means when they send messsage to student , then a radio input will come, to choose whether the message should be sent to
    //    Student or his parent.
    const [showRadio, setShowRadio] = useState(false);

    useEffect(() => {
        // loadEmail();
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const loadEmail = () =>{

    // }

    const loadUsers = () => {
        const obj = { uname: state.uname }
        axios.post(url + "fetch_users", obj)
            .then((response) => {
                // console.log(response)
                if (response.data.success) {
                    let a = response.data.data;
                    // console.log(a);
                    let role = sessionStorage.getItem("role");
                    let data;
                    switch (role) {
                        case "Student":
                            setState({ ...state, from_role: "Student", to_role: "Teacher", stud_id: sessionStorage.getItem("id") })
                            data = a.filter(x => x.role === "Teacher");
                            setUserData(data);
                            break;

                        case "Teacher":
                            setState({ ...state, from_role: "Teacher", to_role: "Student" })
                            data = a.filter(x => x.role === "Student");
                            setUserData(data);
                            break;
                        // Below cases are added for future enhancement prospective
                        case "Admin":
                            setState({ ...state, from_role: "Admin" })
                            setUserData(a);
                            break;

                        case "Location Manager":
                            setState({ ...state, from_role: "Location Manager" })
                            setUserData(a);
                            break;

                        case "Superadmin":
                            setState({ ...state, from_role: "Superadmin" })
                            setUserData(a);
                            break;

                        case "Systemadmin":
                            setState({ ...state, from_role: "Systemadmin" })
                            setUserData(a);
                            break;
                        default:
                            break;
                    }

                } else {
                    setUserData([]);
                }
            }).catch((err) => console.log(err));
    }

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }
    const onSubmit = (e) => {

        e.preventDefault();
        /*---------------Creating object to send data through Axios------------------------- */
        const obj = {
            uname: state.uname, to_email: state.to_email, from_email: state.from_email,
            subject: state.subject, message: state.message,
            sent_by: state.sent_by, stud_id: state.stud_id, from_role: state.from_role, to_role: state.to_role,
            reply_id: state.reply_id, sender_name: state.sender_name, reciever_name: state.reciever_name
        }

        console.log(obj);
        axios.post(url + "message", obj)
            .then((res) => {
                console.log(res)
                if (res.data.success) {
                    toast.success("Message sent successfully!")
                    setState({
                        ...state,
                        to_email: "", subject: "", message: "",
                        stud_id: state.stud_id, to_role: "",
                        reply_id: "", receiver_role: "", reciever_name: ""
                    })
                    setShowRadio(false);
                } else {
                    alert("Mail not sent!")
                }
            })
            .catch((err) => console.log(err));
    }
    return (

        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/message">Messages</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Send Message</li>
                    </ol>
                </nav>

                <Heading name="Send Message" />
                <div className="container-fluid">
                    <form onSubmit={onSubmit}>
                        {sessionStorage.getItem("role") === "Student" &&
                            <>
                                <div className="mb-3 row  mt-4">
                                    <label className="col-sm-2 col-md-1 col-form-label">From</label>
                                    <div className="col-sm-10 col-md-6">
                                        <RadioGroup row value={state.from_role}
                                            onChange={(e) => {
                                                setState({ ...state, from_role: e.target.value })
                                            }}
                                        >
                                            <FormControlLabel value="Student" control={<Radio color="primary" />} label={sessionStorage.getItem("userFname")} />
                                            <FormControlLabel value="Parent" control={<Radio color="primary" />} label={sessionStorage.getItem("userFname") + "'s Parent"} />
                                        </RadioGroup>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className=" col-sm-2 col-md-1 col-form-label">To</label>
                                    <div className="col-sm-10 col-md-6">
                                        <select className="form-control" onChange={(e) => {
                                            let d = e.target.value;
                                            setState({ ...state, to_email: d.split("*")[0], reciever_name: d.split("*")[1] })
                                        }} value={`${state.to_email}*${state.reciever_name}`} name="to_email" required>
                                            <option value="">Choose</option>
                                            {userData.map((x, i) => <option key={i} value={`${x.email}*${x.first_name} ${x.last_name}`}>{`${x.first_name} ${x.last_name}`}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </>
                        }

                        {sessionStorage.getItem("role") === "Teacher" &&
                            <>
                                <div className="mb-3 row">
                                    <label className=" col-sm-2 col-md-1 col-form-label">To</label>
                                    <div className="col-sm-10 col-md-6">
                                        <select className="form-control" onChange={(e) => {
                                            let d = e.target.value;
                                            setState({ ...state, to_email: d.split("*")[0], reciever_name: d.split("*")[1] })
                                        }} value={`${state.to_email}*${state.reciever_name}`} name="to_email" required>
                                            <option value="">Choose</option>
                                            {userData.map((x, i) => <option key={i} value={`${x.email}*${x.first_name} ${x.last_name}`}>{`${x.first_name} ${x.last_name}`}</option>)}
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3 row  mt-4">
                                    <label className="col-auto col-form-label">Send Message to</label>
                                    <div className="col-sm-10 col-md-6">
                                        <RadioGroup row value={state.to_role}
                                            onChange={(e) => {
                                                setState({ ...state, to_role: e.target.value })
                                            }}
                                        >
                                            <FormControlLabel value="Student" control={<Radio color="primary" />} label="Student" />
                                            <FormControlLabel value="Parent" control={<Radio color="primary" />} label="Student's Parent" />
                                        </RadioGroup>
                                    </div>
                                </div>
                            </>
                        }
                        {(sessionStorage.getItem("role") === "Admin" || sessionStorage.getItem("role") === "Superadmin" ||
                            sessionStorage.getItem("role") === "Location Manager" || sessionStorage.getItem("role") === "Systemadmin")
                            &&
                            <>
                                <div className="mb-3 row">
                                    <label className=" col-sm-2 col-md-1 col-form-label">To</label>
                                    <div className="col-sm-10 col-md-6">
                                        <select className="form-control"
                                            onChange={(e) => {
                                                let d = e.target.value;
                                                if (d.split("*")[1] === "Student") {
                                                    setState({ ...state, to_email: d.split("*")[0], receiver_role: d.split("*")[1], to_role: "Student", reciever_name: d.split("*")[2] });
                                                    setShowRadio(true)
                                                } else {
                                                    setState({ ...state, to_email: d.split("*")[0], to_role: d.split("*")[1], receiver_role: "", reciever_name: d.split("*")[2] });
                                                    setShowRadio(false)
                                                }
                                            }}

                                            name="to_email" required>
                                            <option value="">Choose</option>
                                            {userData.map((x, i) => <option key={i} value={`${x.email}*${x.role}*${x.first_name} ${x.last_name}`}>{`${x.first_name} ${x.last_name} - ${x.role}`}</option>)}
                                        </select>
                                    </div>
                                </div>

                                {showRadio && <div className="mb-3 row  mt-4">
                                    <label className="col-auto col-form-label">Send Message to</label>
                                    <div className="col-sm-10 col-md-6">
                                        <RadioGroup row value={state.to_role}
                                            onChange={(e) => {
                                                setState({ ...state, to_role: e.target.value })

                                            }}
                                        >
                                            <FormControlLabel value="Student" control={<Radio color="primary" />} label="Student" />
                                            <FormControlLabel value="Parent" control={<Radio color="primary" />} label="Student's Parent" />
                                        </RadioGroup>
                                    </div>
                                </div>}
                            </>
                        }

                        <div className="mb-3 row">
                            <label className=" col-sm-2 col-md-1 col-form-label red-asterisk">Subject</label>
                            <div className="col-sm-10 col-md-6">
                                <input type="text" name="subject" onChange={onChange} value={state.subject} className="form-control" placeholder="Add Subject" required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className=" col-sm-2 col-md-1 col-form-label red-asterisk">Message</label>
                            <div className="col-sm-10 col-md-6">
                                <textarea type="text" name="message" onChange={onChange} value={state.message} className="form-control" rows="6" placeholder="Add your message here..." required />
                            </div>
                        </div>
                        <div className="col-md-7 text-end mt-4">
                            <Button variant='contained' onClick={() => navigate("/message")} className="mx-3">Cancel</Button>
                            <Button variant="contained" type="submit" >Send</Button>
                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

export default ComposeComp
