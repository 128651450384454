import React ,{useState} from "react"
import {Link} from "react-router-dom"
import Heading from "../../../../Common/Heading"
import {baseUrl} from "../../../../Common/URL"
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const AbacusComp =()=>{

    const [abacus_rails , setAbacus_rails] = useState(17);
return(
    <>
    <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
        <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                <li className="breadcrumb-item">Tests</li>
                <li className="breadcrumb-item active" aria-current="page">Virtual Abacus</li>
            </ol>
        </nav> 
        <Heading name="Virtual Abacus" />

        <div className="container-fluid">
        <div className="row "> 
                <div className="col-12 position-relative" >

                    <div className=" d-flex" style={{position:"absolute" , top:0, left :"3em", zIndex:1}}>
                    <Button variant="contained"  className=" me-3 btn-outline-info border-0 shadow-sm" onClick={(e)=>{
                                e.preventDefault();
                                setAbacus_rails(17)
                            }} >Rails 17</Button>
                    <Button variant="contained"  className=" btn-outline-info border-0 shadow-sm" onClick={(e)=>{
                                e.preventDefault();
                                setAbacus_rails(22)
                            }} >Rails 22</Button>
                    </div>

                {abacus_rails === 17 ?
                     <div className="embed-responsive embed-responsive-16by9" style={{width: "100%", height: "400px", overflow: "hidden"}}>
                    <iframe src={`${baseUrl}abacus/`} style={{width: "100%", height: "600px", scroll: "none"}} title="Iframe Example"></iframe>
                    </div>
                    :
                    <div className="embed-responsive embed-responsive-16by9" style={{width: "100%", height: "400px", overflow: "hidden"}}>
                    <iframe src={`${baseUrl}abacus2/`} style={{width: "100%", height: "600px", scroll: "none"}}  title="Iframe Example"></iframe>
                    </div>
                }
                </div>
            </div> 
        </div>
    </div>
    </>

)
}

export default AbacusComp;