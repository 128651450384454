import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import axios from "axios";
import url from "../../../../Common/URL"
import { toast } from 'react-toastify';
import Input from "../../Student/Component/Input"
import Heading from "../../../../Common/Heading"
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const AddTestComp = () => {

    let navigate = useNavigate();
    const [lev, setLev] = useState([]);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        uname: sessionStorage.getItem("username"), assignment_type: "", page: "",
        test_name: "", test_type: "", level_name: "", test_desc: "", youtube_link: "",
    })

    useEffect(() => {
        loadLevel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //---------------One onChange function is used for setting the state of various types of input fields ---------------     

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    // -----------This function will Fetch all the Existing Levels--------------------
    const loadLevel = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_levels", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setLev(a);
            })
            .catch((err) => console.log(err));
    }

    //-------------------------- onSubmit function for Submitting the  Forms---------------------------

    const onSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        /*---------------Fetching Date in YEAR-MONTH-DATE  format-------------------------- */
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        var newdate = year + "-" + month + "-" + day;

        // ------------Storing form data in "obj" object fetched from various input fields-------------
        const obj = {
            uname: state.uname, created_by: state.uname, created_at: newdate,
            test_name: state.test_name,
            test_type: state.test_type, page: state.page, assignment_type: state.assignment_type,
            level_name: state.level_name,
            test_desc: state.test_desc,
            youtube_link: state.test_type === "Assignment" ? state.youtube_link : "",
        }
        // ------------Sending form data stored in "obj" object to the add_student file uusing AXIOS-------------

        // console.log(obj)
        axios.post(url + "add_test", obj)
            .then((res) => {
                console.log(res)
                if (res.data.success) {
                    toast.success("Test Created successfully!")
                    navigate("/test");

                } else {
                    toast.error("Test Not created, Please try again!")
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Test Not created, Please try again!")

            })
            .finally(() => {
                setLoading(false);
            })
    }
    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item"><Link to="/test">Tests</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Add Test</li>
                    </ol>
                </nav>

                <Heading name="Add Test" />

                <div className="container-fluid">

                    <form onSubmit={onSubmit} >
                        <div className="row mb-4 ">
                            <div className="col-12 d-flex justify-content-between align-items-end ">
                                <h5>Test Information</h5>
                                <div>
                                    <Button variant="contained" onClick={() => navigate("/test")} >Cancel</Button>
                                    <Button variant="contained" type="submit" disabled={loading} className="ms-3">Save</Button>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-11 col-md-7 mx-auto mx-md-0">

                                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Test ID</label>
                                <div className="col-sm-7 col-md-6 ">
                                <input type="text" readonly className="form-control-plaintext" value="<Auto generated>"  />
                                </div>
                            </div> */}
                                <Input field="Test Name" type="text" placeholder="Enter Name" className="form-control" onChange={onChange} name="test_name" value={state.test_name} required={true} />


                                {/* <div className="mb-3 row">
                            <label className="col-sm-5 col-form-label red-asterisk">Test Nmame</label>
                            <div className="col-sm-7 col-md-7 ">
                                <select name="test_name" value={state.test_name}  onChange={onChange} className="form-select" required>
                                    <option value="" >Select Test</option>
                                    <option value="Square Roots">Square Roots</option>
                                    <option value="Cube Roots">Cube Roots</option>
                                    <option value="Greatest Common Divisor (GCD)">Greatest Common Divisor (GCD)</option>
                                    <option value="Least Common Multiple (LCM)">Least Common Multiple (LCM)</option>
                                    <option value="Double Digits - Addition / Subtraction">Double Digits - Addition / Subtraction</option>
                                    <option value="Triple Digits - Addition / Subtraction">Triple Digits - Addition / Subtraction</option>
                                    <option value="Four Digits - Addition / Subtraction">Four Digits - Addition / Subtraction</option>
                                    <option value="Percentages">Percentages</option>
                                    <option value="Division Four Digit ÷ Double Digit">Division Four Digit ÷ Double Digit</option>
                                    <option value="Division Four Digit ÷ Triple Digit">Division Four Digit ÷ Triple Digit</option>
                                    <option value="Decimal Division">Decimal Division</option>
                                    <option value="Decimal Multiplication">Decimal Multiplication</option>
                                    <option value="Double Digits – Decimal Addition / Subtraction">Double Digits – Decimal Addition / Subtraction</option>
                                   
                                </select>
                            </div>
                        </div> */}


                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Test Type</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select name="test_type" value={state.test_type} onChange={onChange} className="form-select" required>
                                            <option value="" >Select Test</option>
                                            <option value="Assignment">Assignment</option>
                                            <option value="Test">Test</option>
                                            <option value="Exam">Exam</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Level</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select name="level_name" value={state.level_name} onChange={onChange} className="form-select" required>
                                            <option value="" >Choose</option>
                                            {lev.map((data, i) => (<option key={i} value={data.level_name}>{data.level_name}</option>))}
                                        </select>
                                    </div>
                                </div>

                                {state.test_type === "Assignment" &&
                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label red-asterisk">Assignment Type</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <select name="assignment_type" value={state.assignment_type} onChange={onChange} className="form-select" required>
                                                <option value="" >--</option>
                                                <option value="A">Class Work</option><option value="B">Home Work</option>
                                            </select>
                                        </div>
                                    </div>
                                }

                                {state.test_type === "Assignment" &&
                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label red-asterisk">Page</label>
                                        <div className="col-sm-7 col-md-7 ">

                                            <input className="form-control" type="text" name="page" value={state.page} onChange={onChange} required />
                                        </div>
                                    </div>
                                }



                                <Input field="Description" type="text" placeholder="Add Description" className="form-control" onChange={onChange} name="test_desc" value={state.test_desc} />
                                {state.test_type === "Assignment" &&
                                    <Input field="Youtube Link" type="text" placeholder="Add Link" className="form-control" onChange={onChange} name="youtube_link" value={state.youtube_link} />
                                }
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default AddTestComp;