import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import LoadingSpinner from "../../../../Common/LoadingSpinner"
import Heading from "../../../../Common/Heading";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from "axios";
import url from "../../../../Common/URL";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useSelector } from "react-redux";
import { equal } from "mathjs";

const AssignmentPreviewComp = () => {

    const { test_name, id, stu_ans, st_uname, date_time } = useParams();
    const [load, setLoad] = useState(false);
    var All_Ans = [];
    useEffect(() => {
        loadQuestions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // -------------------------This will check how many questions are there in any given Assignment page ----------------------

    const [total_que, setTotal_que] = useState("");

    // ------------------------------Test table data starts------------------------

    const [head_id, setHead_id] = useState({
        table1: [], table2: [], table3: [], table4: [], table5: [], table6: [],
    });

    const [answer, setAnswer] = useState({
        answer1: [], answer2: [], answer3: [], answer4: [], answer5: [], answer6: []
    });

    //----------------- stu_ans = answer which student has submitted -----------------------
    const [student_ans, setStudent_ans] = useState({
        answer1: [], answer2: [], answer3: [], answer4: [], answer5: [], answer6: [],
    });

    const [data, setData] = useState({
        main_arr1: [], main_arr2: [], main_arr3: [], main_arr4: [], main_arr5: [], main_arr6: [],
    });

    var answer1 = [], answer2 = [], answer3 = [], answer4 = [], answer5 = [], answer6 = [];

    const loadQuestions = () => {
        setLoad(true);

        var Table1_id = [], Table1_arr1 = [], Table1_arr2 = [], Table1_arr3 = [], Table1_arr4 = [], Table1_arr5 = [], Table1_arr6 = [], Table1_arr7 = [], Table1_arr8 = [], Table1_arr9 = [], Table1_arr10 = [], Table1_arr11 = [], Table1_arr12 = [], Table1_arr13 = [], Table1_arr14 = [], Table1_arr15 = [], Table1_arr16 = [],
            Table2_id = [], Table2_arr1 = [], Table2_arr2 = [], Table2_arr3 = [], Table2_arr4 = [], Table2_arr5 = [], Table2_arr6 = [], Table2_arr7 = [], Table2_arr8 = [], Table2_arr9 = [], Table2_arr10 = [], Table2_arr11 = [], Table2_arr12 = [], Table2_arr13 = [], Table2_arr14 = [], Table2_arr15 = [], Table2_arr16 = [],
            Table3_id = [], Table3_arr1 = [], Table3_arr2 = [], Table3_arr3 = [], Table3_arr4 = [], Table3_arr5 = [], Table3_arr6 = [], Table3_arr7 = [],
            Table4_id = [], Table4_arr1 = [], Table4_arr2 = [], Table4_arr3 = [], Table4_arr4 = [], Table4_arr5 = [], Table4_arr6 = [], Table4_arr7 = [],
            Table5_id = [], Table5_arr1 = [], Table5_arr2 = [], Table5_arr3 = [], Table5_arr4 = [], Table5_arr5 = [], Table5_arr6 = [], Table5_arr7 = [],
            Table6_id = [], Table6_arr1 = [], Table6_arr2 = [], Table6_arr3 = [], Table6_arr4 = [], Table6_arr5 = [], Table6_arr6 = [], Table6_arr7 = [];

        var Table1_arr = [], Table2_arr = [], Table3_arr = [], Table4_arr = [], Table5_arr = [], Table6_arr = [];

        var arr1 = [], arr2 = [], arr3 = [], arr4 = [], arr5 = [], arr6 = [];

        const obj = { uname: sessionStorage.getItem("username"), category_name: "", test_id: id }

        axios.post(url + "questions_level", obj)
            .then((response) => {
                // console.log(response);
                if (response.data.success) {
                    var a = response.data.data;
                    // console.log(a);

                    var len = a.length;
                    setTotal_que(a.length);

                    Table1_arr.push((a.splice(0, 10)));
                    Table2_arr.push((a.splice(0, 10)));
                    Table3_arr.push((a.splice(0, 10)));
                    Table4_arr.push((a.splice(0, 10)));
                    Table5_arr.push((a.splice(0, 10)));
                    Table6_arr.push((a.splice(0, 10)));

                    //Storing header values for the 6 tables
                    var x = [...Array(len).keys()];
                    x = x.map(x => x + 1);
                    //   console.log(x)
                    Table1_id.push((x.splice(0, 10)));
                    Table2_id.push((x.splice(0, 10)));
                    Table3_id.push((x.splice(0, 10)));
                    Table4_id.push((x.splice(0, 10)));
                    Table5_id.push((x.splice(0, 10)));
                    Table6_id.push((x.splice(0, 10)));

                    setHead_id({
                        table1: Table1_id, table2: Table2_id, table3: Table3_id, table4: Table4_id, table5: Table5_id, table6: Table6_id
                    })

                    //Storing Answer of each questions for the 9 tables
                    answer1.push(Table1_arr.map((x) => x.map(x => x.answer)));
                    answer2.push(Table2_arr.map((x) => x.map(x => x.answer)));
                    answer3.push(Table3_arr.map((x) => x.map(x => x.answer)));
                    answer4.push(Table4_arr.map((x) => x.map(x => x.answer)));
                    answer5.push(Table5_arr.map((x) => x.map(x => x.answer)));
                    answer6.push(Table6_arr.map((x) => x.map(x => x.answer)));

                    setAnswer({
                        answer1: answer1, answer2: answer2, answer3: answer3, answer4: answer4, answer5: answer5, answer6: answer6
                    })

                    let arr = [...answer1, ...answer2, ...answer3, ...answer4, ...answer5, ...answer6];

                    arr.forEach(x => {
                        for (let i = 0; i < x.length; i++) {
                            All_Ans.push(...x[i]);
                        }
                    });

                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 1
                    Table1_arr1.push(Table1_arr.map((x) => x.map(x => x.ques_num1))); Table1_arr9.push(Table1_arr.map((x) => x.map(x => x.ques_num9)));
                    Table1_arr2.push(Table1_arr.map((x) => x.map(x => x.ques_num2))); Table1_arr10.push(Table1_arr.map((x) => x.map(x => x.ques_num10)));
                    Table1_arr3.push(Table1_arr.map((x) => x.map(x => x.ques_num3))); Table1_arr11.push(Table1_arr.map((x) => x.map(x => x.ques_num11)));
                    Table1_arr4.push(Table1_arr.map((x) => x.map(x => x.ques_num4))); Table1_arr12.push(Table1_arr.map((x) => x.map(x => x.ques_num12)));
                    Table1_arr5.push(Table1_arr.map((x) => x.map(x => x.ques_num5))); Table1_arr13.push(Table1_arr.map((x) => x.map(x => x.ques_num13)));
                    Table1_arr6.push(Table1_arr.map((x) => x.map(x => x.ques_num6))); Table1_arr14.push(Table1_arr.map((x) => x.map(x => x.ques_num14)));
                    Table1_arr7.push(Table1_arr.map((x) => x.map(x => x.ques_num7))); Table1_arr15.push(Table1_arr.map((x) => x.map(x => x.ques_num15)));
                    Table1_arr8.push(Table1_arr.map((x) => x.map(x => x.ques_num8))); Table1_arr16.push(Table1_arr.map((x) => x.map(x => x.ques_num16)));


                    // Now storing the above 16 arrays into One array named "arr1" 
                    arr1 = [...Table1_arr1, ...Table1_arr2, ...Table1_arr3, ...Table1_arr4,
                    ...Table1_arr5, ...Table1_arr6, ...Table1_arr7, ...Table1_arr8,
                    ...Table1_arr9, ...Table1_arr10, ...Table1_arr11, ...Table1_arr12,
                    ...Table1_arr13, ...Table1_arr14, ...Table1_arr15, ...Table1_arr16];
                    /*----------------------------------------------------------------------*/

                    // Storing Values for table 2
                    Table2_arr1.push(Table2_arr.map((x) => x.map(x => x.ques_num1))); Table2_arr9.push(Table2_arr.map((x) => x.map(x => x.ques_num9)));
                    Table2_arr2.push(Table2_arr.map((x) => x.map(x => x.ques_num2))); Table2_arr10.push(Table2_arr.map((x) => x.map(x => x.ques_num10)));
                    Table2_arr3.push(Table2_arr.map((x) => x.map(x => x.ques_num3))); Table2_arr11.push(Table2_arr.map((x) => x.map(x => x.ques_num11)));
                    Table2_arr4.push(Table2_arr.map((x) => x.map(x => x.ques_num4))); Table2_arr12.push(Table2_arr.map((x) => x.map(x => x.ques_num12)));
                    Table2_arr5.push(Table2_arr.map((x) => x.map(x => x.ques_num5))); Table2_arr13.push(Table2_arr.map((x) => x.map(x => x.ques_num13)));
                    Table2_arr6.push(Table2_arr.map((x) => x.map(x => x.ques_num6))); Table2_arr14.push(Table2_arr.map((x) => x.map(x => x.ques_num14)));
                    Table2_arr7.push(Table2_arr.map((x) => x.map(x => x.ques_num7))); Table2_arr15.push(Table2_arr.map((x) => x.map(x => x.ques_num15)));
                    Table2_arr8.push(Table2_arr.map((x) => x.map(x => x.ques_num8))); Table2_arr16.push(Table2_arr.map((x) => x.map(x => x.ques_num16)));


                    // Now storing the above 16 arrays into One array named "arr2" 
                    arr2 = [...Table2_arr1, ...Table2_arr2, ...Table2_arr3, ...Table2_arr4,
                    ...Table2_arr5, ...Table2_arr6, ...Table2_arr7, ...Table2_arr8,
                    ...Table2_arr9, ...Table2_arr10, ...Table2_arr11, ...Table2_arr12,
                    ...Table2_arr13, ...Table2_arr14, ...Table2_arr15, ...Table2_arr16];
                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 3
                    Table3_arr1.push(Table3_arr.map((x) => x.map(x => x.ques_num1)))
                    Table3_arr2.push(Table3_arr.map((x) => x.map(x => x.ques_num2)))
                    Table3_arr3.push(Table3_arr.map((x) => x.map(x => x.ques_num3)))
                    Table3_arr4.push(Table3_arr.map((x) => x.map(x => x.ques_num4)))
                    Table3_arr5.push(Table3_arr.map((x) => x.map(x => x.ques_num5)))
                    Table3_arr6.push(Table3_arr.map((x) => x.map(x => x.ques_num6)))
                    Table3_arr7.push(Table3_arr.map((x) => x.map(x => x.ques_num7)))


                    // Now storing the above 7 arrays into One array named "arr3" 
                    arr3 = [...Table3_arr1, ...Table3_arr2, ...Table3_arr3, ...Table3_arr4, ...Table3_arr5, ...Table3_arr6, ...Table3_arr7];
                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 4
                    Table4_arr1.push(Table4_arr.map((x) => x.map(x => x.ques_num1)))
                    Table4_arr2.push(Table4_arr.map((x) => x.map(x => x.ques_num2)))
                    Table4_arr3.push(Table4_arr.map((x) => x.map(x => x.ques_num3)))
                    Table4_arr4.push(Table4_arr.map((x) => x.map(x => x.ques_num4)))
                    Table4_arr5.push(Table4_arr.map((x) => x.map(x => x.ques_num5)))
                    Table4_arr6.push(Table4_arr.map((x) => x.map(x => x.ques_num6)))
                    Table4_arr7.push(Table4_arr.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 7 arrays into One array named "arr4" 
                    arr4 = [...Table4_arr1, ...Table4_arr2, ...Table4_arr3, ...Table4_arr4, ...Table4_arr5, ...Table4_arr6, ...Table4_arr7];

                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 5
                    Table5_arr1.push(Table5_arr.map((x) => x.map(x => x.ques_num1)))
                    Table5_arr2.push(Table5_arr.map((x) => x.map(x => x.ques_num2)))
                    Table5_arr3.push(Table5_arr.map((x) => x.map(x => x.ques_num3)))
                    Table5_arr4.push(Table5_arr.map((x) => x.map(x => x.ques_num4)))
                    Table5_arr5.push(Table5_arr.map((x) => x.map(x => x.ques_num5)))
                    Table5_arr6.push(Table5_arr.map((x) => x.map(x => x.ques_num6)))
                    Table5_arr7.push(Table5_arr.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 7 arrays into One array named "arr5" 
                    arr5 = [...Table5_arr1, ...Table5_arr2, ...Table5_arr3, ...Table5_arr4, ...Table5_arr5, ...Table5_arr6, ...Table5_arr7];

                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 5
                    Table6_arr1.push(Table6_arr.map((x) => x.map(x => x.ques_num1)))
                    Table6_arr2.push(Table6_arr.map((x) => x.map(x => x.ques_num2)))
                    Table6_arr3.push(Table6_arr.map((x) => x.map(x => x.ques_num3)))
                    Table6_arr4.push(Table6_arr.map((x) => x.map(x => x.ques_num4)))
                    Table6_arr5.push(Table6_arr.map((x) => x.map(x => x.ques_num5)))
                    Table6_arr6.push(Table6_arr.map((x) => x.map(x => x.ques_num6)))
                    Table6_arr7.push(Table6_arr.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 7 arrays into One array named "arr6" 
                    arr6 = [...Table6_arr1, ...Table6_arr2, ...Table6_arr3, ...Table6_arr4, ...Table6_arr5, ...Table6_arr6, ...Table6_arr7];

                    // console.log(arr1);console.log(arr2);console.log(arr3);console.log(arr4);console.log(arr);
                    /*----------------------------------------------------------------------*/
                    setData({
                        main_arr1: arr1, main_arr2: arr2, main_arr3: arr3, main_arr4: arr4, main_arr5: arr5, main_arr6: arr6,
                    })
                    setLoad(false);
                } else {
                    alert("Unfortunately, the preview is not available at the moment. Please try again later");
                    window.close();
                    // setData([]);
                }
            }).then(() => {
                if (stu_ans === "1") {
                    var obj_ans = { uname: st_uname, id: id, test_date_time: date_time };

                    axios.post(url + "test_answers", obj_ans)
                        .then((response) => {
                            // console.log(response);
                            if (response.data.message === "User Not Login") {
                                alert("Student has been removed from portal, and corresponding answer records has also been removed.");
                                window.close();
                            } else {
                                var a = response.data.data;
                              //  console.log(a);
                                if (!a) {   // if a equals null or undefined 
                                    alert("The test answers are not present.");
                                    window.close();
                                }

                                var stu_ans_array = [];

                                a.forEach(e => stu_ans_array.push(e.ans)); // This will store all the answers

                                for (let i = 0; i < stu_ans_array.length; i++) {  //This will check whether Student's answers are right or wrong
                                    if (stu_ans_array[i] === "") {
                                        stu_ans_array[i] = stu_ans_array[i] + "--";
                                    }
                                    else if (equal(stu_ans_array[i], All_Ans[i])) {
                                        let diff = "_";
                                        stu_ans_array[i] = stu_ans_array[i] + diff; // diff will add an underscore after right answers
                                    }
                                }

                                setStudent_ans({
                                    answer1: stu_ans_array.splice(0, 10), answer2: stu_ans_array.splice(0, 10), answer3: stu_ans_array.splice(0, 10),
                                    answer4: stu_ans_array.splice(0, 10), answer5: stu_ans_array.splice(0, 10), answer6: stu_ans_array.splice(0, 10)
                                })
                            }
                        })
                        .then(() => setLoad(false))
                        .catch((err) => console.log(err));
                }
            }).catch((err) => console.log(err));
    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>

                {stu_ans === "1" ?
                    <Heading name={`${test_name} ${date_time}`} /> :
                    <Heading name={`Exam Preview : ${test_name}`} />
                }
                {load ? <LoadingSpinner />
                    :
                    <div className="container-fluid">

                        {stu_ans === "1" &&
                            <div className="row ">
                                <div className="col-12 mx-auto ">
                                    <h6 ><FiberManualRecordIcon style={{ color: "green" }} /> = Right Answer</h6>
                                    <h6 ><FiberManualRecordIcon style={{ color: "red" }} /> = Wrong Answer</h6>
                                    <h6><span className="fw-bolder ms-2 me-1">--</span> = Not Answered</h6>
                                </div><hr className="w-100" />
                            </div>
                        }

                        <div className="row ">
                            <div className="col-12 mx-auto ">
                                <TableContainer component={Paper} className="mt-3">
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>{head_id.table1.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}</TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data.main_arr1.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i} className="d-none"></TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}

                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {student_ans.answer1.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }


                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer.answer1.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div className="row my-5">
                            <div className="col-12 mx-auto ">

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>{head_id.table2.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}</TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data.main_arr2.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i} className="d-none"></TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}

                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {student_ans.answer2.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }



                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer.answer2.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div className="row my-5">

                            <div className="col-12 mx-auto ">

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>

                                                {head_id.table3.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data.main_arr3.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i} className="d-none"></TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}

                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {student_ans.answer3.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }


                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer.answer3.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div className="row my-5">

                            <div className="col-12 mx-auto ">

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>

                                                {head_id.table4.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data.main_arr4.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i} className="d-none"></TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}

                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {student_ans.answer4.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }


                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer.answer4.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div className="row my-5">

                            <div className="col-12 mx-auto ">

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>

                                                {head_id.table5.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data.main_arr5.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i} className="d-none"></TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}

                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {student_ans.answer5.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }


                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer.answer5.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div className="row my-5">

                            <div className="col-12 mx-auto ">

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>

                                                {head_id.table6.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data.main_arr6.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i} className="d-none"></TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}

                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {student_ans.answer6.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }


                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer.answer6.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>


                    </div>}
            </div>
        </>
    );
}

export default AssignmentPreviewComp;
