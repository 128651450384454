import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material"

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Link, useParams, useNavigate, useLocation, Prompt } from "react-router-dom";
import Heading from "../../../../Common/Heading";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from "axios";
import url from "../../../../Common/URL";
import { useConfirm } from "material-ui-confirm";
import GenerateInputRow from "../../../../Common/GenerateInputRow";
import { useSelector } from "react-redux";
import AlertBlockForQuestionPapers from "../../../../Common/AlertBlockForQuestionPapers";



const AssignmentSheetComp = () => {

    const confirm = useConfirm();
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    // ====================== Code to confirm page reload ==============================

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };
        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);

    // ================== Code to confirm Route-Change / Back-Press ====================

    useEffect(() => {
        console.log(location);

    }, []);

    // =================================================================================

    const ans = {};  // This is used to store answers in obj , which is sent in axios post while submitting the Test

    const { test_name, level_name, yt_url, page, test_type, id, draft_answer } = useParams();


    // -------------------------This will check how many questions are there in any given Assignment page ----------------------

    const [total_que, setTotal_que] = useState("");

    // -----------------------For iframe video modal ------------------------------------------------
    const [open_iframe, setOpen_iframe] = useState(false);

    const [head_id, setHead_id] = useState({
        table1: [], table2: [], table3: [], table4: [], table5: [], table6: [],
    });

    const [answer, setAnswer] = useState({
        answer1: [], answer2: [], answer3: [], answer4: [], answer5: [], answer6: []
    });

    const [data, setData] = useState({
        main_arr1: [], main_arr2: [], main_arr3: [], main_arr4: [], main_arr5: [], main_arr6: [],
    });

    const [field1, setField1] = useState({
        val1: "", val2: "", val3: "", val4: "", val5: "", val6: "", val7: "", val8: "", val9: "", val10: "",
        val11: "", val12: "", val13: "", val14: "", val15: "", val16: "", val17: "", val18: "", val19: "", val20: "",
    })

    const [field2, setField2] = useState({
        val21: "", val22: "", val23: "", val24: "", val25: "", val26: "", val27: "", val28: "", val29: "", val30: "",
        val31: "", val32: "", val33: "", val34: "", val35: "", val36: "", val37: "", val38: "", val39: "", val40: "",
    })

    const [field3, setField3] = useState({
        val41: "", val42: "", val43: "", val44: "", val45: "", val46: "", val47: "", val48: "", val49: "", val50: "",
        val51: "", val52: "", val53: "", val54: "", val55: "", val56: "", val57: "", val58: "", val59: "", val60: "",
    })

    const [value, setValue] = useState({
        count: "", attemptedpercentage: "", notans: "", overallpercentage: "", wrongans: "",
    })

    const [show, setShow] = useState(false);
    const [result, setResult] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [storedAnswers, setStoredAnswers] = useState();


    useEffect(() => {
        generateTest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    // Function will show generated test in table and make the submit button appear  
    const generateTest = () => {
        setData({ main_arr1: [], main_arr2: [], main_arr3: [], main_arr4: [], main_arr5: [], main_arr6: [] })
        setIsSubmitted(false);
        // Save Draft Feature ---------------------------------------------
        if (draft_answer === "1") {
            // ----------- This function loads the stored assignment's answers ------------------
            const obj = { uname: sessionStorage.getItem("username"), user_id: sessionStorage.getItem('userId'), id: id };
            axios
                .post(url + "draft_answer", obj)
                .then((response) => {
                    // console.log(response);

                    let resData = response.data.data;
                    // console.log(resData);

                    setField1({
                        val1: resData[0], val2: resData[1], val3: resData[2], val4: resData[3], val5: resData[4], val6: resData[5],
                        val7: resData[6], val8: resData[7], val9: resData[8], val10: resData[9], val11: resData[10], val12: resData[11],
                        val13: resData[12], val14: resData[13], val15: resData[14], val16: resData[15], val17: resData[16], val18: resData[17],
                        val19: resData[18], val20: resData[19],
                    })
                    setField2({
                        val21: resData[20], val22: resData[21], val23: resData[22], val24: resData[23], val25: resData[24], val26: resData[25],
                        val27: resData[26], val28: resData[27], val29: resData[28], val30: resData[29], val31: resData[30], val32: resData[31],
                        val33: resData[32], val34: resData[33], val35: resData[34], val36: resData[35], val37: resData[36], val38: resData[37],
                        val39: resData[38], val40: resData[39],
                    })
                    setField3({
                        val41: resData[40], val42: resData[41], val43: resData[42], val44: resData[43], val45: resData[44], val46: resData[45],
                        val47: resData[46], val48: resData[47], val49: resData[48], val50: resData[49], val51: resData[50], val52: resData[51],
                        val53: resData[52], val54: resData[53], val55: resData[54], val56: resData[55], val57: resData[56], val58: resData[57],
                        val59: resData[58], val60: resData[59],
                    })


                }).catch((err) => {
                    console.log(err);
                    alert('Unable to fetch answers!');
                });
        } else {
            // All answer fields will remain empty here
            setField1({
                val1: "", val2: "", val3: "", val4: "", val5: "", val6: "", val7: "", val8: "", val9: "", val10: "",
                val11: "", val12: "", val13: "", val14: "", val15: "", val16: "", val17: "", val18: "", val19: "", val20: "",
            })
            setField2({
                val21: "", val22: "", val23: "", val24: "", val25: "", val26: "", val27: "", val28: "", val29: "", val30: "",
                val31: "", val32: "", val33: "", val34: "", val35: "", val36: "", val37: "", val38: "", val39: "", val40: "",
            })
            setField3({
                val41: "", val42: "", val43: "", val44: "", val45: "", val46: "", val47: "", val48: "", val49: "", val50: "",
                val51: "", val52: "", val53: "", val54: "", val55: "", val56: "", val57: "", val58: "", val59: "", val60: "",
            })
        }

        loadQuestions();
        setResult(false);
    }

    var answer1 = [], answer2 = [], answer3 = [], answer4 = [], answer5 = [], answer6 = [];

    const ANS_ARR = [
        field1.val1, field1.val2, field1.val3, field1.val4, field1.val5, field1.val6, field1.val7, field1.val8, field1.val9, field1.val10,
        field1.val11, field1.val12, field1.val13, field1.val14, field1.val15, field1.val16, field1.val17, field1.val18, field1.val19, field1.val20,
        field2.val21, field2.val22, field2.val23, field2.val24, field2.val25, field2.val26, field2.val27, field2.val28, field2.val29, field2.val30,
        field2.val31, field2.val32, field2.val33, field2.val34, field2.val35, field2.val36, field2.val37, field2.val38, field2.val39, field2.val40,
        field3.val41, field3.val42, field3.val43, field3.val44, field3.val45, field3.val46, field3.val47, field3.val48, field3.val49, field3.val50,
        field3.val51, field3.val52, field3.val53, field3.val54, field3.val55, field3.val56, field3.val57, field3.val58, field3.val59, field3.val60,
    ]

    // ------------------------Fetching Question table's data-------------------------------------

    const loadQuestions = async () => {

        var Table1_id = [], Table1_arr1 = [], Table1_arr2 = [], Table1_arr3 = [], Table1_arr4 = [], Table1_arr5 = [], Table1_arr6 = [], Table1_arr7 = [], Table1_arr8 = [], Table1_arr9 = [], Table1_arr10 = [], Table1_arr11 = [], Table1_arr12 = [], Table1_arr13 = [], Table1_arr14 = [], Table1_arr15 = [], Table1_arr16 = [],
            Table2_id = [], Table2_arr1 = [], Table2_arr2 = [], Table2_arr3 = [], Table2_arr4 = [], Table2_arr5 = [], Table2_arr6 = [], Table2_arr7 = [], Table2_arr8 = [], Table2_arr9 = [], Table2_arr10 = [], Table2_arr11 = [], Table2_arr12 = [], Table2_arr13 = [], Table2_arr14 = [], Table2_arr15 = [], Table2_arr16 = [],
            Table3_id = [], Table3_arr1 = [], Table3_arr2 = [], Table3_arr3 = [], Table3_arr4 = [], Table3_arr5 = [], Table3_arr6 = [], Table3_arr7 = [],
            Table4_id = [], Table4_arr1 = [], Table4_arr2 = [], Table4_arr3 = [], Table4_arr4 = [], Table4_arr5 = [], Table4_arr6 = [], Table4_arr7 = [],
            Table5_id = [], Table5_arr1 = [], Table5_arr2 = [], Table5_arr3 = [], Table5_arr4 = [], Table5_arr5 = [], Table5_arr6 = [], Table5_arr7 = [],
            Table6_id = [], Table6_arr1 = [], Table6_arr2 = [], Table6_arr3 = [], Table6_arr4 = [], Table6_arr5 = [], Table6_arr6 = [], Table6_arr7 = [];

        var Table1_arr = [], Table2_arr = [], Table3_arr = [], Table4_arr = [], Table5_arr = [], Table6_arr = [];

        var arr1 = [], arr2 = [], arr3 = [], arr4 = [], arr5 = [], arr6 = [];

        const obj = { uname: sessionStorage.getItem("username"), category_name: "", test_id: id }

        await axios.post(url + "questions_level", obj)
            .then((response) => {
                // console.log(response);
                if (response.data.success) {
                    var a = response.data.data;
                    var len = a.length;
                    // console.log(len);
                    setTotal_que(a.length);

                    Table1_arr.push((a.splice(0, 10)));
                    Table2_arr.push((a.splice(0, 10)));
                    Table3_arr.push((a.splice(0, 10)));
                    Table4_arr.push((a.splice(0, 10)));
                    Table5_arr.push((a.splice(0, 10)));
                    Table6_arr.push((a.splice(0, 10)));

                    //Storing header values for the 9 tables
                    var x = [...Array(len).keys()];
                    x = x.map(x => x + 1);
                    // console.log(x)
                    Table1_id.push((x.splice(0, 10)));
                    Table2_id.push((x.splice(0, 10)));
                    Table3_id.push((x.splice(0, 10)));
                    Table4_id.push((x.splice(0, 10)));
                    Table5_id.push((x.splice(0, 10)));
                    Table6_id.push((x.splice(0, 10)));

                    setHead_id({
                        table1: Table1_id, table2: Table2_id, table3: Table3_id, table4: Table4_id, table5: Table5_id, table6: Table6_id,
                    })

                    //Storing Answer of each questions for the 9 tables
                    answer1.push(Table1_arr.map((x) => x.map(x => x.answer)));
                    answer2.push(Table2_arr.map((x) => x.map(x => x.answer)));
                    answer3.push(Table3_arr.map((x) => x.map(x => x.answer)));
                    answer4.push(Table4_arr.map((x) => x.map(x => x.answer)));
                    answer5.push(Table5_arr.map((x) => x.map(x => x.answer)));
                    answer6.push(Table6_arr.map((x) => x.map(x => x.answer)));

                    setAnswer({ answer1: answer1, answer2: answer2, answer3: answer3, answer4: answer4, answer5: answer5, answer6: answer6 })

                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 1
                    Table1_arr1.push(Table1_arr.map((x) => x.map(x => x.ques_num1))); Table1_arr9.push(Table1_arr.map((x) => x.map(x => x.ques_num9)));
                    Table1_arr2.push(Table1_arr.map((x) => x.map(x => x.ques_num2))); Table1_arr10.push(Table1_arr.map((x) => x.map(x => x.ques_num10)));
                    Table1_arr3.push(Table1_arr.map((x) => x.map(x => x.ques_num3))); Table1_arr11.push(Table1_arr.map((x) => x.map(x => x.ques_num11)));
                    Table1_arr4.push(Table1_arr.map((x) => x.map(x => x.ques_num4))); Table1_arr12.push(Table1_arr.map((x) => x.map(x => x.ques_num12)));
                    Table1_arr5.push(Table1_arr.map((x) => x.map(x => x.ques_num5))); Table1_arr13.push(Table1_arr.map((x) => x.map(x => x.ques_num13)));
                    Table1_arr6.push(Table1_arr.map((x) => x.map(x => x.ques_num6))); Table1_arr14.push(Table1_arr.map((x) => x.map(x => x.ques_num14)));
                    Table1_arr7.push(Table1_arr.map((x) => x.map(x => x.ques_num7))); Table1_arr15.push(Table1_arr.map((x) => x.map(x => x.ques_num15)));
                    Table1_arr8.push(Table1_arr.map((x) => x.map(x => x.ques_num8))); Table1_arr16.push(Table1_arr.map((x) => x.map(x => x.ques_num16)));


                    // Now storing the above 16 arrays into One array named "arr1" 
                    arr1 = [...Table1_arr1, ...Table1_arr2, ...Table1_arr3, ...Table1_arr4, ...Table1_arr5, ...Table1_arr6, ...Table1_arr7, ...Table1_arr8,
                    ...Table1_arr9, ...Table1_arr10, ...Table1_arr11, ...Table1_arr12, ...Table1_arr13, ...Table1_arr14, ...Table1_arr15, ...Table1_arr16];
                    /*----------------------------------------------------------------------*/

                    // Storing Values for table 2
                    Table2_arr1.push(Table2_arr.map((x) => x.map(x => x.ques_num1))); Table2_arr9.push(Table2_arr.map((x) => x.map(x => x.ques_num9)));
                    Table2_arr2.push(Table2_arr.map((x) => x.map(x => x.ques_num2))); Table2_arr10.push(Table2_arr.map((x) => x.map(x => x.ques_num10)));
                    Table2_arr3.push(Table2_arr.map((x) => x.map(x => x.ques_num3))); Table2_arr11.push(Table2_arr.map((x) => x.map(x => x.ques_num11)));
                    Table2_arr4.push(Table2_arr.map((x) => x.map(x => x.ques_num4))); Table2_arr12.push(Table2_arr.map((x) => x.map(x => x.ques_num12)));
                    Table2_arr5.push(Table2_arr.map((x) => x.map(x => x.ques_num5))); Table2_arr13.push(Table2_arr.map((x) => x.map(x => x.ques_num13)));
                    Table2_arr6.push(Table2_arr.map((x) => x.map(x => x.ques_num6))); Table2_arr14.push(Table2_arr.map((x) => x.map(x => x.ques_num14)));
                    Table2_arr7.push(Table2_arr.map((x) => x.map(x => x.ques_num7))); Table2_arr15.push(Table2_arr.map((x) => x.map(x => x.ques_num15)));
                    Table2_arr8.push(Table2_arr.map((x) => x.map(x => x.ques_num8))); Table2_arr16.push(Table2_arr.map((x) => x.map(x => x.ques_num16)));


                    // Now storing the above 16 arrays into One array named "arr2" 
                    arr2 = [...Table2_arr1, ...Table2_arr2, ...Table2_arr3, ...Table2_arr4, ...Table2_arr5, ...Table2_arr6, ...Table2_arr7, ...Table2_arr8,
                    ...Table2_arr9, ...Table2_arr10, ...Table2_arr11, ...Table2_arr12, ...Table2_arr13, ...Table2_arr14, ...Table2_arr15, ...Table2_arr16];
                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 3
                    Table3_arr1.push(Table3_arr.map((x) => x.map(x => x.ques_num1)))
                    Table3_arr2.push(Table3_arr.map((x) => x.map(x => x.ques_num2)))
                    Table3_arr3.push(Table3_arr.map((x) => x.map(x => x.ques_num3)))
                    Table3_arr4.push(Table3_arr.map((x) => x.map(x => x.ques_num4)))
                    Table3_arr5.push(Table3_arr.map((x) => x.map(x => x.ques_num5)))
                    Table3_arr6.push(Table3_arr.map((x) => x.map(x => x.ques_num6)))
                    Table3_arr7.push(Table3_arr.map((x) => x.map(x => x.ques_num7)))


                    // Now storing the above 7 arrays into One array named "arr3" 
                    arr3 = [...Table3_arr1, ...Table3_arr2, ...Table3_arr3, ...Table3_arr4, ...Table3_arr5, ...Table3_arr6, ...Table3_arr7];
                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 4
                    Table4_arr1.push(Table4_arr.map((x) => x.map(x => x.ques_num1)))
                    Table4_arr2.push(Table4_arr.map((x) => x.map(x => x.ques_num2)))
                    Table4_arr3.push(Table4_arr.map((x) => x.map(x => x.ques_num3)))
                    Table4_arr4.push(Table4_arr.map((x) => x.map(x => x.ques_num4)))
                    Table4_arr5.push(Table4_arr.map((x) => x.map(x => x.ques_num5)))
                    Table4_arr6.push(Table4_arr.map((x) => x.map(x => x.ques_num6)))
                    Table4_arr7.push(Table4_arr.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 7 arrays into One array named "arr3" 
                    arr4 = [...Table4_arr1, ...Table4_arr2, ...Table4_arr3, ...Table4_arr4, ...Table4_arr5, ...Table4_arr6, ...Table4_arr7];

                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 5
                    Table5_arr1.push(Table5_arr.map((x) => x.map(x => x.ques_num1)))
                    Table5_arr2.push(Table5_arr.map((x) => x.map(x => x.ques_num2)))
                    Table5_arr3.push(Table5_arr.map((x) => x.map(x => x.ques_num3)))
                    Table5_arr4.push(Table5_arr.map((x) => x.map(x => x.ques_num4)))
                    Table5_arr5.push(Table5_arr.map((x) => x.map(x => x.ques_num5)))
                    Table5_arr6.push(Table5_arr.map((x) => x.map(x => x.ques_num6)))
                    Table5_arr7.push(Table5_arr.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 7 arrays into One array named "arr3" 
                    arr5 = [...Table5_arr1, ...Table5_arr2, ...Table5_arr3, ...Table5_arr4, ...Table5_arr5, ...Table5_arr6, ...Table5_arr7];


                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 6
                    Table6_arr1.push(Table6_arr.map((x) => x.map(x => x.ques_num1)))
                    Table6_arr2.push(Table6_arr.map((x) => x.map(x => x.ques_num2)))
                    Table6_arr3.push(Table6_arr.map((x) => x.map(x => x.ques_num3)))
                    Table6_arr4.push(Table6_arr.map((x) => x.map(x => x.ques_num4)))
                    Table6_arr5.push(Table6_arr.map((x) => x.map(x => x.ques_num5)))
                    Table6_arr6.push(Table6_arr.map((x) => x.map(x => x.ques_num6)))
                    Table6_arr7.push(Table6_arr.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 7 arrays into One array named "arr3" 
                    arr6 = [...Table6_arr1, ...Table6_arr2, ...Table6_arr3, ...Table6_arr4, ...Table6_arr5, ...Table6_arr6, ...Table6_arr7];

                    // console.log(arr1);console.log(arr2);console.log(arr3);console.log(arr4);console.log(arr5);
                    /*----------------------------------------------------------------------*/
                    setData({ main_arr1: arr1, main_arr2: arr2, main_arr3: arr3, main_arr4: arr4, main_arr5: arr5, main_arr6: arr6 })
                } else {
                    // setData([]);
                }
            })
            .catch((err) => console.log(err));

        if (yt_url !== "null") {
            setOpen_iframe(true);
        } else {
            setShow(true);
            draft_answer === "1" ?
                toast.success("Test loaded successfully, along with previously saved Answers!") :
                toast.success("Test loaded successfully");

            // setDate((Date.now()+ 1500000))
        }
    }


    // This function runs when the Submit button is Clicked
    const onSubmitTest = () => {

        // *********************************************************
        var role = sessionStorage.getItem("role");
        var location_name;
        var batch_name;

        if (role === "Student") {
            location_name = sessionStorage.getItem("location");
            batch_name = sessionStorage.getItem("batch");
        } else {
            location_name = "";
            batch_name = "";
        }

        // *********************************************************
        for (var i = 0; i < total_que; i++) {
            // Below "ans[]" Array is previously declared
            ans[`val${i + 1}`] = ANS_ARR[i] || ""
        }

        // ********************************************************* 
        const obj = {
            uname: sessionStorage.getItem("username"), location_name: location_name, batch_name: batch_name,
            level_name: level_name, fname: sessionStorage.getItem("userFname"), mname: "", lname: sessionStorage.getItem("userLname"),
            test_name: test_name, test_type: test_type, time_taken_abacus: "", time_taken_mm: "", total_que: total_que,
            total_ab_que: total_que, total_mm_que: "0", page: page, test_id: id,
            ...ans
        }
        console.log(obj);
        axios.post(url + "match_stud_ans", obj)
            .then((res) => {
                // console.log(res) 
                if (res.data.success) {
                    if (res.data.mail_sent === 1) {
                        toast.success("Test submitted");
                    } else toast.warning("Test submitted, but mail was not sent due to an error");
                    setValue({
                        count: res.data.count,
                        attemptedpercentage: res.data.attemptedpercentage,
                        notans: res.data.notans, overallpercentage: res.data.overallpercentage,
                        wrongans: res.data.wrongans,
                    })
                    setResult(true);
                    setShow(false);
                    setIsSubmitted(true);
                } else alert("Test not submitted");

            })
            .catch((err) => console.log(err));
    }

    //  This function is used to make normal Inputs into Controlled inputs and storing values into state variables
    const onChange1 = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setField1((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    //  This function is used to make normal Inputs into Controlled inputs and storing values into state variables
    const onChange2 = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setField2((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }
    //  This function is used to make normal Inputs into Controlled inputs and storing values into state variables
    const onChange3 = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setField3((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }


    // This function will show the Result in place of Submit button on test Submit -----
    function ShowResult() {
        return (
            <div className="row mb-4 ">
                <div className="col-12 text-center d-flex flex-column justify-content-center align-items-center bg-light py-1">
                    <h6>{"You have entered " + value.count + " correct answer(s)"}</h6>
                    <h6>{"You have entered " + value.wrongans + " Wrong answer(s)"}</h6>
                    <h6>{"Not answered " + value.notans + " questions"}</h6>
                    <h6>{"Attempt Percentage " + parseFloat(value.attemptedpercentage).toFixed(1).replace(/\.0$/, '') + "%"}</h6>
                    <h6>{"Overall Percentage " + parseFloat(value.overallpercentage).toFixed(1).replace(/\.0$/, '') + "%"}</h6>

                </div>
            </div>
        )
    }

    // ===== Saving a test Progress [Save as draft feature] ===========

    const saveProgress = () => {

        setLoading(true);

        // *********************************************************
        var role = sessionStorage.getItem("role");
        var location_name;
        var batch_name;

        if (role === "Student") {
            location_name = sessionStorage.getItem("location");
            batch_name = sessionStorage.getItem("batch");
        } else {
            location_name = "";
            batch_name = "";
        }

        // *********************************************************
        for (var i = 0; i < total_que; i++) {
            // Below "ans[]" Array is previously declared
            ans[`val${i + 1}`] = ANS_ARR[i] || ""
        }

        // ********************************************************* 
        const obj = {
            uname: sessionStorage.getItem("username"), location_name: location_name, batch_name: batch_name,
            level_name: level_name, fname: sessionStorage.getItem("userFname"), mname: "", lname: sessionStorage.getItem("userLname"),
            test_name: test_name, test_type: test_type, time_taken_abacus: "", time_taken_mm: "", total_que: total_que,
            total_ab_que: total_que, total_mm_que: "0", page: page, test_id: id, user_id: sessionStorage.getItem('userId'),
            ...ans

        }
        //   console.log(obj);  
        axios.post(url + "add_draft_assignment", obj)
            .then((res) => {
                //   console.log(res) 
                if (res.data.success) {
                    confirm({
                        title: "Saved Successfully",
                        description: "Your assignment is now saved as a draft, you can access it again from the 'My Tests' page.",
                        confirmationText: 'Close Test',
                        cancellationText: 'Continue'
                    }).then(() => navigate("/all-tests"));
                } else alert("Error occurred while saving the answers as draft")
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item">Home</li>
                        <li className="breadcrumb-item">Tests</li>
                        <li className="breadcrumb-item">My Tests</li>
                        <li className="breadcrumb-item active" aria-current="page">Assignment</li>
                    </ol>
                </nav>

                <Heading name={`Assignment : ${test_name}`} />

                {/* ---------------Modal popup for video iframe on click of test generate button----------------------- */}

                <Dialog open={open_iframe} disable
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                            // Set 'open' to false, however you would do that with your particular code.
                            setOpen_iframe(false);
                        }
                    }} BackdropClick maxWidth='md' fullWidth>
                    <DialogContent >
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item" title="1"
                                src={`https://www.youtube.com/embed/${yt_url}?rel=0&modestbranding=1`}
                                frameBorder="0" allow="autoplay;encrypted-media;"
                                allowFullScreen></iframe>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setOpen_iframe(false);
                            setShow(true);
                            toast.success("Test loaded successfully");
                            // setDate((Date.now()+ 1500000))
                        }} color='primary'>Close</Button>
                    </DialogActions>
                </Dialog>

                {/* ======================================================================== Test-Table --> Start here ====================================================================================== */}


                <div className="container-fluid mt-5">

                    <div >

                        {!isSubmitted ?
                            <div>
                                <div className="row mb-4">

                                    {sessionStorage.getItem('role') === "Student" &&
                                        <div className="row d-flex justify-content-between align-items-end mb-3">
                                            <AlertBlockForQuestionPapers />
                                            <Button className="col-auto" variant="contained" disabled={loading} onClick={saveProgress}>Save Progress</Button>
                                        </div>}
                                    <div className="col-12 mx-auto ">

                                        {/* -------------------------Accordion Tab to show Iframe video--------------------------- */}
                                        {yt_url !== "null" && <>
                                            <div className='w-100'>
                                                <Accordion>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content"  >
                                                        <h5 className="my-auto" style={{ color: "#174873" }}>{`${test_name} Video Help`}</h5></AccordionSummary>
                                                    <AccordionDetails className="d-flex justify-content-center align-items-center">
                                                        <div >
                                                            <iframe width="600" height="300" title="2"
                                                                src={`https://www.youtube.com/embed/${yt_url}`}
                                                                frameBorder="0" allow="autoplay;encrypted-media;"
                                                                allowFullScreen></iframe>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <hr className="p-0 mt-4 mx-auto" style={{ border: "none", borderBottom: ".4em dotted #174873", width: "10%" }} />
                                        </>}
                                        {/* --------------------------------------------------- */}

                                        <TableContainer component={Paper} >
                                            <Table>
                                                <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                    <TableRow>{head_id.table1.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}</TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {show && data.main_arr1.map(x => x.map((x, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell className="d-none" key={i} ></TableCell>)}
                                                            </TableRow>
                                                        )
                                                    }))}

                                                    <GenerateInputRow readOnly={false} from={1} to={10} length={total_que} onChange={onChange1} field={field1} />

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>

                                <div className="row my-5">
                                    <div className="col-12 mx-auto ">

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                    <TableRow>{head_id.table2.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}</TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {show && data.main_arr2.map(x => x.map((x, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell className="d-none" key={i} ></TableCell>)}
                                                            </TableRow>
                                                        )
                                                    }))}

                                                    <GenerateInputRow readOnly={false} from={11} to={20} length={total_que} onChange={onChange1} field={field1} />

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>


                                {total_que > 20 && <div className="row my-5">

                                    <div className="col-12 mx-auto ">

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                    <TableRow>

                                                        {head_id.table3.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {show && data.main_arr3.map(x => x.map((x, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell className="d-none" key={i} ></TableCell>)}
                                                            </TableRow>
                                                        )
                                                    }))}

                                                    <GenerateInputRow readOnly={false} from={21} to={30} length={total_que} onChange={onChange2} field={field2} />

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>}



                                {total_que > 30 && <div className="row my-5">

                                    <div className="col-12 mx-auto ">

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                    <TableRow>

                                                        {head_id.table4.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {show && data.main_arr4.map(x => x.map((x, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell className="d-none" key={i} ></TableCell>)}
                                                            </TableRow>
                                                        )
                                                    }))}

                                                    <GenerateInputRow readOnly={false} from={31} to={40} length={total_que} onChange={onChange2} field={field2} />

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>}


                                {total_que > 40 && <div className="row my-5">

                                    <div className="col-12 mx-auto ">

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                    <TableRow>

                                                        {head_id.table5.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {show && data.main_arr5.map(x => x.map((x, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell className="d-none" key={i} ></TableCell>)}
                                                            </TableRow>
                                                        )
                                                    }))}

                                                    <GenerateInputRow readOnly={false} from={41} to={50} length={total_que} onChange={onChange3} field={field3} />

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>}


                                {total_que > 50 && <div className="row my-5">

                                    <div className="col-12 mx-auto ">

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                    <TableRow>

                                                        {head_id.table6.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {show && data.main_arr6.map(x => x.map((x, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell className="d-none" key={i} ></TableCell>)}
                                                            </TableRow>
                                                        )
                                                    }))}

                                                    <GenerateInputRow readOnly={false} from={51} to={60} length={total_que} onChange={onChange3} field={field3} />

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>}

                            </div> : <div>

                                {/* ==================================================== After Submit test Part ===============================================================*/}
                                {/* ==================================================== After Submit test Part ===============================================================*/}
                                <div className="row my-4">
                                    <div className="col-12 ">
                                        <Button variant="contained" onCLick={() => navigate("/all-tests")} >Back To My Tests</Button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 mx-auto ">

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                    <TableRow>{head_id.table1.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}</TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {data.main_arr1.map(x => x.map((x, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell className="d-none" key={i} ></TableCell>)}
                                                            </TableRow>
                                                        )
                                                    }))}
                                                    <GenerateInputRow readOnly={true} from={1} to={10} length={total_que} answer={answer.answer1} field={field1} />

                                                    <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                        {result && answer.answer1.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>

                                <div className="row my-5">
                                    <div className="col-12 mx-auto ">

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                    <TableRow>{head_id.table2.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}</TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {data.main_arr2.map(x => x.map((x, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell className="d-none" key={i} ></TableCell>)}
                                                            </TableRow>
                                                        )
                                                    }))}
                                                    <GenerateInputRow readOnly={true} from={11} to={20} length={total_que} answer={answer.answer2} field={field1} />

                                                    <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                        {result && answer.answer2.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>


                                <div className="row my-5">

                                    <div className="col-12 mx-auto ">

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                    <TableRow>

                                                        {head_id.table3.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {data.main_arr3.map(x => x.map((x, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell className="d-none" key={i} ></TableCell>)}
                                                            </TableRow>
                                                        )
                                                    }))}
                                                    <GenerateInputRow readOnly={true} from={21} to={30} length={total_que} answer={answer.answer3} field={field2} />

                                                    <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                        {result && answer.answer3.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>


                                <div className="row my-5">

                                    <div className="col-12 mx-auto ">

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                    <TableRow>

                                                        {head_id.table4.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {data.main_arr4.map(x => x.map((x, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell className="d-none" key={i} ></TableCell>)}
                                                            </TableRow>
                                                        )
                                                    }))}
                                                    <GenerateInputRow readOnly={true} from={31} to={40} length={total_que} answer={answer.answer4} field={field2} />

                                                    <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                        {result && answer.answer4.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>


                                <div className="row my-5">

                                    <div className="col-12 mx-auto ">

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                    <TableRow>

                                                        {head_id.table5.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {data.main_arr5.map(x => x.map((x, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell className="d-none" key={i} ></TableCell>)}
                                                            </TableRow>
                                                        )
                                                    }))}
                                                    <GenerateInputRow readOnly={true} from={41} to={50} length={total_que} answer={answer.answer5} field={field3} />

                                                    <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                        {result && answer.answer5.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>

                                <div className="row my-5">

                                    <div className="col-12 mx-auto ">

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                    <TableRow>

                                                        {head_id.table6.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {data.main_arr6.map(x => x.map((x, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell className="d-none" key={i} ></TableCell>)}
                                                            </TableRow>
                                                        )
                                                    }))}
                                                    <GenerateInputRow readOnly={true} from={51} to={60} length={total_que} answer={answer.answer6} field={field3} />

                                                    <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                        {result && answer.answer6.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>

                            </div>}

                        {/* after clicking of above submit button below  Result will be shown on the screen  and above submit button will get dissappear*/}
                        {result && (<ShowResult />)}

                        {show && (<div className="row mb-4">
                            <div className="col-12 mx-auto d-flex justify-content-end ">
                                <Button variant="contained" onClick={() => {

                                    if (sessionStorage.getItem("role") === "Student" || sessionStorage.getItem("role") === "Systemadmin") {
                                        confirm({
                                            title: "Submit Confirmation",
                                            description: "Are you sure, you want to submit the Assignment?",
                                        }).then(() => onSubmitTest());
                                    } else toast.error("Only students can submit the test");

                                }} className="btn btn-success ">Submit</Button>
                            </div>
                        </div>)
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default AssignmentSheetComp;
