import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../../../Common/Heading";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../../Common/LoadingSpinner";

import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import MaterialReactTable from 'material-react-table';

import axios from "axios";
import url from "../../../../Common/URL";
import { useConfirm } from 'material-ui-confirm';
import { Button, IconButton } from "@mui/material";
import { useSelector } from "react-redux";


const LocationTable = () => {
  const confirm = useConfirm();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
  });

  const Columns = useMemo(
    () => [
      { header: 'Location Name', accessorKey: 'name' },
      { header: 'Address Line 1', accessorKey: 'addressline1', size: 180 },
      { header: 'Address Line 2', accessorKey: 'addressline2' },
      { header: "Province", accessorKey: 'state' },
      { header: 'Country', accessorKey: 'country' },
      { header: "Location Manager", accessorFn: rowData => `${rowData.fname} ${rowData.lname}` },
    ],
    [],
  );


  // ------------------------Fetching Location table's data-------------------------------------
  useEffect(() => {
    loadLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadLocations = async () => {
    const obj = { uname: state.uname };
    await axios.post(url + "fetch_locations", obj)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          let a = response.data.data;
          console.log(a);
         !a.length ? setData([]) : setData(a);
          setLoading(false);
        } else {
          setData([]);
          console.log(response);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  // ------------------------Deleting Location table's data--------------------------

  const onDelete = (id, resolve) => {
    const obj = {
      id: id,
      uname: state.uname,
      tokenId: 123,
    };
    console.log(obj);
    axios.post(url + "delete_location", obj)
      // .then((res) => console.log(res))
      .then((res) => {
        loadLocations();
        resolve();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item active" aria-current="page">Location List</li>
          </ol>
        </nav>

        <Heading name="Location List" />

        <div className="container-fluid">
          <div className="row my-4">
            <div className="col-12 mx-auto d-flex justify-content-end">
              <Button variant="contained" onClick={() => navigate("/add-location")} >Add Location <Add /></Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mx-auto">

              <MaterialReactTable
                state={{ isLoading: loading }}

                columns={Columns}
                data={data}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                getRowId={(originalRow) => originalRow.location_id}

                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/view-location/" + row.id),
                  sx: { cursor: 'pointer' },
                })}

                renderTopToolbarCustomActions={({ table }) => (
                  <IconButton
                    disabled={table.getSelectedRowModel().rows.length ? false : true}
                    onClick={() => {
                      confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                        .then(() => {
                          let selectedRows = table.getSelectedRowModel().rows;
                          // console.log(selectedRows);
                          const delPromise = selectedRows.map((row) => {
                            return new Promise((resolve, reject) => {
                              onDelete(row.id, resolve);
                            });
                          });
                          Promise.all(delPromise).then(() => {
                            loadLocations();
                            table.resetRowSelection();
                            toast.info(selectedRows.length + " Row(s) Deleted!");
                          });
                        });
                    }}
                  ><DeleteOutline />
                  </IconButton>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LocationTable;