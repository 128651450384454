import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import Heading from "../../../Common/Heading";
import Input from "../../Setup/Student/Component/Input";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

import Add from "@mui/icons-material/Add";

import axios from "axios";
import url from "../../../Common/URL";

import { useConfirm } from 'material-ui-confirm';

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";


const TimesheetComp = () => {

  const confirm = useConfirm();

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [proj, setProj] = useState([]);
  const [task, setTask] = useState([]);

  const [updateLoading, setUpdateLoading] = useState(false);

  const [weekstatus, setWeekstatus] = useState("");

  const [state, setState] = useState({
    workdate: "",
    work_day: "",
    work_hours: "",
    user_id: "",
    project: "",
    task: "",
    status: "",
    update_id: "",
    form_type: "",
    // fname: "",
    // lname: "",
    // location_name: [],
    // batch_name: [],
    // level_name: [],
  });


  var Columns = useMemo(
    () => sessionStorage.getItem("role") !== "Teacher" ? [
      { header: "First Name", accessorKey: "fname" },
      { header: "Last Name", accessorKey: "lname" },
      { header: "Location", id: 'location_name', accessorFn: (rowData) => rowData.location_name.split(",").join(", ") },
      { header: "Batch", id: 'batch_name', accessorFn: (rowData) => rowData.batch_name.split(",").join(", ") },
      { header: "Level", id: 'level_name', accessorFn: (rowData) => rowData.level_name.split(",").join(", ") },
    ] : [
      { header: "Project", accessorKey: "project" },
      { header: "Task", accessorKey: "task" },
      { header: "MON", id: 'Mon', accessorFn: (rowData) => (rowData.work_day.includes("Mon") ? rowData.work_hours : "--") },
      { header: "TUE", id: 'Tue', accessorFn: (rowData) => (rowData.work_day.includes("Tue") ? rowData.work_hours : "--") },
      { header: "WED", id: 'Wed', accessorFn: (rowData) => (rowData.work_day.includes("Wed") ? rowData.work_hours : "--") },
      { header: "THU", id: 'Thu', accessorFn: (rowData) => (rowData.work_day.includes("Thu") ? rowData.work_hours : "--") },
      { header: "FRI", id: 'Fri', accessorFn: (rowData) => (rowData.work_day.includes("Fri") ? rowData.work_hours : "--") },
      { header: "SAT", id: 'Sat', accessorFn: (rowData) => (rowData.work_day.includes("Sat") ? rowData.work_hours : "--") },
      { header: "SUN", id: 'Sun', accessorFn: (rowData) => (rowData.work_day.includes("Sun") ? rowData.work_hours : "--") },
    ],
    [],
  );


  const [data, setData] = useState([]);
  const [week, setWeek] = useState({
    firstday: "",
    lastday: "",
    currentWeekLast: "",
  });


  useEffect(() => {
    if (sessionStorage.getItem("role") === "Teacher") {
      loadTimesheets();
      LoadProjects();
      LoadTasks();
      // loadUserData();
      // TeacherDetail();
    } else {
      loadStaff(); // This will help Admin to select any teacher and ascess its data
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -----------This function will Fetch the User's Fname & Lname--------------------

  // const loadUserData = () => {
  //   const obj = { uname: sessionStorage.getItem("username") };
  //   axios
  //     .post(url + "select_profile", obj)
  //     .then((response) => {
  //       //   console.log(response);
  //       let resData = response.data.data;
  //       //   console.log(resData)
  //       setState((pre) => {
  //         return {
  //           ...pre,
  //           fname: resData[0].first_name,
  //           lname: resData[0].last_name,
  //         };
  //       });
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const TeacherDetail = () => {
  //   axios
  //     .post(url + "select_teacher_params", { uname: sessionStorage.getItem("username") })
  //     .then((res) => {
  //       // console.log(res);
  //       if (res.data.success) {
  //         var batch_name_arr = JSON.parse(res.data.batch_data).map((a) => a.batch_name);
  //         var location_name_arr = JSON.parse(res.data.location_data).map((a) => a.location_name);
  //         var level_name_arr = JSON.parse(res.data.level_data).map((a) => a.level_name);

  //         console.log(batch_name_arr);
  //         console.log(location_name_arr);
  //         console.log(level_name_arr);
  //         setState((pre) => {
  //           return {
  //             ...pre,
  //             // location_name: location_name_arr.map((x) => ({ label: x, value: x })),
  //             // batch_name: batch_name_arr.map((x) => ({ label: x, value: x })),
  //             // level_name: level_name_arr.map((x) => ({ label: x, value: x })),
  //             location_name: location_name_arr,
  //             batch_name: batch_name_arr,
  //             level_name: level_name_arr,
  //           };
  //         });
  //       } else {
  //         alert("An error occurred, Location, Level & Batch can't be fetched.");
  //       }
  //     })
  //     .catch((res) => console.log(res));
  // };

  const found = (arr1, arr2) => {
    return arr1.some(r => arr2.indexOf(r) >= 0);
  };

  // ***********************************************************************************************************************************
  // *************************************** Role === [SuperAdmin ,  Admin , Location Manager] *******************************************
  const loadStaff = () => {
    const obj = { uname: sessionStorage.getItem("username") };
    axios
      .post(url + "teachers", obj)
      .then((response) => {
        if (response.data.success) {
          let a = response.data.data;

          if (sessionStorage.getItem("role") === "Location Manager") {
            let loc = sessionStorage.getItem("location").toLowerCase().split(",");

            let arr = a.filter((x) => found(x.location_name.toLowerCase().split(","), loc));
            arr === null ? setData([]) : setData(arr);
            setLoading(false);
          } else {
           !a.length ? setData([]) : setData(a);
            setLoading(false);
          }
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  // ------------------------Fetching Timesheets -------------------------------------

  const loadTimesheets = async () => {
    let curr = new Date();
    let week = [];

    for (let i = 1; i <= 7; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
      week.push(day);
    }

    setWeek({ ...week, firstday: week[0], lastday: week[6], currentWeekLast: week[6] });

    const obj = {
      uname: sessionStorage.getItem("username"),
      user_id: sessionStorage.getItem("id"),
      start_date: week[0],
      end_date: week[6],
    };

    // console.log(obj);

    await axios
      .post(url + "timesheet", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
          if (!a.length) {
            setWeekstatus("");
            setData([]);
          } else {
            let status = a.every((x) => x.status === "true");
            setWeekstatus(status);
           !a.length ? setData([]) : setData(a);
          }
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  // ============================================================================

  const PreviousWeekData = () => {
    setLoading(true);

    let startDate, endDate, midCalculation;

    let ed = new Date(week.firstday);
    ed.setDate(ed.getDate() - 1);
    endDate = ed.toISOString().slice(0, 10);

    midCalculation = new Date(week.firstday);
    midCalculation.setDate(midCalculation.getDate() - 7);

    startDate = midCalculation.toISOString().slice(0, 10);

    setWeek({ ...week, firstday: startDate, lastday: endDate });

    // ===================================================================

    const obj1 = {
      uname: sessionStorage.getItem("username"),
      user_id: sessionStorage.getItem("id"),
      start_date: startDate,
      end_date: endDate,
    };

    axios
      .post(url + "timesheet", obj1)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
          if (!a.length) {
            setWeekstatus("");
            setData([]);
          } else {
            let status = a.every((x) => x.status === "true");
            setWeekstatus(status);
           !a.length ? setData([]) : setData(a);
          }
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  // ============================================================================

  const NextWeekData = () => {
    setLoading(true);

    let startDate, endDate, midCalculation;

    let sd = new Date(week.lastday);
    sd.setDate(sd.getDate() + 1);
    startDate = sd.toISOString().slice(0, 10);

    midCalculation = new Date(week.lastday);
    midCalculation.setDate(midCalculation.getDate() + 7);

    endDate = midCalculation.toISOString().slice(0, 10);

    setWeek({ ...week, firstday: startDate, lastday: endDate });

    // ===================================================================

    const obj2 = {
      uname: sessionStorage.getItem("username"),
      user_id: sessionStorage.getItem("id"),
      start_date: startDate,
      end_date: endDate,
    };

    axios
      .post(url + "timesheet", obj2)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
          if (!a.length) {
            setWeekstatus("");
            setData([]);
          } else {
            let status = a.every((x) => x.status === "true");
            setWeekstatus(status);
           !a.length ? setData([]) : setData(a);
          }
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% Modal Open Close Logic %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  // ------------------------Fetching Projects ------------------------------------

  const LoadProjects = () => {
    const obj = { uname: sessionStorage.getItem("username") };
    axios
      .post(url + "fetch_projects", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
         !a.length ? setProj([]) : setProj(a);
        } else {
          setProj([]);
        }
      })
      .catch((err) => console.log(err));
  };

  // ------------------------Fetching Tasks-------------------------------------

  const LoadTasks = () => {
    const obj = { uname: sessionStorage.getItem("username") };
    axios
      .post(url + "fetch_tasks", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
         !a.length ? setTask([]) : setTask(a);
        } else {
          setTask([]);
        }
      })
      .catch((err) => console.log(err));
  };

  //---------------One onChange function is used for setting the state of various types of input fields ---------------

  const onChange = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.type === "checkbox" ? input.checked : input.value;

    setState((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  //-------------------------- onSubmit function for Submitting the  Forms---------------------------

  const onSubmit = async (e) => {
    e.preventDefault();
    setUpdateLoading(true);

    if (state.form_type === "add") {
      // ------------Storing form data in "obj" object fetched from various input fields-------------
      const obj = {
        uname: sessionStorage.getItem("username"),
        user_id: sessionStorage.getItem("id"),
        workdate: state.workdate,
        work_day: state.work_day,
        work_hours: state.work_hours,
        project: state.project,
        task: state.task,
        status: state.status,
        created_by: sessionStorage.getItem("username"),

        // fname: state.fname,
        // lname: state.lname,
        // location_name: state.location_name,
        // batch_name: state.batch_name,
        // level_name: state.level_name,
      };
      // ------------Sending form data stored in "obj" object to the add_student file uusing AXIOS-------------
      // console.log(obj);

      await axios
        .post(url + "add_timesheet", obj)
        .then((res) => {
          // console.log(res);
          if (res.data.success) {
            loadTimesheets();
            toast.success("Timesheet Created successfully");

            setOpen(false);
          } else {
            alert("Timesheet Not created, Please try again");

            setOpen(false);
          }
          setState({
            workdate: "",
            work_day: "",
            work_hours: "",
            user_id: "",
            project: "",
            task: "",
            status: "",
            update_id: "",
            form_type: "",
          });
        })
        .catch((err) => {
          console.log(err);
          setUpdateLoading(false);
        });
    } else {
      // ------------Storing form data in "obj" object fetched from various input fields-------------
      const obj = {
        uname: sessionStorage.getItem("username"),
        id: state.update_id,
        user_id: sessionStorage.getItem("id"),
        workdate: state.workdate,
        work_day: state.work_day,
        work_hours: state.work_hours,
        project: state.project,
        task: state.task,
        status: state.status,
        updated_by: sessionStorage.getItem("username"),
        // fname: state.fname,
        // lname: state.lname,
        // location_name: state.location_name,
        // batch_name: state.batch_name,
        // level_name: state.level_name,
      };
      // console.log(obj);
      // ------------Sending form data stored in "obj" object to the add_student file uusing AXIOS-------------

      await axios
        .post(url + "edit_timesheet", obj)
        .then((res) => {
          // console.log(res)
          if (res.data.success) {
            loadTimesheets();
            toast.success("Updated successfully!");

            setOpen(false);
          } else {
            alert("Data not updated, Please try again");

            setOpen(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setUpdateLoading(false);
        });
    }

    setUpdateLoading(false);
  };

  const AddForm_Modal = () => {
    setState({
      ...state,
      workdate: "",
      work_day: "",
      work_hours: "",
      user_id: "",
      project: "",
      task: "",
      status: "",
      update_id: "",
      form_type: "add",
    });

    setOpen(true);
  };

  const EditForm_Modal = (data) => {
    setState({
      ...state,
      update_id: data.time_id,
      project: data.project,
      task: data.task,
      workdate: data.workdate,
      work_day: data.work_day,
      work_hours: data.work_hours,
      form_type: "update",
    });
    setOpen(true);
  };

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% Modal Open Close Logic %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  return (
    <>
      {/* ############################################ Modal for Uploading Attachments ################################################## */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Update</DialogTitle>
        <DialogContent>
          <div className="mb-3 row">
            <label className="col-sm-5 col-form-label red-asterisk">Project Name</label>
            <div className="col-md-7 col-sm-7">
              <select
                name="project"
                value={state.project}
                onChange={onChange}
                className="form-select"
                required>
                <option value="">Choose</option>
                {proj.map((x, i) => (
                  <option key={i} value={x.project_name}>
                    {x.project_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-5 col-form-label red-asterisk">Task Name</label>
            <div className="col-md-7 col-sm-7">
              <select name="task" value={state.task} onChange={onChange} className="form-select"
                required>
                <option value="">Choose</option>
                {task
                  .filter((x) => x.project_name === state.project)
                  .map((x, i) => (
                    <option key={i} value={x.task_name}>
                      {x.task_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <Input
            name="workdate"
            onChange={(e) => {
              var date = e.target.value;
              var day = new Date(date.toString()).toLocaleString("en-us", { weekday: "long" });
              // console.log(day);
              // console.log(date);
              setState({ ...state, work_day: day, workdate: date });
            }}
            value={state.workdate}
            field="Date"
            type="date"
            className="form-control"
            required={true}
          />

          <Input name="work_hours" onChange={onChange} placeholder="E.g. : 2" value={state.work_hours} field="Hours Consumed" type="number" className="form-control" required={true} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            Close
          </Button>

          <Button color="primary" onClick={onSubmit}>
            {!updateLoading ? "Save" : <CircularProgress size={20} />}
          </Button>
        </DialogActions>

      </Dialog>


      {/* ############################################################### */}

      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item">
              <Link to="/bulletin">Home</Link>
            </li>
            <li className="breadcrumb-item">Timesheet</li>
            {/* <li className="breadcrumb-item active" aria-current="page">Time Sheet</li> */}
          </ol>
        </nav>

        <Heading name="Timesheet" />

        <div className="container-fluid mb-5">
          {sessionStorage.getItem("role") === "Teacher" && (
            <div className="row mb-4">
              <div className="col-12 mx-auto d-flex">
                <Button variant="contained" onClick={PreviousWeekData} >
                  <NavigateBeforeIcon /> Previous Week
                </Button>
                <Button variant="contained"
                  onClick={NextWeekData}
                  disabled={week.lastday === week.currentWeekLast ? true : false}
                  className="ms-3">
                  Next Week <NavigateNextIcon />
                </Button>
                {/* <Link to="/add-timesheet" className=" ms-auto ">Add Update <Add /></Link> */}
                <Button variant="contained" onClick={AddForm_Modal} className=" ms-auto ">
                  Add Update <Add />
                </Button>
              </div>
            </div>
          )}
          {(sessionStorage.getItem("role") !== "Teacher" && sessionStorage.getItem("role") !== "Location Manager") && (
            <div className="row mb-4">
              <div className="col-12  ">
                <Button variant="contained"
                  onClick={() => navigate("/global-timesheet-summary")}
                  className="ms-auto">
                  Global Summary
                </Button>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12 mx-auto">

              <MaterialReactTable
                state={{ isLoading: loading }}
                columns={Columns}
                data={data}
                enableRowNumbers
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                getRowId={(originalRow) => originalRow.teacher_id}
                muiTableProps={{
                  sx: {
                    tableLayout: 'fixed',
                  },
                }}

                renderTopToolbarCustomActions={() => (
                  <Typography variant="h6">{sessionStorage.getItem("role") !== "Teacher" ? "" : `[ ${week.firstday} - ${week.lastday} ]`}</Typography>
                )}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => {
                    // console.log(row);
                    sessionStorage.getItem("role") === "Teacher"
                      ? EditForm_Modal(row.original)
                      : navigate("/review-timesheet/" + row.id);
                  },
                  sx: { cursor: 'pointer' },
                })}
              />
            </div>
          </div>


          {!loading && sessionStorage.getItem("role") === "Teacher" && (
            <div className="row mt-4">
              <div className="col-11 col-md-6 mx-auto rounded">
                {data.length !== 0 ? (
                  weekstatus === true ? (
                    <div className="d-flex justify-content-center alert alert-success  align-items-center border-0">
                      <h6 className=" m-0 d-flex align-items-center">
                        <CheckIcon />
                        &nbsp;This week's timesheet has been approved.
                      </h6>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center alert alert-danger  align-items-center border-0">
                      <h6 className=" m-0 d-flex align-items-center">
                        <ClearIcon />
                        &nbsp;This week's timesheet hasn't been approved.
                      </h6>
                    </div>
                  )
                ) : (
                  <div className="d-flex justify-content-center alert alert-danger  align-items-center border-0">
                    <h6 className=" m-0 d-flex align-items-center">
                      <PriorityHighIcon />
                      &nbsp;Empty weekly timesheet can't be approved.
                    </h6>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default TimesheetComp;
