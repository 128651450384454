import React, { useState, useEffect } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Heading from "../../../Common/Heading"
import { ReadonlyInput } from "../../Setup/Student/Component/Input"
import axios from "axios"
import url from "../../../Common/URL"
import { Button } from "@mui/material";

import { useConfirm } from 'material-ui-confirm';
import { useSelector } from "react-redux"


const ViewUpdateComp = () => {

    const confirm = useConfirm();
    const navigate = useNavigate();
    const { id } = useParams();

    const [field, setField] = useState({
        workdate: "", work_day: "", work_hours: "", user_id: "", project: "",
        task: "", status: ""
    })



    useEffect(() => {
        loadUpdate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadUpdate = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        }
        axios.post(url + "select_timesheet", obj)
            .then((response) => {
                let resData = response.data.data;
                // console.log(resData);
                setField({
                    workdate: resData[0].workdate, work_day: resData[0].work_day, work_hours: resData[0].work_hours,
                    user_id: resData[0].user_id, project: resData[0].project,
                    task: resData[0].task, status: resData[0].status
                })
            })
            .catch((err) => console.log(err));

    }

    // ------------------------Deleting Location table's data--------------------------

    const onDelete = () => {

        confirm({ title: "Delete Confirmation", description: 'This will delete this location permanently.' })
            .then(() => {
                const delObj = {
                    id: id,
                    uname: sessionStorage.getItem("username"),
                    tokenId: 123,
                };
                axios.post(url + "delete_timesheet", delObj)
                    .then((res) => {
                        //   console.log(res);
                        navigate("/timesheet");
                        toast.info("Update deleted");
                    })
                    .catch((err) => console.log(err));
            })
    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/timesheet">Timesheet</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">View Update</li>
                    </ol>
                </nav>
                <Heading name={`View Update`} />

                <div className="container-fluid">

                    <div className="row mb-4">
                        <div className="col-12 col-md-12 d-flex justify-content-between align-items-end ">
                            <h5>Update Information</h5>
                            <div>
                                <Button variant="contained" onClick={onDelete} >Delete</Button>
                                <Button onClick={() => navigate("/ts-edit-update/" + id)} className="ms-3">Edit</Button>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-11 col-md-7 mx-auto mx-md-0">

                            <ReadonlyInput field="Project Name"  className="form-control bg-light border-0 " value={field.project} />

                            <ReadonlyInput field="Task Name"  className="form-control bg-light border-0 " value={field.task} />


                            <ReadonlyInput field="Day of Week"  className="form-control bg-light border-0 " value={field.work_day} />

                            {/* ---Select Input---- */}
                            <ReadonlyInput field="Work Date"  className="form-control bg-light border-0 " value={field.workdate} />

                            {/* ---Select Input---- */}
                            <ReadonlyInput field="Hours Consumed"  className="form-control bg-light border-0 " value={field.work_hours} />

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewUpdateComp
