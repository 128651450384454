import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Heading from "../../../../Common/Heading";
// import {toast} from "react-toastify"

import axios from "axios";
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
import url from "../../../../Common/URL";

const HistoryTableComp = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [state] = useState({
    uname: sessionStorage.getItem("username"),
  })

  const Columns = useMemo(
    () => [
      { header: 'First Name', accessorKey: 'fname' },
      { header: 'Last Name', accessorKey: 'lname' },
      { header: 'Email', accessorKey: 'email' },
      { header: 'Status', accessorKey: 'active', lookup: { 1: 'Active', 0: 'Inactive' } },
      { header: 'Location', accessorKey: 'location_name' },
      { header: 'Batch', accessorKey: 'batch_name' },
      { header: 'Level', accessorKey: 'level_name' },
    ],
    [],
  );


  const loadHistory = () => {
    const obj = { uname: state.uname, id: id }
    axios.post(url + "teacher_history", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
         !a.length ? setData([]) : setData(a);
        } else {
          setData([]);
        }
      })
      .catch((err) => console.log(err));
  }

  // ------------------------Fetching Teacher table's data-------------------------------------
  useEffect(loadHistory, []);


  // ------------------------Deleting Teacher table's data-------------------------------------

  //     const onDelete=(id)=> {
  //       const delObj = {
  //         id: id,
  //         uname: state.uname,
  //         tokenId: 123,
  //       };
  //         axios.post(url+"delete_teacher", delObj)
  //             .then((res) => console.log(res))
  //             .catch((err) => console.log(err));
  //     }
  // ------------------------Custom Theme in table------------------------------------

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item" ><Link to="/teacher">Teachers list</Link></li>
            <li className="breadcrumb-item"><Link to={"/view-teacher/" + id}>View Teacher</Link></li>
            <li className="breadcrumb-item active" aria-current="page">History Log</li>
          </ol>
        </nav>
        <Heading name="History log" />

        <div className="container-fluid mb-5">
          {/* <div className="row my-4">
                <div className="col-12 mx-auto d-flex justify-content-end ">
                    <Link to="/add-teacher" >Add Teacher <Add /></Link>
                </div>
            </div> */}
          <div className="row">
            <div className="col-12 mx-auto">

              <MaterialReactTable
              
                columns={Columns}
                data={data}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                
                getRowId={(originalRow) => originalRow.teacher_history_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/tch-history-data/" + row.id),
                  sx: { cursor: 'pointer' },
                })}
              />
            </div>
          </div>
        </div>
      </div>

    </>
  )

}
export default HistoryTableComp




