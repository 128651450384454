import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import axios from "axios";
import url from "../../Common/URL"
import "./../login.css";

import { toast } from "react-toastify";
import { Button } from "@mui/material";

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Dialog, DialogContent } from "@mui/material";
// #makeStyles

// const useStyles = makeStyles((theme) => ({
//     modal: {
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//     },
//     paper: {
//         backgroundColor: theme.palette.background.paper,
//         border: '5px solid #e89801',
//         borderRadius: "10px",
//         boxShadow: theme.shadows[5],
//         padding: theme.spacing(5, 4, 3),
//     },
// }));

const Reset_pass_comp = () => {
    // const classes = useStyles();
    const urlParams = new URLSearchParams(useLocation().search);

    const [showpass, setShowpass] = useState(false);
    const [show_confm_pass, setShow_confm_pass] = useState(false);

    const key = urlParams.get('key');
    const email = urlParams.get('email');
    const action = urlParams.get('action');

    // console.log(key , email , action);


    const [open, setOpen] = useState(false);
    const [done, setDone] = useState(true);
    const [showform, setShowform] = useState(false);
    const [warning, setWarning] = useState(false);

    const [state, setState] = useState({
        password: "",
        re_password: "",
    });

    const getCurdate = () => {
        let date_ob = new Date();
        // adjust 0 before single digit date
        let date = ("0" + date_ob.getDate()).slice(-2);
        // current month
        let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
        // current year
        let year = date_ob.getFullYear();
        // current hours
        let hours = date_ob.getHours();
        // current minutes
        let minutes = date_ob.getMinutes();
        // current seconds
        let seconds = date_ob.getSeconds();
        // prints date & time in YYYY-MM-DD HH:MM:SS format
        const curDate = year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
        return curDate;
    }

    const Exp_date = () => {
        const obj = {
            key: key, email: email,
        }
        axios.post(url + "select_password_token", obj)
            .then((response) => {
                let resData = response.data.data;
                // console.log(resData);
                var expDate = resData[0].expDate;
                var curDate = getCurdate();

                var expDateUpdated = new Date(expDate.replace(/-/g, '/'));
                // console.log(expDateUpdated)
                var curDateUpdated = new Date(curDate.replace(/-/g, '/'));
                // console.log(curDateUpdated)

                if (expDateUpdated > curDateUpdated) {
                    setShowform(true);
                } else {
                    setWarning(true);
                }

            })
            .catch((err) => console.log(err));
    }

    useEffect(Exp_date, [])

    const onChange = (e) => {

        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (state.password !== state.re_password) {
            toast.error("Password confirmation doesn't match Password")
        } else {
            /*---------------Creating object to send data through Axios------------------------- */
            const obj = {
                pass1: state.password, email: email
            }
            axios.post(url + "php/reset_user.php", obj)
                .then((res) => {
                    // console.log(res) ;
                    if (res.data.success) {
                        setOpen(true);
                        setDone(false);
                    } else {
                        console.log(res);
                        alert("Password Not reset")
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    return (
        <>

            {/* <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h4 className="text-center" id="transition-modal-title">Success!</h4>
                        <p id="transition-modal-description">Your password has been changed successfully.</p>
                    </div>
                </Fade>
            </Modal> */}

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <h4 className="text-center" id="transition-modal-title">Success!</h4>
                    <p id="transition-modal-description">Your password has been changed successfully.</p>
                </DialogContent>

            </Dialog>


            {showform &&
                <div style={{ height: "50vh" }} className="card col-11 col-md-4  pt-3 px-4 px-md-5 shadow mx-auto login mb-5 border-0">
                    {done ?
                        <> {
                            action === "reset" ? <h2 className="text-center  mt-2 head">Reset Password</h2> :
                                <h2 className="text-center  mt-2 head">Set Password</h2>}

                            <hr style={{ border: "0px", borderBottom: "3px dotted #E89801" }} className="w-25 mx-auto mt-2" />

                            <form onSubmit={onSubmit}>
                                <div className="mb-3 mt-2">
                                    {/* <label htmlFor="exampleInputEmail1">Email address</label> */}
                                    <div className="mb-3 text-left">

                                        <Input
                                            required
                                            disableUnderline={true}
                                            type={showpass ? "text" : "password"}
                                            className="form-control shadow-sm border-0 "
                                            id="password"
                                            value={state.password}
                                            placeholder="Enter New Password"
                                            onChange={onChange}
                                            autoComplete="off"
                                            name="password"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowpass(() => !showpass)}
                                                        onMouseDown={(e) => e.preventDefault()}
                                                    >
                                                        {showpass ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                    <div className="mb-3 text-left">
                                        <Input
                                            required
                                            disableUnderline={true}
                                            type={show_confm_pass ? "text" : "password"}
                                            className="form-control shadow-sm border-0 "
                                            id="password"
                                            value={state.re_password}
                                            placeholder="Confirm Password"
                                            onChange={onChange}
                                            autoComplete="off"
                                            name="re_password"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShow_confm_pass(() => !show_confm_pass)}
                                                        onMouseDown={(e) => e.preventDefault()}
                                                    >
                                                        {show_confm_pass ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </div>

                                    <Button variant="contained" type="submit" className=" mb-3 ">{action === "reset" ? "Reset Your Password" : "Set Your Password"}</Button>

                                </div>
                            </form></> :
                        <div >
                            <h2 className="text-center mt-2 head">Success!</h2>
                            <hr style={{ border: "0px", borderBottom: "3px dotted #E89801" }} className="w-25 mx-auto" />
                            {/* <h4 className="text-center mt-4" id="transition-modal-title">Congratulations!</h4> */}
                            <p id="transition-modal-description" className="text-center mt-4">Your password has been changed successfully.</p>

                            <div className="text-center mt-4">
                                <p className="mb-2">Please login below.</p>
                                <NavLink to="/">Login</NavLink>
                            </div>
                        </div>}

                </div>}

            {warning && <div style={{ height: "auto" }} className="card col-11 col-md-8  pt-3 px-4 px-md-5 shadow mx-auto login mb-5">
                <h6 className="mb-3">Invalid Link !!! The link is invalid/expired. Either you did not copy the correct link from the email,
                    or you have already used the key in which case it is deactivated.</h6>
                <p>Click below button to reset your password.</p>
                <div className="text-center">
                    <NavLink className=" mb-3" to="/forget-pass">Reset Password</NavLink>
                </div>
            </div>}

        </>
    )
}
export default Reset_pass_comp;



