import React, { useState, useEffect } from "react"
import Input from "../../../Student/Component/Input"
import { toast } from "react-toastify"
import { Link, useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom";
import Heading from "../../../../../Common/Heading"
import axios from "axios"
import url from "../../../../../Common/URL"
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const TsTaskEditComp = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [proj, setProj] = useState([]);

    const [field, setField] = useState({
        uname: sessionStorage.getItem("username"),
        task_name: "", project_id: "",
        task_desc: "", project_name: ""
    })

    const SelectTask = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        }
        axios.post(url + "fetch_single_task", obj)
            .then((response) => {
                let resData = response.data.data;
                // console.log(resData);
                setField({
                    task_name: resData[0].task_name, project_id: resData[0].project_id,
                    task_desc: resData[0].task_desc, project_name: resData[0].project_name
                })
            })
            .catch((err) => console.log(err));

    }


    useEffect(() => {
        SelectTask();
        LoadProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const LoadProjects = () => {
        const obj = { uname: sessionStorage.getItem("username") }
        axios.post(url + "fetch_projects", obj)
            .then((response) => {
                if (response.data.success) {
                    let a = response.data.data;
                   !a.length ? setProj([]) : setProj(a);
                } else {
                    setProj([]);
                }
            }).catch((err) => console.log(err));
    }

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setField((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        const obj = {
            uname: sessionStorage.getItem("username"), id: id,
            task_name: field.task_name, updated_by: sessionStorage.getItem("username"),
            task_desc: field.task_desc, project_id: field.project_id, project_name: field.project_name
        }
        axios.post(url + "edit_task", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    SelectTask();
                    toast.success("Updated successfully!")
                } else {
                    alert("Error occurred, please try again later.")
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            })
    }
    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item">Timesheet</li>
                        <li className="breadcrumb-item active"><Link to={`/ts-task`}>Tasks</Link></li>
                        <li className="breadcrumb-item active"><Link to={`/view-ts-task/${id}`}>View Task</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Edit Task</li>
                    </ol>
                </nav>

                <Heading name={`Edit Task`} />

                <div className="container-fluid">

                    <form onSubmit={onSubmit}>

                        <div className="row mb-4">
                            <div className="col-12 d-flex justify-content-between align-items-end ">
                                <h5>Task Information</h5>
                                <div>
                                    <Button variant="contained" onClick={() => navigate("/view-ts-task/" + id)} >Back</Button>
                                    <Button variant="contained" type="submit" disabled={loading} className="ms-3">Save</Button>
                                </div>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-12 col-md-6 mx-auto mx-md-0">


                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Project</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select name="project_id"
                                            value={`${field.project_id}*${field.project_name}`}
                                            onChange={(e) => {
                                                let id, name;
                                                let data = e.target.value;
                                                id = data.split("*")[0];
                                                name = data.split("*")[1];
                                                setField({ ...field, project_id: id, project_name: name });
                                            }} className="form-select" required>
                                            <option value="">Choose</option>
                                            {proj.map((data, i) => (<option key={i} value={`${data.project_id}*${data.project_name}`}>{data.project_name}</option>))}
                                        </select>
                                    </div>
                                </div>

                                <Input field="Task Name"
                                    name="task_name"
                                    type="text" placeholder="Add text"
                                    className="form-control"
                                    value={field.task_name}
                                    onChange={onChange}
                                    required={true}
                                />

                                <Input field="Task Description"
                                    name="task_desc"
                                    type="text" placeholder="Add text"
                                    className="form-control"
                                    value={field.task_desc}
                                    onChange={onChange}
                                    required={true}
                                />

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default TsTaskEditComp