import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Input from "../../../Setup/Student/Component/Input";
import Heading from "../../../../Common/Heading";
import axios from "axios";
import url from "../../../../Common/URL";

import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";



const EditTransactionComp = () => {

    const { id } = useParams();
    const [loc, setLoc] = useState([]);

    const [user, setUser] = useState({
        student: [], staff: []
    });
    const [invItem, setInvItem] = useState([]);

    const [state, setState] = useState({
        item_name: "", description: "", location: "", created_by: "", quantity: "", student: "", price: "",
        comments: "", trans_date: "", transaction_type: "", assigned_to: "", transfer_to_location: "", attachments: ""
    })

    // ----------------------- For Upload Transaction File------------------------------------------------
    const [openDialog, setOpenDialog] = useState(false);
    const [transaction_file, setTransaction_file] = useState(null);
    const [file_upload_spinner, setFile_upload_spinner] = useState(false); // This is used in Calendar link upload  Popup

    const transaction_type = ["Buy", "Sell", "Return", "Issue", "Transfer-In", "Transfer-Out", "Lost", "Damaged"];

    useEffect(() => {
        loadLocation();
        loadTransaction();
        loadInventoryItems();
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Existing Locations--------------------
    const loadLocation = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_locations", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setLoc(a);
            })
            .catch((err) => console.log(err));
    }

    // -----------This function will Fetch all the Item data--------------------
    const loadTransaction = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        };

        await axios.post(url + "select_inventory_transaction", obj)
            .then((response) => {
                let a = response.data.data;
                // console.log(a);
                setState({
                    item_name: a[0].item_name, description: a[0].description, location: a[0].location, created_by: a[0].created_by, quantity: a[0].quantity,
                    comments: a[0].comments, trans_date: a[0].trans_date, transaction_type: a[0].transaction_type, assigned_to: a[0].assigned_to,
                    transfer_to_location: a[0].transfer_to_location, attachments: a[0].attachments, price: a[0].price
                })
            }).catch((err) => console.log(err));
    }
    // -----------This function will Fetch all the Existing Inventory Items--------------------

    const loadInventoryItems = () => {
        const obj = { uname: sessionStorage.getItem("username") };

        axios.post(url + "fetch_inventorys", obj)
            .then((response) => {
                // console.log(response)
                if (response.data.success) {
                    let a = response.data.data;
                    // console.log(a);
                    a !== null ? setInvItem(a.filter(x => x.status === "Active")) : setInvItem([]);
                } else {
                    setInvItem([]);
                }
            }).catch((err) => console.log(err));
    }
    // ------------------------Fetching users table data-------------------------------------
    const loadUsers = async () => {
        const obj = { uname: sessionStorage.getItem("username") };
        axios.post(url + "fetch_users", obj)
            .then((response) => {
                // console.log(response)
                let a = response.data.data;
                var student_arr = [], staff_arr = [];
                a.forEach(x => {
                    if (x.role === "Student") {
                        student_arr.push(x);
                    } else {
                        staff_arr.push(x);
                    }
                });
                setUser({ student: student_arr, staff: staff_arr });
            }).catch((err) => console.log(err));
    }

    //---------------One onChange function is used for setting the state of various types of input fields ---------------     

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    //--------------- onTransaction_File_Submit function for Submitting the  Important Links according to the location & batches--------------

    const onTransaction_File_Submit = () => {

        setFile_upload_spinner(true);
        // ------------Storing form data in "obj" object fetched from various input fields-------------
        var obj = {
            id: id,
            item_name: state.item_name, description: state.description, location: state.location, created_by: state.created_by, quantity: state.quantity,
            comments: state.comments, trans_date: state.trans_date, transaction_type: state.transaction_type, price: state.price,
            assigned_to: state.assigned_to, transfer_to_location: state.transfer_to_location,
            updated_by: sessionStorage.getItem("username"), uname: sessionStorage.getItem("username")
        }

        const formData = new FormData();
        Object.keys(obj).forEach(key => formData.append(key, obj[key]));

        formData.append("image", transaction_file);
        // ------------Sending form data stored in "obj" object to the add_student file uusing AXIOS-------------

        axios.post(url + "edit_inventory_transaction", formData)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    setFile_upload_spinner(false);
                    loadTransaction();
                    toast.success("Updated successfully!");
                    setOpenDialog(false); // For closing the Popup
                    setTransaction_file(null); // For making fields blank again
                } else {
                    setFile_upload_spinner(false);
                    alert("Transaction not updated, please try again later");
                    setOpenDialog(false); // For closing the Popup
                    setTransaction_file(null); // For making fields blank again
                }
            })
            .catch((err) => console.log(err));

    };


    //-------------------------- onSubmit function for Submitting the  Forms---------------------------

    const onSubmit = (e) => {
        e.preventDefault();
        // ------------Storing form data in "obj" object fetched from various input fields-------------
        var obj = {
            id: id,
            item_name: state.item_name, description: state.description, location: state.location, created_by: state.created_by, quantity: state.quantity,
            comments: state.comments, trans_date: state.trans_date, transaction_type: state.transaction_type, price: state.price,
            assigned_to: state.assigned_to, transfer_to_location: state.transfer_to_location,
            updated_by: sessionStorage.getItem("username"), uname: sessionStorage.getItem("username")
        }

        const formData = new FormData();
        Object.keys(obj).forEach(key => formData.append(key, obj[key]));
        formData.append("image", transaction_file);

        // ------------Sending form data stored in "obj" object to the add_student file uusing AXIOS-------------
        axios.post(url + "edit_inventory_transaction", formData)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    toast.success("Updated successfully!");
                } else {
                    alert("Transaction not updated, please try again later!")
                }
            })
            .catch((err) => console.log(err));
    }
    //=======================================================
    const onChnage_File = (e) => {
        let file = e.target.files[0];
        setTransaction_file(file);
    };

    return (
        <>


            {/* =============================== Dialog popup for Adding Google & Batch Calendar Link =====================================*/}

            <Dialog fullWidth={true} maxWidth="sm" open={openDialog}>
                <DialogTitle id="max-width-dialog-title">
                    Add Transaction File <hr className="p-0 mt-2 mb-0" />{" "}
                </DialogTitle>
                <DialogContent>
                    <div className="row ">
                        <div className="col-12 ">
                            <div className="mb-3 ">
                                <label >Transaction File</label>

                                <input
                                    type="file"
                                    className="form-control-file"
                                    name="transaction_file"
                                    onChange={onChnage_File}
                                />
                            </div>
                            {file_upload_spinner ?
                                (<div className="spinner-grow text-primary mx-3 mt-1" role="status"></div>) :
                                (<Button variant="contained"  onClick={() => onTransaction_File_Submit()} >Save</Button>
                                )}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button className="me-4" color="primary"
                        onClick={() => {
                            setOpenDialog(false);
                            setTransaction_file(null);
                        }}
                    >Close</Button>
                </DialogActions>
            </Dialog>

            {/* ===========================================================================================================================*/}

            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'} >
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Inventory</li>
                        <li className="breadcrumb-item "><Link to="/inv-transaction">Transaction Log</Link></li>
                        <li className="breadcrumb-item "><Link to={`/inv-view-transaction/${id}`}>ViewTransaction</Link></li>
                        <li className="breadcrumb-item active">Edit Transaction</li>
                    </ol>
                </nav>
                <Heading name="Edit Transaction" />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
                <form onSubmit={onSubmit} id="myform">
                    <div className="container-fluid mt-3">
                        <div className="row my-4">
                            <div className="col-12 d-flex justify-content-end mx-auto">
                                <Link to={`/inv-view-transaction/${id}`} >Back</Link>
                                <Button variant="contained"  type="submit" className="ms-3  ">Save</Button>
                            </div>
                        </div>

                        <div className="row">

                            {/*-----------------------Column-1 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                <h5 className="mb-4">Item Information</h5>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Select Item</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="item_name" value={`${state.item_name}^${state.price}`}
                                            onChange={(e) => {
                                                let data = e.target.value;
                                                setState({ ...state, price: data.split("^")[1], item_name: data.split("^")[0] })
                                            }}
                                            className="form-select">
                                            <option value="" >Choose</option>
                                            {invItem.map((data, i) => (<option key={i} value={`${data.product_name}^${data.price}`}>{data.product_name}</option>))}
                                        </select>
                                    </div>
                                </div>

                                {/* <Input name="description" onChange={onChange} field="Description" type="text" placeholder="Add Description" className="form-control" value={state.description} required={false} /> */}

                                <Input name="quantity" onChange={onChange} field="Quantity" type="number" placeholder="Add Quantity" className="form-control" value={state.quantity} required={true} />


                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Location</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="location" value={state.location} onChange={onChange} className="form-select">
                                            <option value="" >Choose</option>
                                            {loc.map((data, i) => (<option key={i} value={data.name}>{data.name}</option>))}
                                        </select>
                                    </div>
                                </div>

                                <Input name="comments" onChange={onChange} field="Comments" type="text" placeholder="Add Comment" className="form-control" value={state.comments} />

                                <div className="mb-3 row ">
                                    <label className="col-sm-5 col-form-label">Transaction File</label>
                                    {state.attachments !== "" ?
                                        <div className="col-sm-7 col-md-7 d-flex align-items-center">
                                            <a rel="noopener noreferrer" target="_blank" href={`${url}php/inventory_transaction/transaction_images/${state.attachments}`} >Check File </a>
                                        </div> :
                                        <div className="col-sm-7 col-md-7 d-flex align-items-center">
                                            <p className="m-0 bold" style={{ fontWeight: "500" }}>No File Present</p>
                                        </div>
                                    }
                                </div>

                                <hr className="p-0 mb-1 w-50" />
                                <div className="mb-3 row ">
                                    <label className=" col-auto col-form-label font" style={{ fontWeight: "500" }}>Update Transaction File
                                        <IconButton size="medium" onClick={() => setOpenDialog(true)} className="ms-2" ><EditIcon fontSize="default" color="primary" /></IconButton>
                                    </label>
                                </div>


                            </div>
                            {/*-----------------------Column-2 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">


                                <h5 className="mb-4">Other Information</h5>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Assigned To</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select name="assigned_to" value={state.assigned_to} onChange={onChange} className="form-select">
                                            <option value="" >Choose</option>
                                            {user.staff.map((x, i) => <option key={i} value={x.username}>{x.username}</option>)}
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Student</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select name="student" value={state.student} onChange={onChange} className="form-select">
                                            <option value="" >Choose</option>
                                            {user.student.map((x, i) => <option key={i} value={x.username}>{x.username}</option>)}
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Transaction Type</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="transaction_type" value={state.transaction_type} onChange={onChange} className="form-select">
                                            <option value="" >Choose</option>
                                            {transaction_type.map((x, i) => <option key={i} value={x.toLowerCase()}>{x}</option>)}
                                        </select>
                                    </div>
                                </div>

                                {(state.transaction_type === "transfer-in" || state.transaction_type === "transfer-out") &&
                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label red-asterisk">{state.transaction_type === "transfer-in" ? `From Location` : `To Location`}</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <select required name="transfer_to_location" value={state.transfer_to_location} onChange={onChange} className="form-select">
                                                <option value="" >Choose</option>
                                                {loc.map((data, i) => (<option key={i} value={data.name}>{data.name}</option>))}
                                            </select>
                                        </div>
                                    </div>}

                                <Input name="trans_date" onChange={onChange} field="Transaction Date" type="date" placeholder="Add Date" className="form-control" value={state.trans_date} required={true} />



                            </div>

                        </div>
                    </div>
                </form>
            </div>

        </>
    )
}
export default EditTransactionComp  