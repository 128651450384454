import { Button } from "@mui/material"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { useSelector } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Heading from "../../../../Common/Heading"
import url from "../../../../Common/URL"
import Input from "../../Student/Component/Input"
import Select from 'react-select';

const EditManagerComp = () => {
    const navigate = useNavigate();

    const [loc, setLoc] = useState([]);
    const [multiple_select_data, setMultiple_select_data] = useState({
        location: [],
    }); //Used to compare if any update is done in Multi Select dropdowns data.


    const { id } = useParams();


    const [disable, setDisable] = useState(true);
    const [form_data, setForm_data] = useState(true); //Used to compare if any update is done in form data.

    const [state, setState] = useState({
        uname: sessionStorage.getItem("username"), manager_id: "",
        // ------------normal input fields--------------
        fname: "", mname: "", lname: "", city: "", zip: "", mobile_number: "", home_number: "", email: "",
        join_date: "", leave_date: "", kvk_number: "",
        contract_period: "", contract_renewal_date: "", probation_period: "",
        // ------------Textarea input fields--------------
        address_line1: "", address_line2: "",

        // ------------Checkbox input fields--------------
        active: false, photo_consent: false, disclaimer_signed: false,

        // ------------Select inputs fields--------------
        province: "", country: "", location_name: [], batch_name: [], level_name: [], user_name: "",
        picture: null
    })

    useEffect(() => {
        ManagerDetail();
        loadLocation();
    }, [])



    // -----------This function will Fetch all the Existing Locations--------------------
    const loadLocation = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        axios.post(url + "fetch_locations", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setLoc(a);
            })
            .catch((err) => console.log(err));
    }

    // ------------------------------

    // --------------This will help in creating capitalize strings 
    const capitalizeFirstLetter = (string) => {
        return string[0].toUpperCase() + string.slice(1);
    }

    // ------------------------------

    const ManagerDetail = async () => {
        const obj = {
            uname: state.uname,
            id: id
        }
        await axios.post(url + "fetch_single_manager", obj)
            .then((response) => {
                let resData = response.data.data;
                // console.log(resData);
                setForm_data(resData);       //THis will help in checking any update done in form, so we can enable or disable the save form button
                var active_checkbox = (resData[0].active === "1" || resData[0].active === "true") ? true : false;

                let _locationArrayObjects = resData[0].location_name.split(",");
                let locationArrayObjects = _locationArrayObjects.map(x => ({ label: capitalizeFirstLetter(x), value: capitalizeFirstLetter(x) }))

                setMultiple_select_data({
                    location: locationArrayObjects
                });

                setState(pre => ({
                    ...pre,
                    fname: resData[0].fname, mname: resData[0].mname, lname: resData[0].lname, city: resData[0].city,
                    zip: resData[0].zip, mobile_number: resData[0].mobile_number, home_number: resData[0].home_number,
                    email: resData[0].email,
                    manager_id: resData[0].manager_id, join_date: resData[0].join_date, leave_date: resData[0].leave_date,
                    probation_period: resData[0].probation_period, contract_renewal_date: resData[0].contract_renewal_date,
                    contract_period: resData[0].contract_period, kvk_number: resData[0].kvk_number,
                    // ------------Textarea input fields--------------
                    address_line1: resData[0].address_line1, address_line2: resData[0].address_line2,

                    // ------------Checkbox input fields--------------
                    active: active_checkbox, photo_consent: resData[0].photo_consent, disclaimer_signed: resData[0].disclaimer_signed,

                    // ------------Select inputs fields--------------
                    province: resData[0].province, country: resData[0].country, user_name: resData[0].user_name,

                    location_name: locationArrayObjects

                }))
                //----------------------------------------------------------------------------------------------


            })
            .catch((err) => console.log(err));

        setDisable(true);
    }
    //---------------One onChange function is used for setting the state of various types of input fields ------------
    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        if (form_data[0][name] !== value) {
            setDisable(false);
        } else {
            setDisable(true);
        }
        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    // ------------------- -------------------functions for Submitting Country and state- ------------------- -------------------
    const selectCountry = (val) => {

        setState((preValue) => {
            return {
                ...preValue,
                country: val
            }
        })
    }
    const selectRegion = (val) => {

        setState((preValue) => {
            return {
                ...preValue,
                province: val
            }
        })
    }

    //-------------------------- onSubmit function for Submitting the  Forms--------------------------
    const onSubmit = (e) => {
        e.preventDefault();
        if (state.location_name.length === 0) {
            toast.error("Please fill the Location Name field.");
        } else {
            setDisable(true);

            let locationString = state.location_name.map(({ value }) => value).join();
            /*---------------Creating object to send data through Axios------------------------- */
            const obj = {
                uname: state.user_name, id: id, updated_by: sessionStorage.getItem("username"),

                fname: state.fname, mname: state.mname, lname: state.lname,
                city: state.city, zip: state.zip, mobile_number: state.mobile_number,
                home_number: state.home_number, email: state.email,

                leave_date: state.leave_date, address_line1: state.address_line1,
                address_line2: state.address_line2, join_date: state.join_date, kvk_number: state.kvk_number,
                contract_period: state.contract_period,
                contract_renewal_date: state.contract_renewal_date, probation_period: state.probation_period,

                active: state.active, photo_consent: state.photo_consent, disclaimer_signed: state.disclaimer_signed,

                province: state.province, country: state.country, user_name: state.user_name, location_name: locationString
            }

            const formData = new FormData();
            Object.keys(obj).forEach(key => formData.append(key, obj[key]));



            // console.log([...formData])

            axios.post(url + "edit_manager", formData)
                .then((res) => {
                    // console.log(res)
                    if (res.data.success) {
                        ManagerDetail();
                        toast.success("Updated successfully!")
                    } else {

                        alert("Manager not updated please try again later.")
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                {/*----------------------- Bread crumb ------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item"><Link to="/manager">Manager List</Link></li>
                        <li className="breadcrumb-item active">Edit Manager</li>
                    </ol>
                </nav>
                <Heading name={`Edit Manager - ${state.fname}`} />
                {/* ------------------------------------------------- Two Columns for Inputs --------------------------------------- */}
                <form onSubmit={onSubmit}>
                    <div className="container-fluid mt-3">

                        <div className="row my-4">
                            <div className="col-12 d-flex justify-content-end mx-auto">
                                <Button variant="contained" onClick={() => navigate("/view-manager/" + state.manager_id)} >Back</Button>
                                <Button variant="contained" type="submit" disabled={disable} className="ms-3">Save</Button>
                            </div>
                        </div>

                        <div className="row">

                            {/*-----------------------Column-1 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                <h5 className="mb-4">Personal Information</h5>

                                <Input field="First Name" type="text" placeholder="Auto Generated" className="form-control" name="fname" onChange={onChange} value={state.fname} required={true} />
                                <Input field="Middle Name" type="text" placeholder="Auto Generated" className="form-control" name="mname" onChange={onChange} value={state.mname} required={false} />
                                <Input field="Last Name" type="text" placeholder="Auto Generated" className="form-control" name="lname" onChange={onChange} value={state.lname} required={true} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Address Line 1</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea required name="address_line1" onChange={onChange} className="form-control" placeholder="Add text" value={state.address_line1}  ></textarea>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Address Line 2</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea name="address_line2" onChange={onChange} className="form-control" placeholder="Add text" value={state.address_line2} ></textarea>
                                    </div>
                                </div>

                                <Input field="City" type="text" placeholder="Auto Generated" className="form-control" name="city" onChange={onChange} value={state.city} required={true} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Country</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <CountryDropdown
                                            className="form-select"
                                            name="country"
                                            value={state.country}
                                            onChange={(val) => selectCountry(val)} />
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Province</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <RegionDropdown
                                            className="form-select"
                                            name="state"
                                            country={state.country}
                                            value={state.province}
                                            onChange={(val) => selectRegion(val)} />
                                    </div>
                                </div>

                                <Input field="Zip" type="text" placeholder="Auto Generated" className="form-control" name="zip" onChange={onChange} value={state.zip} required={true} />
                                <Input field="Mobile Number" type="text" placeholder="Auto Generated" className="form-control" name="mobile_number" onChange={onChange} value={state.mobile_number} required={true} />
                                <Input field="Home Phone" type="text" placeholder="Auto Generated" className="form-control" name="home_number" onChange={onChange} value={state.home_number} required={false} />
                                <Input field="Email" type="email" placeholder="Auto Generated" className="form-control" name="email" onChange={onChange} value={state.email} required={true} />
                                <Input field="KVK Number" type="text" placeholder="Auto Generated" className="form-control" name="kvk_number" onChange={onChange} value={state.kvk_number} required={true} />

                            </div>

                            {/*-----------------------Column-2 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                <h5 className="mb-4">Location Information</h5>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Location Name</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <Select
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            isClearable={true}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            options={loc.map(x => ({ label: x.name, value: x.name }))}
                                            value={state.location_name}
                                            onChange={(e) => {
                                                if (JSON.stringify(multiple_select_data.location) !== JSON.stringify(e))
                                                    setDisable(false);
                                                else setDisable(true);
                                                setState({ ...state, location_name: e });
                                            }}
                                        />
                                    </div>
                                </div>

                                <h5 className="mt-5 mb-4">Account Information</h5>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Active Account</label>
                                    <div className="col-sm-7 col-md-7 ps-4">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="active" checked={state.active} onChange={onChange} />
                                        </div>
                                    </div>
                                </div>

                                <Input name="join_date" onChange={onChange} field="Join Date" type="date" placeholder="Add text" className="form-control" value={state.join_date} required={true} />
                                <Input name="probation_period" onChange={onChange} value={state.probation_period} field="Probation Period" type="number" placeholder="In Months" className="form-control" />
                                <Input name="contract_renewal_date" onChange={onChange} value={state.contract_renewal_date} field="Contract Renewal Date" type="date" placeholder="Add text" className="form-control" />
                                <Input name="contract_period" onChange={onChange} value={state.contract_period} field="Contract Period" type="number" placeholder="In Months" className="form-control" />
                                <Input name="leave_date" onChange={onChange} value={state.leave_date} field="Leave Date" type="date" placeholder="Add text" className="form-control" />

                            </div>

                        </div>
                    </div>
                </form>

            </div>
        </>
    )
}
export default EditManagerComp;