import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom";
import Heading from "../../../../Common/Heading"
import { toast } from "react-toastify"
import { ReadonlyInput } from "../../Student/Component/Input"
import axios from "axios"
import url from "../../../../Common/URL"
import { Button } from "@mui/material";

import { useConfirm } from 'material-ui-confirm';
import { useSelector } from "react-redux";


const ViewBatchComp = () => {
    const confirm = useConfirm();

    const navigate = useNavigate();

    const { id } = useParams();

    const [state, setState] = useState({
        uname: sessionStorage.getItem("username"),
        batch_name: "", location_name: "", level_name: "", day_of_week: "",
        time_of_day: "", batch_calendar_link: "", remarks: "", batch_id: "",

    })

    const loadBatch = () => {
        const obj = {
            uname: state.uname,
            id: id
        }
        axios.post(url + "fetch_single_batch", obj)
            .then((response) => {
                let resData = response.data.data;
                // console.log(resData);
                setState({
                    batch_name: resData[0].batch_name, location_name: resData[0].location_name,
                    level_name: resData[0].level_name, day_of_week: resData[0].day_of_week, batch_id: resData[0].batch_id,
                    time_of_day: resData[0].time_of_day, batch_calendar_link: resData[0].batch_calendar_link,
                    remarks: resData[0].remarks,
                })
            })
            .catch((err) => console.log(err));

    }

    useEffect(loadBatch, [])


    // ------------------------Deleting Batch table's data-------------------------------------

    const onDelete = () => {

        confirm({ title: "Delete Confirmation", description: 'This will delete this batch permanently.' })
            .then(() => {
                const delObj = {
                    id: id,
                    uname: sessionStorage.getItem("username"),
                    tokenId: 123,
                };
                axios.post(url + "delete_batch", delObj)
                    .then((res) => {
                        // console.log(res) ;
                        navigate("/batch");
                        toast.info("Batch deleted")
                    })
                    .catch((err) => console.log(err));
            })
    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item"><Link to="/batch">Batch List</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">View Batch</li>
                    </ol>
                </nav>


                <Heading name={"View Batch - " + state.batch_name} />

                <div className="container-fluid">

                    <div className="row mb-4">
                        {/*-----------------Header------------------------  */}
                        <div className="col-12 d-flex justify-content-between align-items-end ">
                            <h5>Batch Information</h5>
                            <div>
                                <Button variant="contained" onClick={onDelete} >Delete</Button>
                                <Button variant="contained" onClick={() => navigate("/edit-batch/" + id)} className="ms-3 ">Edit</Button>
                            </div>
                        </div>
                    </div>

                    <div className="row ">
                        {/* -----------------First Column----------------- */}
                        <div className="col-11 col-md-6 mx-auto">

                            {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Batch ID</label>
                        <div className="col-sm-7 col-md-7 ">
                        <input type="text" readOnly className="form-control bg-light border-0 fw-bold" value={state.batch_id} />
                        </div>
                    </div> */}

                            <ReadonlyInput value={state.batch_name} field="Batch Name"   />

                            {/* ---Select Input---- */}
                            <ReadonlyInput field="Location"  className="form-control bg-light border-0 " value={state.location_name.split("*")[0]} />

                            {/* ---Select Input---- */}
                            {/* <ReadonlyInput field="Level"  className="form-control bg-light border-0 "  value={state.level_name}/> */}


                            {/* ---Select Input---- */}
                            <ReadonlyInput field="Day of Week"  className="form-control bg-light border-0 " value={state.day_of_week} />

                            <ReadonlyInput value={state.time_of_day} field="Time of day" type="time"  />

                        </div>
                        {/* ------------Second Column----------------- */}
                        <div className="col-11 col-md-6 mx-auto">

                            <ReadonlyInput field="Batch Calendar Link"  className="form-control bg-light border-0 " value={state.batch_calendar_link} />

                            <div className="mb-3 row">
                                <label className=" col-sm-5 col-form-label">Remarks</label>
                                <div className="col-sm-7 col-md-7">
                                    <textarea readOnly className="form-control bg-light border-0 " value={state.remarks} type="text"  rows="2" placeholder="Add remarks here..." />
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

export default ViewBatchComp