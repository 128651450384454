import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../../../Common/Heading"
import LoadingSpinner from "../../../../Common/LoadingSpinner";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SaveAlt from '@mui/icons-material/SaveAlt';

import axios from "axios";
import url from "../../../../Common/URL"

import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
// import { useConfirm } from 'material-ui-confirm';

const BalanceComp = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showTable, setShowTable] = useState(false);
  // ----------------------- For fetching all Locations for select Inputs ------------------------------------------------
  const [loc, setLoc] = useState([]);
  const [data, setData] = useState([]);

  const [state, setState] = useState({
    location_name: ""
  })

  const Columns = useMemo(
    () => [
      { header: 'Product', accessorKey: 'item_name' },
      { header: 'Quantity', accessorKey: 'quantity' },
    ],
    [],
  );


  // ------------------------Fetching Student table's data-------------------------------------
  useEffect(() => {
    loadLocation();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const loadLocation = () => {
    const obj = { uname: sessionStorage.getItem("username") };

    axios
      .post(url + "fetch_locations", obj)
      .then((response) => {
        var All_locations = response.data.data;
        setLoc(All_locations);
      })
      .catch((err) => console.log(err));
  };

  const loadBalance = (e) => {
    e.preventDefault();
    setShowTable(true);
    setLoading(true);
    const obj = { uname: sessionStorage.getItem("username"), location: state.location_name }
    axios.post(url + "select_balance", obj)
      .then((response) => {
        console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          console.log(a);
          if (a === null) {
            setData([])
          } else {
            var show = a.filter(x => x.quantity !== "0");
            // console.log(show);
            show.length !== 0 ? setData(show) : setData([]);
          }

          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Inventory</li>
            <li className="breadcrumb-item active" aria-current="page">Inventory Balance</li>
          </ol>
        </nav>

        <Heading name="Inventory Balance" />
        <div className="container-fluid">

          <div className="row">
            <div className="col-11 mx-auto">
              <form onSubmit={loadBalance}>
                <div className="mb-3 row py-2 rounded" style={{ border: 0, borderLeft: "5px solid #174873" }}>
                  <label htmlFor="inp_loc" className="col-auto col-form-label fw-bold">Select Location To Proceed</label>
                  <div className="col-2 ps-0">
                    <select name="location_name" required value={state.location_name} onChange={(e) => setState({ location_name: e.target.value })}
                      className="form-select rounded-0" style={{ border: 0, borderBottom: "1px solid black" }}
                    >
                      <option value="">Choose</option>
                      {loc.map((data, i) => <option key={i} value={data.name}>{data.name} </option>)}
                    </select>
                  </div>
                  <div className="input-group-append">
                    <Button variant="contained" type="submit" >Search</Button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <hr className="p-0 mx-auto" />

          {showTable &&
            <div className="row mt-3">
              <div className="col-12 mx-auto">

                <MaterialReactTable
                  state={{ isLoading: loading }}

                  columns={Columns}
                  data={data}
                  enableRowNumbers
                  enableRowSelection
                  initialState={{ density: 'compact' }}
                  enableDensityToggle={false}
                  
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => navigate("/view-balance-history/" + row.original.item_name + "/" + row.original.location),
                    sx: { cursor: 'pointer' },
                  })}
                />
              </div>
              <div className="col-12 mx-auto d-flex align-items-center mt-3 mb-5">
                <ErrorOutlineIcon /> &nbsp;<h6 className="m-0">{data.length === 0 ? "All " : "Remaining "}Inventory Items Have 0 Quantity For This Location.</h6>
              </div>
            </div>}
        </div>
      </div>
    </>
  )
}
export default BalanceComp

