import React, { useState } from "react";
// import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "axios";
import url from "../../Common/URL";
import "./../login.css";

// import { toast } from "react-toastify";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Input from '@mui/material/Input';
// #makeStyle

const Forget_pass_comp = () => {

    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        email: "",
    })

    const onChange = (e) => {

        const input = e.target;
        const name = input.name;
        const value = input.value;

        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();

        /*---------------Creating object to send data through Axios------------------------- */
        const obj = {
            email: state.email
        }
        axios.post(url + "forgot_password", obj)
            .then((res) => {
                // console.log(res) 
                if (res.data.success) {
                    setOpen(true);
                    setState({
                        email: ""
                    })
                } else {
                    console.log(res);
                    alert("Given email address is not present in our database.")
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Change Password Dialog</DialogTitle>
                <DialogContent>
                    <h4 className="text-center" id="transition-modal-title">You are done!</h4>
                    <p id="transition-modal-description" className="text-center">An email has been sent to you with instructions on how to reset your password.</p>
                </DialogContent>

            </Dialog>

            <div style={{ height: "60vh" }} className="card col-11 col-md-4  pt-3 px-4 px-md-5 shadow mx-auto login mb-5">
                <h2 className="text-center mb-5 mt-2 head">Forgot Password</h2>
                <h6 className="text-center">Please enter your email address below and we will help you to change your password.</h6>
                <hr style={{ border: "0px", borderBottom: "3px dotted #E89801" }} className="w-25 mx-auto" />

                <form onSubmit={onSubmit}>
                    <div className="mb-3 mt-2">
                        {/* <label htmlFor="exampleInputEmail1">Email address</label> */}
                        <Input
                            required
                            disableUnderline={true}
                            type="email"
                            className="form-control shadow-sm border-0"
                            id="uname"
                            value={state.uname}
                            placeholder="Email Address"
                            onChange={onChange}
                            autoComplete="off"
                            name="email"
                        />
                        <small id="emailHelp" className="form-text text-muted mt-2 ps-2">We'll never share your email with anyone else.</small>
                        <Button variant="contained" type="submit" className=" mb-3 shadow mt-5">Reset Password</Button>

                    </div>
                </form>

            </div>

        </>
    )
}
export default Forget_pass_comp;