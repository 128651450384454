import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import url from "../../../../Common/URL";
import { toast } from "react-toastify";
import Input from "../../Student/Component/Input";
import Heading from "../../../../Common/Heading";
import { Button } from "@mui/material";

import StringToTime from "../../../../Common/StringDateTime";
import { useSelector } from "react-redux";

const AddSlotComp = () => {
  let navigate = useNavigate();

  const [loc, setLoc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [teachers, setTeacher] = useState([]);


  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
    slot_date: '',
    slot_time: '',
    slot_type: '0',
    slot_status: '0',
    location_id: '',
    created_by: sessionStorage.getItem("username"),
    assigned_to: '',
  });

  useEffect(() => {
    loadLocation();
    loadTeachers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------One onChange function is used for setting the state of various types of input fields ---------------

  const onChange = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.type === "checkbox" ? input.checked : input.value;
    setState((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };
  // -----------This function will Fetch all the Existing Teachers--------------------

  const loadTeachers = () => {
    const obj = { uname: sessionStorage.getItem("username") }
    axios.post(url + "teachers", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          console.log(a);
         !a.length ? setTeacher([]) : setTeacher(a);
        } else {
          setTeacher([]);
        }
      })
      .catch((err) => console.log(err));
  }


  // -----------This function will Fetch all the Existing Locations--------------------
  const loadLocation = async () => {
    const obj = {
      uname: sessionStorage.getItem("username"),
    };

    await axios
      .post(url + "fetch_locations", obj)
      .then((response) => {
        let a = response.data.data;
        //   console.log(a);
        setLoc(a);
      })
      .catch((err) => console.log(err));
  };

  //-------------------------- onSubmit function for Submitting the  Forms---------------------------

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // ------------Sending form data stored in "obj" object to the add_student file uusing AXIOS-------------
    console.log(state);
    axios
      .post(url + "add_slot", state)
      .then((res) => {
        // console.log(res);
        if (res.data.success) {
          toast.success("Slot created successfully ");
          navigate("/slots");
          setLoading(false);
        } else {
          alert("Slot not created, please try again");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item"><Link to="/slots">Slots</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Add Slot</li>
          </ol>
        </nav>

        <Heading name="Add Slot" />

        <div className="container-fluid">
          <form onSubmit={onSubmit} id="myform">
            <div className="row mb-4">
              <div className="col-12 d-flex justify-content-between ">
                <h5>Slot Information</h5>
                <div>
                  <Button variant="contained" onClick={() => navigate("/slots")} >
                    Cancel
                  </Button>
                  <Button disabled={loading} variant="contained" type="submit" className=" mx-3 ">
                    Save
                  </Button>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-11 col-md-7 mx-auto mx-md-0">


                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Slot Location</label>
                  <div className="col-sm-7 col-md-7 ">
                    <select required name="location_id" value={state.location_id} onChange={onChange} className="form-select">
                      <option value="">Choose</option>
                      {loc.map((data, i) => <option key={i} value={data.location_id}>{data.name}</option>)}
                    </select>
                  </div>
                </div>

                <Input
                  name="slot_date"
                  onChange={onChange}
                  value={state.slot_date}
                  field="Slot Date"
                  type="date"
                  className="form-control"
                  required={true}
                />

                <Input
                  name="slot_time"
                  onChange={onChange}
                  value={state.slot_time}
                  field="Slot Time"
                  type="time"
                  className="form-control"
                  required={true}
                />

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Assign To</label>
                  <div className="col-sm-7 col-md-7 ">
                    <select required name="assigned_to" value={state.assigned_to} onChange={onChange} className="form-select">
                      <option value="">Choose</option>
                      {teachers.map((data, i) => (<option key={i} value={data.teacher_id}>{`${data.fname} ${data.lname}`}</option>))}
                    </select>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label ">Slot Type</label>
                  <div className="col-sm-7 col-md-7 align-items-center d-flex ">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input"
                        type="radio"
                        name="slot_type"
                        id="slotType1"
                        value='0'
                        checked={state.slot_type === '0'}
                        onChange={onChange}
                      />
                      <label className="form-check-label" htmlFor="slotType1">Trial Class</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input"
                        type="radio"
                        name="slot_type"
                        id="slotType2"
                        value='1'
                        checked={state.slot_type === '1'}
                        onChange={onChange}
                      />
                      <label className="form-check-label" htmlFor="slotType2">Parent Orientation</label>
                    </div>
                  </div>
                </div>

                {/* <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label ">Is slot full?</label>
                  <div className="col-sm-7 col-md-7 align-items-center d-flex ">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input"
                        type="radio"
                        name="slot_status"
                        id="slotFull1"
                        value='0'
                        checked={state.slot_fulstatus= '0'}
                        onChange={onChange}
                      />
                      <label className="form-check-label" htmlFor="slotFull1">No</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input"
                        type="radio"
                        name="slot_status"
                        id="slotFull2"
                        value='1'
                        checked={state.slot_fulstatus= '1'}
                        onChange={onChange}
                      />
                      <label className="form-check-label" htmlFor="slotFull2">Yes</label>
                    </div>
                  </div>
                </div> */}



              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddSlotComp;